import * as Sentry from '@sentry/browser'

/**
 * Set Sentry User Context
 * @param {*} user
 */
const configureUser = user => {
    Sentry.configureScope(scope => {
        scope.setUser({
            email: user.email,
            username: user.displayName
        })
    })
}

/**
 * Clear Sentry User Context
 */
const clearContext = () => {
    Sentry.configureScope(scope => {
        scope.setUser(null)
    })
}

export {
    configureUser,
    clearContext
}