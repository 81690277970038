import { ELSAResult, ELSAScore, ELSAScoreApiVersion, MarkedWord, WordStatus } from './../types/elsa';
import axios from 'axios';

export const fetchScore = async (sentence: string, audio: Blob) => {
  const formData = new FormData()

  formData.append('sentence', sentence);
  formData.append('audio_file', audio);

  return axios.post<ELSAScore>('/score_audio', formData, {
    baseURL: process.env.VUE_APP_ELSA_API_BASE_URL,
    headers: {
      'Authorization': process.env.VUE_APP_ELSA_API_TOKEN,
      'Set-Cookie': process.env.VUE_APP_ELSA_API_COOKIE,
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * Score parser for ELSA api version 3.0
 * This demonstrates the following:
 * The correct and incorrect judgement is in the decision field.
 * The quality of the speaking is in the nativeness_score which is a value from 0-100.
 * There is also a global nativeness_score outside of the words array.  This can be converted into a 5 star rating directly.
 * The the ELSA response will always have a words array ordered by the expected words (equivalent to words with sequence_id in our own data)
 *
 * Mapping the ELSA data to our UI
 *  missing = incorrect OR correct and nativeness_score <= 20
 *  incorrect = correct and nativeness_score <= 30
 *  mispronounced = correct and nativeness_score <= 60
 *  correct = correct and nativeness_score > 60
 * @param score
 * @returns ELSAResult
 */
const _parseScoreApiVersion20 = (score: ELSAScore): ELSAResult => {
  const [ utterance ] = score.utterance
  const MAX_RATING = 5
  // TODO: SMT 20-100
  const rating = Math.round( ( utterance.nativeness_score * MAX_RATING) / 100 )
  const words: MarkedWord[] = []
  let sequence = 0

  for (const word of utterance.words) {
    let status = WordStatus.INCORRECT
    // TODO: (MATT) you can tweak logic here ;)
    // if (word.decision === WordDecision.CORRECT && word.nativeness_score > 20 && word.nativeness_score <= 30) {
    //   status = WordStatus.INCORRECT
    // } else if (word.decision === WordDecision.ALMOST_CORRECT || ( word.decision === WordDecision.CORRECT &&
    //                                                               word.nativeness_score > 30 &&
    //                                                               word.nativeness_score <= 60)) {
    //   status = WordStatus.MISPRONOUNCED
    // } else
    // if (word.decision === WordDecision.CORRECT && word.nativeness_score > 35) {
    if (word.nativeness_score > 35) {
      status = WordStatus.CORRECT
    }

    if (word.text_orig.includes(' ')) {
      const subWords = word.text_orig.split(' ');
      subWords.forEach(subWord => {
        words.push({
          word: subWord,
          sequence,
          status
        });
        sequence++;
      })
    } else {
      words.push({
        word: word.text_orig,
        sequence,
        status
      });
      sequence++;
    }
  }

  return {
    rating,
    words
  } as ELSAResult
}

/**
 * Parses Fetched API Score
 * @param score
 * @returns ELSAResult
 */
export const parseScore = (score: ELSAScore): ELSAResult => {
  switch(score.api_version) {
    case ELSAScoreApiVersion.V_2_0: {
      return _parseScoreApiVersion20(score)
    }
    default: {
      return {
        rating: 0,
        words: []
      } as ELSAResult
    }
  }
}