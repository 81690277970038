<template>
  <div>
    <div class="es-select-phrases" :class="{'disabled': disabled}">
      <div class="select-wrapper">
        <h3>{{ title }}</h3>
        <div class="phrases">
          <template v-for="phrase in markedPhrases">
            <button
              v-es-on:click="() => toggleSelection(phrase.option_id, !isSelected(phrase))"
              :key="`es-select-prase-${phrase.option_id}`"
              class="phrase"
              :class="{'selected': isSelected(phrase)}" >
              {{ phrase.value }}
            </button>
          </template>
        </div>
        <div class="or-content">
          <p>or</p>
          <button :class="{ 'active': allIncorrect }" v-es-on:click="selectNone">None of these words</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-select-phrases',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Tap everything that you hear the speaker say'
    },
    phrases: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    selectedWord: null,
    markResponse: {
      tasks: []
    },
    allIncorrect: false
  }),
  computed: {
    markedPhrases: {
      get() {
        return this.markResponse.tasks
      },
      set(value) {
        this.markResponse.tasks = value
      }
    }
  },
  methods: {
    isSelected(word) {
      return word.status && word.status === 'correct'
    },
    toggleSelection(optionId, shouldAdd) {
      if (this.disabled) {
        return 
      }
      this.allIncorrect = false
      const index = this.markedPhrases.findIndex(item => item.option_id === optionId)
      const word = this.markedPhrases[index]
      if (shouldAdd) {
        word.status = 'correct'
      } else {
        word.status = 'incorrect'
      }
      this.markedPhrases = [
        ...this.markedPhrases.slice(0, index),
        word,
        ...this.markedPhrases.slice(index + 1),
      ] 
      this.emitResponse()
    },
    selectNone() {
      if (this.disabled) {
        return 
      }
      if (!this.allIncorrect) {  
        this.markedPhrases = [...this.markedPhrases.map(word => {
          word.status = 'incorrect'
          return word
        })]
        this.allIncorrect = true
        this.emitResponse()
      } else {
        this.allIncorrect = false
        this.emitResponse(true)
      }
    },
    emitResponse(sendNull = false) {
      if (!sendNull) {
        const phrases = this.markedPhrases.map(phrase => {
          if (!phrase.status || phrase.status !== 'correct') {
            phrase.status = 'incorrect'
          }
          return phrase
        })
        this.$emit('input', {
          ...this.markResponse,
          tasks: phrases
        })
      } else {
        this.$emit('input', null)
      }
    }
  },
  mounted() {
    this.markResponse.tasks = this.phrases || []
  },
  watch: {
    'markedPhrases' (newPhrases) {
       if (newPhrases.filter(phrase => phrase.status === 'correct').length === 0 && !this.allIncorrect) {
         this.emitResponse(true)
       }
    }
  }
}
</script>