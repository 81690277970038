import { singleton } from 'tsyringe';
import NativeAudioService from '@es/domain/lib/services/NativeAudioService'
import Recorder from 'audio-recorder-polyfill'
import StopRecordingResponse from '@es/domain/lib/models/AudioService';

// console.log('Recorder', Recorder)

declare let MediaRecorder: any;

const sleep = (time = 1000) => new Promise((resolve) => {
  setTimeout(resolve, time);
}) 

const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise<string>(resolve => {
    reader.onloadend = () => {
      resolve(reader.result?.toString());
    };
  });
};

@singleton()
export default class WebAudioService implements NativeAudioService {

  private _recorder?: any
  private _chunks: Blob[] = []
  private _stream?: MediaStream
  private _type?: string
  private _recordings: Map<string, string> = new Map()

  private _distortion;
  private _source;
  private _interval;
  private _audio?: HTMLAudioElement;
  private _lastAudioToken?: string;

  async record(onProgress: (err: any, data: any) => void): Promise<boolean> {
    // const status = await window.navigator.permissions.query({
    //   name: 'microphone'
    // })
    // if (status.state === "granted") {
      
    // } else if (status.state === 'prompt') {
    //   throw new Error('Waiting for user to allow')
    // }
    try {
      this._stream = await window.navigator.mediaDevices.getUserMedia({
        audio: true
      })
      const audioCtx = new AudioContext();
      const analyser = audioCtx.createAnalyser();
      // analyser.minDecibels = -90;
      // analyser.maxDecibels = -10;

      this._source = audioCtx.createMediaStreamSource(this._stream);
      this._source.connect(analyser);
      // this._distortion = audioCtx.createWaveShaper();
      // analyser.connect(this._distortion);
      // this._distortion.connect(audioCtx.destination); 

      // console.log('MediaRecorder', !('MediaRecorder' in window))
      const usePollyfill = !('MediaRecorder' in window)
      this._recorder = usePollyfill ? new Recorder(this._stream) : new MediaRecorder(this._stream)
      this._recorder.addEventListener('dataavailable', (evt) => {
        this._type = evt.data.type
        this._chunks.push(evt.data)
      })
      this._chunks = []
      this._recorder.start()

      this._interval = setInterval(() => {

        analyser.fftSize = 32;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyser.getByteTimeDomainData(dataArray);
        // const data: number[] = [];
        const sum = dataArray.reduce((sum, arrayItem) => {
          const value = Math.abs(arrayItem - 128.0) / 128.0
          return value + sum
        }, 0);
        // for (let i = 0; i < 32; i += 2) {
        //   const average = (dataArray[i] + dataArray[i+1]) / (2 * 255.0)
        //   data.push(average)
        // }
        onProgress(null, sum/dataArray.length);
      }, 100)
    } catch(err) {
      console.log(err)
      this._stream?.getTracks().forEach(track => track.stop())
      clearInterval(this._interval)
      throw err
    }
    return true
  }

  async stop(): Promise<StopRecordingResponse> {
    clearInterval(this._interval)
    this._recorder.stop()
    this._stream?.getTracks().forEach(track => track.stop())
    
    await sleep(500)
    const token = `spoken_${Math.floor(Math.random() * 100000)}`
    const blob = new Blob(this._chunks, { 'type' : this._type })
    const base64 = await blobToBase64(blob)
    this._recordings.set(token, base64)

    return {
      token,
      millis: 15600
    }
  }

  async get(token: string): Promise<string> {
    if (this._recordings.has(token)) {
      return this._recordings.get(token)!
    } else {
      throw new Error(`Recording not found for given token (${token})`)
    }
  }

  async playAudio(token: string, onDone: () => void): Promise<boolean> {
    if (this._recordings.has(token)) {
      if (!this._audio || token !== this._lastAudioToken) {
        this._audio = new Audio(this._recordings.get(token));
        this._audio.addEventListener('ended', onDone);
        this._lastAudioToken = token
      }
      this._audio.play()
      return true
    } else {
      return false
    }
  }

  async pauseAudio(): Promise<boolean> {
    if (this._audio) {
      this._audio.pause();
      return true
    } else {
      return false
    }
  }
}