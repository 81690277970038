import { apiClient } from "@/services/api";
import { logEvent } from "@/services/analytics";
import dateString from "@/plugins/filters/date-string";

// Generate a human-friendly label e.g. from "DND_READING" to "Dnd Reading"
const humanize = (string: string) =>
  string &&
  string
    .trim()
    .toLowerCase()
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const getFullname = ({ first_name, last_name }) => {
  if (!first_name && !last_name) return "—";
  // sometimes last name is already in the first name.
  if (first_name?.endsWith(last_name)) return first_name;
  return [first_name, last_name].filter(Boolean).join(" ");
};

const users = {
  headers: [
    { text: "Name", value: "name" },
    { text: "Email", value: "email" },
    { text: "Valid from", value: "created" },
    { text: "Expires on", value: "renewal_date" },
    { text: "", value: "actions", align: "right", sortable: false }
  ],
  items: []
};
const connectCodes = {
  headers: [
    { text: "Code", value: "code" },
    { text: "Description", value: "description" },
    { text: "Assessment skills", value: "assessmentSkills" },
    { text: "Reference prompt", value: "referencePrompt" },
    { text: "Valid from", value: "validFrom" },
    { text: "Expires on", value: "validTo" },
    { text: "", value: "actions", align: "right", sortable: false }
  ],
  items: []
};
const certificateVouchers = {
  headers: [
    { text: "Code", value: "code" },
    { text: "Assessment skills", value: "assessmentSkills" },
    { text: "% Discount", value: "discount" },
    { text: "Description", value: "description" },
    { text: "Valid from", value: "validFrom" },
    { text: "Expires on", value: "validTo" },
    { text: "", value: "actions", align: "right", sortable: false }
  ],
  items: []
};
const certificateCredits = {
  headers: [
    { text: "Code", value: "code" },
    { text: "Assessment skills", value: "assessmentSkills" },
    { text: "Description", value: "description" },
    { text: "Valid from", value: "validFrom" },
    { text: "Expires on", value: "validTo" },
    { text: "", value: "actions", align: "right", sortable: false }
  ],
  items: []
};

const organisation = {
  name: "",
  users,
  connectCodes,
  certificateVouchers,
  certificateCredits
};

const headers = [
  { text: "Name", value: "name" },
  { text: "Parent", value: "is_parent" },
  { text: "Dashboard Admin", value: "initial_dashboard_user" },
  { text: "Connect Code Created", value: "most_recently_created_connect_code" },
  { text: "Conect Code Used", value: "most_recently_used_connect_code" },
  { text: "Certificate Purchased", value: "most_recent_certificate_purchased" },
  { text: "Test Takers", value: "unique_test_taker_connect_code_usage" },
  { text: "", value: "actions", align: "right", sortable: false }
];

const resetOrganisationState = ({ state }) => {
  state.organisation = {
    name: "",
    users: {
      ...users,
      items: []
    },
    connectCodes: {
      ...connectCodes,
      items: []
    },
    certificateVouchers: {
      ...certificateVouchers,
      items: []
    },
    certificateCredits: {
      ...certificateCredits,
      items: []
    }
  };
};

const fetchOrganisations = async ({ dispatch, state }) => {
  state.busy = true;

  /**
   * @note for hubspot_id
   * Don't expect "hubspot_id" to be available in dev or staging. Only the production environment will communicate with HubSpot.
   */
  try {
    const response = await apiClient.get(`/v1.11/organisations`, {
      params: { incl_stats: true }
    });
    const {
      data: { results }
    } = response;
    const organisations = results;
    state.rawOrganisations = organisations;
    const unfilteredOrganisations = organisations.map(organisation => {
      const {
        is_parent,
        organisation_id,
        hubspot_id,
        name,
        statistics
      } = organisation;
      const {
        initial_dashboard_user,
        most_recently_created_connect_code,
        most_recently_used_connect_code,
        most_recent_certificate_purchased,
        unique_test_taker_connect_code_usage
      } = statistics;
      const isParent = is_parent ? "Parent" : "";
      const { first_name, last_name } = initial_dashboard_user || {};
      const username = getFullname({ first_name, last_name });
      return {
        organisation_id,
        hubspot_id,
        name,
        is_parent: isParent,
        initial_dashboard_user: { ...initial_dashboard_user, username },
        most_recently_created_connect_code,
        formatted_most_recently_created_connect_code: dateString(
          most_recently_created_connect_code
        ),
        most_recently_used_connect_code,
        formatted_most_recently_used_connect_code: dateString(
          most_recently_used_connect_code
        ),
        most_recent_certificate_purchased,
        formatted_most_recent_certificate_purchased: dateString(
          most_recent_certificate_purchased
        ),
        unique_test_taker_connect_code_usage
      };
    });

    // Give the unfiltered organisations to the store, then apply any existing filters
    state.items = unfilteredOrganisations;
  } catch (error) {
    console.error(error);
    state.items = state.unfilteredOrganisations = [];
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Could not load organisations.</strong>`
      },
      { root: true }
    );
  }
  state.busy = false;
};

/**
 * Developer note
 *
 * Don't expect this code to work in dev or staging. Only the production environment will communicate with hubspot.
 * More details are here: https://englishscore.atlassian.net/browse/ES-5602
 */
const addOrganisation = async (
  { state, rootGetters, dispatch },
  { admin_email, hubspot_company_id }
) => {
  state.busy = true;
  const provisioned_by = rootGetters["account/user"].user_id;
  const payload = {
    admin_email,
    hubspot_company_id,
    provisioned_by,
    skip_email: false
  };

  try {
    await apiClient.post(`/v0.1/dataflow/b2b/provision`, payload);
    dispatch(
      "snackbar/snack",
      {
        mode: "success",
        message: `✅ Added Organisation. <a href="/connect-codes">Now create a connect code</a>`
      },
      { root: true }
    );
  } catch (error) {
    console.error(error);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore:next-line
    const { message } = error.response?.data || error;
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">${message}</strong>`
      },
      { root: true }
    );
  } finally {
    state.busy = false;
    dispatch("fetchOrganisations");
  }
};

const loadOrganisation = async ({ state, dispatch }, organisationId) => {
  // @hack: If the organisationId is "new", then we're creating a new organisation, so don't load anything
  if (organisationId === "new") return;

  state.showOrganisationModal = true;
  state.busy = true;

  try {
    const response = await apiClient.get(`/v1.11/organisations`, {
      params: { organisation_id: organisationId }
    });
    const {
      data: { results }
    } = response;
    const organisation = results[0];

    state.organisation.name = organisation.name;
    state.organisation.hubspot_id = organisation.hubspot_id;

    state.organisation.users.items = organisation.admins?.map(
      ({ renewal_date, created, email, first_name, last_name, active }) => {
        const name = getFullname({ first_name, last_name });
        const user = {
          name,
          email,
          renewal_date,
          created,
          formatted_renewal_date: dateString(renewal_date),
          formatted_created: dateString(created),
          active
        };
        return user;
      }
    );

    state.organisation.connectCodes.items = organisation.connect_codes?.map(
      ({
        code,
        valid_from,
        valid_to,
        description,
        quotas,
        test_taker_reference_prompt
      }) => {
        const item = {
          code,
          validFrom: valid_from,
          validTo: valid_to,
          formattedValidFrom: dateString(valid_from),
          formattedValidTo: dateString(valid_to),
          description: description,
          assessmentSkills: quotas.assessment_skills
            ?.map(humanize)
            .map(s => (s === "Core V1" ? "Core" : s))
            .map(s => (s === "Spoken" ? "Speaking" : s))
            .join(", "),
          referencePrompt: test_taker_reference_prompt
        };
        return item;
      }
    );

    state.organisation.certificateVouchers.items = organisation.certificate_vouchers?.map(
      ({ code, valid_from, valid_to, description, quotas, discount }) => {
        const item = {
          code,
          validFrom: valid_from,
          validTo: valid_to,
          formattedValidFrom: dateString(valid_from),
          formattedValidTo: dateString(valid_to),
          description: description,
          assessmentSkills: quotas.assessment_skills
            ?.map(humanize)
            .map(s => (s === "Core V1" ? "Core" : s))
            .map(s => (s === "Spoken" ? "Speaking" : s))
            .join(", "),
          discount: discount
        };
        return item;
      }
    );

    state.organisation.certificateCredits.items = organisation.certificate_credits?.map(
      ({ code, valid_from, valid_to, description, quotas }) => {
        const item = {
          code,
          validFrom: valid_from,
          validTo: valid_to,
          formattedValidFrom: dateString(valid_from),
          formattedValidTo: dateString(valid_to),
          description: description,
          assessmentSkills: quotas.assessment_skills
            ?.map(humanize)
            .map(s => (s === "Core V1" ? "Core" : s))
            .map(s => (s === "Spoken" ? "Speaking" : s))
            .join(", ")
        };
        return item;
      }
    );
  } catch (error) {
    console.error(error);
    state.items = state.unfilteredOrganisations = [];
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Could not load organisations.</strong>`
      },
      { root: true }
    );
  }
  state.busy = false;
};

//Only works in PROD
const loadOrganisationStatement = async (
  { state, dispatch },
  organisationId
) => {
  state.busy = true;
  state.tableauToken = "";
  state.showOrganisationStatementModal = true;
  try {
    const response = await apiClient.get(`/v0.1/dataflow/accountusage`, {
      params: { organisation_id: organisationId }
    });
    const {
      data: { token }
    } = response;
    state.tableauToken = token;
  } catch (error) {
    console.error(error);
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Could not load statement.</strong>`
      },
      { root: true }
    );
  }
  state.busy = false;
};

const loadHubspotCompanyName = async ({ state }, { srcElement }) => {
  clearTimeout(state.debounce);

  state.hubspotCompanyName = "";
  if (!srcElement.value.length) return;

  state.busy = true;
  const hubspot_company_id = srcElement.value;

  // @note: If further requests are made withing 500ms, this timeout will be cancelled.
  state.debounce = setTimeout(async () => {
    try {
      const {
        data: { organisation_name }
      } = await apiClient.post("/v0.1/dataflow/b2b/verifyhubspotid", {
        hubspot_company_id
      });
      state.hubspotCompanyName = organisation_name;
    } catch (error) {
      state.hubspotCompanyName =
        "🤔 Company not found for the above Hubspot ID.";
    }
    state.busy = false;
  }, 500);
};

const manageOrgAdminAccountStatus = async (
  { state, dispatch },
  { organisationId, email, action }
) => {
  state.busy = true;
  try {
    const confirmEmail = prompt(
      `Please type admin email (${email}) to confirm your action.`
    );
    if (confirmEmail?.trim() !== email) {
      dispatch(
        "snackbar/snack",
        {
          mode: "error",
          message: `⚠️ Error: <strong class="px-4">Email dose not matched.</strong>`
        },
        { root: true }
      );
      state.busy = false;
      return;
    }

    await apiClient.post(`/v1.11/organisations/manage/admins`, {
      items: [
        { email, account_status: action, organisation_id: organisationId }
      ]
    });

    logEvent("CMS_ORG_ADMIN_ACCOUNT_STATE_CHANGE", {
      org_id: organisationId,
      email,
      new_status: action
    });
    dispatch(
      "snackbar/snack",
      {
        mode: "success",
        message: `✅ Admin account status updated successfully.`
      },
      { root: true }
    );
    const findIdex = state.organisation.users.items.findIndex(
      user => user.email === email
    );
    state.organisation.users.items = [
      ...state.organisation.users.items.slice(0, findIdex),
      { ...state.organisation.users.items[findIdex], active: action },
      ...state.organisation.users.items.slice(findIdex + 1)
    ];
  } catch (error) {
    console.error(error);
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Could not update account status for admin.</strong>`
      },
      { root: true }
    );
  }
  state.busy = false;
};

const checkForOrgNameDifferences = async () => {
  try {
    const result = await apiClient.get(
      `/v1.11/organisations/hubspot/companies`
    );
    console.log(result);
  } catch (err) {
    console.error(err);
  }
};

export default {
  namespaced: true,
  state: {
    debounce: null,
    busy: true,
    showOrganisationModal: false,
    showOrganisationStatementModal: false,
    tableauToken: "",
    headers,
    items: [],
    rawOrganisations: [],
    unfilteredOrganisations: [],
    currentStatusFilter: "Draft",
    organisation,
    hubspotCompanyName: ""
  },
  mutations: {
    setShowOrganisationModal(state, showOrganisationModal) {
      state.showOrganisationModal = showOrganisationModal;
    },
    setShowOrganisationStatementModal(state, showOrganisationStatementModal) {
      state.showOrganisationStatementModal = showOrganisationStatementModal;
    },
    setHubspotCompanyName(state, hubspotCompanyName) {
      state.hubspotCompanyName = hubspotCompanyName;
    }
  },
  actions: {
    fetchOrganisations,
    addOrganisation,
    loadOrganisation,
    resetOrganisationState,
    loadHubspotCompanyName,
    loadOrganisationStatement,
    manageOrgAdminAccountStatus,
    checkForOrgNameDifferences
  }
};
