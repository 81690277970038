export default [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "browse",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-browse" */ "@/views/dashboard/Browse.vue"
          )
      },
      {
        path: "leads",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-leads" */ "@/views/dashboard/Leads.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        },
        children: [
          {
            path: "",
            name: "leads",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-leads-list-all" */ "@/views/dashboard/leads/ListAllLeads.vue"
              )
          },
          {
            path: "carousel",
            name: "leads-carousel",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-leads-carousel" */ "@/views/dashboard/leads/CarouselLeads.vue"
              )
          },
          {
            path: "dashboard",
            name: "leads-dashboard",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-leads-dashboard" */ "@/views/dashboard/leads/DashboardLeads.vue"
              )
          }
        ]
      },
      {
        path: "cert-regen",
        name: "CertRegen",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-cert-regen" */ "@/views/dashboard/CertRegen.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "certificate-preview",
        name: "CertificatePreview",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-certificate-preview" */ "@/views/dashboard/CertificatePreview.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "user-events",
        name: "UserEvents",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-user-events" */ "@/views/dashboard/UserEvents.vue"
          ),
        meta: {
          allowedRoles: [
            "customer_service_account",
            "customer_service_account",
            "dev",
            "proctor"
          ]
        }
      },
      {
        path: "user-deletion",
        name: "UserDeletion",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-user-deletion" */ "@/views/dashboard/UserDeletion.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "proctoring/:uuid?",
        name: "Proctoring",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-proctoring" */ "@/views/dashboard/proctoring/ProctoringList.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "proctor"]
        }
      },
      {
        path: "proctoring-old",
        name: "Proctoring: Old Version",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-proctoring-oldversion" */ "@/views/dashboard/ProctoringOldversion.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "proctor"]
        }
      },
      {
        path: "certificate-vouchers/:new?",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-certificate-vouchers" */ "@/views/dashboard/CertificateVouchers.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "connect-codes/:new?",
        name: "ConnectCodes",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-connect-codes" */ "@/views/dashboard/ConnectCodes.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "connect-codes/edit/:uuid",
        name: "ConnectCodesEdit",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-connect-codes" */ "@/views/dashboard/ConnectCodes.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "items",
        name: "ItemsList",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-items-list" */ "@/views/dashboard/item/List.vue"
          ),
        meta: {
          allowedRoles: ["item_admin"]
        }
      },
      {
        path: "items/to-do",
        name: "ItemsToDo",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-items-todo" */ "@/views/dashboard/item/ToDo.vue"
          )
      },
      {
        path: "items/new/:templateIndex", // templateIndex is the position of the template in the to-do items array
        name: "ItemsNew",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-items-new" */ "@/views/dashboard/item/Item.vue"
          )
      },
      {
        path: "items/update/:uuid",
        name: "ItemsUpdate",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-items-update" */ "@/views/dashboard/item/Item.vue"
          )
      },
      {
        path: "items/edit",
        redirect: to => ({
          name: "ItemsUpdate", //  [tableau-fix] it has old url structure i.e [SITE_URL]/items/edit?mode=update&id={ITEM_ID} which is not working thus redirecting to new url structure
          params: {
            uuid: to.query.id
          },
          query: {}
        })
      },
      {
        path: "grading",
        name: "Grading",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-grading-list" */ "@/views/dashboard/grading/GradingList.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "certify",
        name: "Certify",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-certify" */ "@/views/dashboard/Certify.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "certificate-credits/:new?",
        name: "CertificateCredits",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-certificate-credits" */ "@/views/dashboard/CertificateCredits.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "certificate-credits/edit/:uuid",
        name: "CertificateCreditsEdit",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-certificate-credits" */ "@/views/dashboard/CertificateCredits.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "certificate-vouchers/edit/:uuid",
        name: "CertificateVouchersEdit",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-certificate-vouchers" */ "@/views/dashboard/CertificateVouchers.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "users",
        name: "Users",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-users" */ "@/views/dashboard/Users.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "sittings",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-sittings-index" */ "@/views/dashboard/sittings/Index.vue"
          ),
        children: [
          {
            path: "",
            name: "Sittings",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-sittings-list" */ "@/views/dashboard/sittings/List.vue"
              )
          },
          {
            path: ":sittingId",
            name: "SittingDetail",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-sittings-detail" */ "@/views/dashboard/sittings/Detail.vue"
              )
          }
        ],
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "assessment-instances",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-assessment-instances-index" */ "@/views/dashboard/assessment-instances/Index.vue"
          ),
        children: [
          {
            path: "",
            name: "AssessmentInstances",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-assessment-instances-list" */ "@/views/dashboard/assessment-instances/List.vue"
              )
          },
          {
            path: "new",
            name: "NewAssessmentInstance",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-assessment-instances-new" */ "@/views/dashboard/assessment-instances/New.vue"
              )
          },
          {
            path: ":assessmentInstanceId",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-assessment-instances-index" */ "@/views/dashboard/assessment-instances/Index.vue"
              ),
            children: [
              {
                path: "",
                name: "AssessmentInstanceDetail",
                component: () =>
                  import(
                    /* webpackChunkName: "dashboard-assessment-instances-detail" */ "@/views/dashboard/assessment-instances/Detail.vue"
                  )
              },
              {
                path: "items",
                name: "AssessmentInstanceItems",
                component: () =>
                  import(
                    /* webpackChunkName: "dashboard-assessment-instances-items" */ "@/views/dashboard/assessment-instances/Items.vue"
                  )
              }
            ]
          }
        ],
        meta: {
          allowedRoles: ["customer_service_account", "item_admin"]
        }
      },
      {
        path: "assessment-types",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-assessment-types-index" */ "@/views/dashboard/assessment-types/Index.vue"
          ),
        children: [
          {
            path: "",
            name: "AssessmentTypes",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-assessment-types-list" */ "@/views/dashboard/assessment-types/List.vue"
              )
          },
          {
            path: ":assessmentTypeID",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-assessment-types-index" */ "@/views/dashboard/assessment-types/Index.vue"
              ),
            children: [
              {
                path: "",
                name: "AssessmentTypeDetail",
                component: () =>
                  import(
                    /* webpackChunkName: "dashboard-assessment-types-detail" */ "@/views/dashboard/assessment-types/Detail.vue"
                  )
              },
              {
                path: "instances",
                name: "AssessmentTypeInstances",
                component: () =>
                  import(
                    /* webpackChunkName: "dashboard-assessment-types-instances" */ "@/views/dashboard/assessment-types/Instances.vue"
                  )
              }
            ]
          }
        ],
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "organisations/:uuid?",
        name: "Organisations",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-organisations" */ "@/views/dashboard/Organisations.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "organisations/statement/:uuid?",
        name: "Organisations-Statement",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-organisations" */ "@/views/dashboard/Organisations.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account", "b2b_support_staff"]
        }
      },
      {
        path: "access-management/:uuid?",
        name: "Access-management",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-access-management" */ "@/views/dashboard/AccessManagement.vue"
          ),
        meta: {
          allowedRoles: ["role_manager"]
        }
      },
      {
        path: "revoke-certificate",
        name: "Revoke certificate",
        component: () =>
          import(
            /* webpackChunkName: "revoke-certificate" */ "@/views/dashboard/RevokeCertificate.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "reinstate-certificate",
        name: "Reinstate certificate",
        component: () =>
          import(
            /* webpackChunkName: "reinstate-certificate" */ "@/views/dashboard/ReinstateCertificate.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "user-configurations",
        name: "User Configurations",
        component: () =>
          import(
            /* webpackChunkName: "user-configurations" */ "@/views/dashboard/UserConfigurations.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        }
      },
      {
        path: "fluentify-mailchimp-sync-status",
        component: () =>
          import(
            /* webpackChunkName: "fluentify-mailchimp-sync-status" */ "@/views/dashboard/fluentify/index.vue"
          ),
        meta: {
          allowedRoles: ["customer_service_account"]
        },
        children: [
          {
            name: "fluentify-list",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "fluentify-mailchimp-sync-status-list" */ "@/views/dashboard/fluentify/list.vue"
              )
          },
          {
            name: "fluentify-details",
            path: ":batchId",
            component: () =>
              import(
                /* webpackChunkName: "fluentify-mailchimp-sync-status-details" */ "@/views/dashboard/fluentify/details.vue"
              )
          }
        ]
      },
      {
        path: "retire-app-version",
        name: "Retire App Version",
        component: () =>
          import(
            /* webpackChunkName: "reinstate-certificate" */ "@/views/dashboard/RetireAppVersion.vue"
          ),
        meta: {
          allowedRoles: ["role_manager"]
        }
      }
    ]
  },
  {
    path: "/no-access",
    name: "noAccess",
    component: () =>
      import(/* webpackChunkName: "no-access" */ "@/views/NoAccess.vue")
  },
  {
    path: "/no-permissions",
    name: "noPermissions",
    component: () =>
      import(
        /* webpackChunkName: "no-Permissions" */ "@/views/NoPermissions.vue"
      )
  },
  {
    path: "*",
    name: "notfound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue")
  }
];
