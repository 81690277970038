import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { apiClient } from '@/services/api';
import axios from 'axios';

export interface AssessmentInstancesState {
  assessmentInstances: unknown[];
  assessmentInstanceItems: Map<string, unknown[]>;
  busy: boolean;
  pagination: {
    perPage: number;
    offset: number;
  };
  searchKeyword?: string;
  filters: {
    assessment_instance_id?: string;
    assessment_type_id?: string;
    assessment_type_name?: string;
  };
  error?: Error;
}

export enum AssessmentInstanceMutations {
  SET_BUSY = 'SET_BUSY',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_FILTERS = 'SET_FILTERS',
  SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD',
  SET_ASSESSMENT_INSTANCES = 'SET_ASSESSMENT_INSTANCES',
  SET_ASSESSMENT_INSTANCE_ITEMS = 'SET_ASSESSMENT_INSTANCE_ITEMS',
  SET_ERROR = 'SET_ERROR',
}

const initialState: AssessmentInstancesState = {
  busy: false,
  pagination: {
    perPage: 1000,
    offset: 0,
  },
  filters: {
    assessment_instance_id: undefined,
    assessment_type_id: undefined,
    assessment_type_name: undefined,
  },
  assessmentInstanceItems: new Map(),
  assessmentInstances: [],
  searchKeyword: undefined,
}

const state: AssessmentInstancesState = {
  ...initialState,
}

const mutations: MutationTree<AssessmentInstancesState> = {
  [AssessmentInstanceMutations.SET_BUSY](state, busy: boolean) {
    state.busy = busy
  },
  [AssessmentInstanceMutations.SET_PAGINATION](state, pagination: { perPage: number; offset: number }) {
    state.pagination = pagination
  },
  [AssessmentInstanceMutations.SET_SEARCH_KEYWORD](state, searchKeyword: string | undefined) {
    state.searchKeyword = searchKeyword
  },
  [AssessmentInstanceMutations.SET_FILTERS](state, filters: { assessment_instance_id?: string; assessment_type_id?: string; assessment_type_name?: string }) {
    state.filters = filters
  },
  [AssessmentInstanceMutations.SET_ASSESSMENT_INSTANCES](state, assessmentInstances: unknown[]) {
    state.assessmentInstances = assessmentInstances
  },
  [AssessmentInstanceMutations.SET_ASSESSMENT_INSTANCE_ITEMS](state, assessmentInstanceItems: Map<string, unknown[]>) {
    state.assessmentInstanceItems = assessmentInstanceItems
  },
  [AssessmentInstanceMutations.SET_ERROR](state, error: Error | undefined) {
    state.error = error
  }
}

const actions: ActionTree<AssessmentInstancesState, any> = {
  fetchAssessmentInstances: async ({ state, commit, rootState }) => {
    try {
      commit(AssessmentInstanceMutations.SET_BUSY, true)
      commit(AssessmentInstanceMutations.SET_ERROR, undefined)
      const params = {
        user_id: rootState.account.user.user_id,
        page_size: state.pagination.perPage,
        offset: state.pagination.offset
      }
      if (state.filters.assessment_instance_id && state.filters.assessment_instance_id.length > 3) {
        params['assessment_instance_id'] = state.filters.assessment_instance_id
      }
      if (state.filters.assessment_type_id && state.filters.assessment_type_id.length > 3) {
        params['assessment_type_id'] = state.filters.assessment_type_id
      }
      if (state.filters.assessment_type_name && state.filters.assessment_type_name.length > 3) {
        params['assessment_type_name'] = `${state.filters.assessment_type_name}`
      }
      const { data: { results, page_size, offset } } = await apiClient.get('/v1.11/assessment/instances', { params })
      commit(AssessmentInstanceMutations.SET_ASSESSMENT_INSTANCES, results)
      commit(AssessmentInstanceMutations.SET_PAGINATION, { perPage: page_size, offset })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.response?.data.message && commit(AssessmentInstanceMutations.SET_ERROR, new Error(err.response.data.message))
      } else {
        commit(AssessmentInstanceMutations.SET_ERROR, err)
      }
    } finally {
      commit(AssessmentInstanceMutations.SET_BUSY, false)
    }
  },
  fetchAssessmentInstanceItems: async ({ state, commit, rootState }, { assessment_instance_id }) => {
    try {
      if (!assessment_instance_id) return;
      commit(AssessmentInstanceMutations.SET_BUSY, true)
      commit(AssessmentInstanceMutations.SET_ERROR, undefined)
      const params = {
        user_id: rootState.account.user.user_id,
        assessment_instance_id: assessment_instance_id
      }
      const { data: { results } } = await apiClient.get('/v1.11/assessment/instance', {
        params
      })
      state.assessmentInstanceItems.set(assessment_instance_id, results)
      commit(AssessmentInstanceMutations.SET_ASSESSMENT_INSTANCE_ITEMS, new Map(state.assessmentInstanceItems))
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.response?.data.message && commit(AssessmentInstanceMutations.SET_ERROR, new Error(err.response.data.message))
      } else {
        commit(AssessmentInstanceMutations.SET_ERROR, err)
      }
    } finally {
      commit(AssessmentInstanceMutations.SET_BUSY, false)
    }
  },
  toggleStatus: async ({ dispatch }, { assessment_instance_id, status: active }) => {
    const payload = {
      "instance_id": assessment_instance_id,
      active,
    }
    try {
      await apiClient.patch('/v1.11/assessment/instance', active ? payload : {
        ...payload, "retired_reason": "Turned off manually in the CMS"
      })
      return true
    } catch (error) {
      console.error(error)
      const messages = ['⚠️ Error: <strong class="px-4">Could not change status of the assessment instance.</strong>']
      if (axios.isAxiosError(error) && error.response) {
        messages.push(error.response?.data.message)
      }
      dispatch('snackbar/snack', {
        mode: 'error',
        message: messages.join('<br/><br/>'),
      }, { root: true })
      return false
    }
  }
}

const getters: GetterTree<AssessmentInstancesState, unknown> = {
  assessmentInstances: (state) => state.assessmentInstances,
  isBusy: (state) => state.busy,
  error: (state) => state.error,
  pagination: (state) => state.pagination,
  searchKeyword: (state) => state.searchKeyword,
  filters: (state) => state.filters,
  assessmentInstanceItems: (state) => (assessment_instance_id) => state.assessmentInstanceItems.has(assessment_instance_id) ? state.assessmentInstanceItems.get(assessment_instance_id) : [],
  headers: () => {
    return [{
      text: 'Assessment Instance Id',
      value: 'assessment_instance_id',
    }, {
      text: 'Assessment Type',
      value: 'assessment_type_name'
    }, {
      text: 'Number of Sittings Started',
      value: 'no_of_sittings_started'
    }, {
      text: 'Earliest Sittings Started At',
      value: 'earliest_sitting_started_at'
    }, {
      text: 'Latest Sittings Started At',
      value: 'latest_sitting_started_at'
    }, {
      text: 'Retired On',
      value: 'retired_on'
    }, {
      text: 'Retired Reason',
      value: 'retired_reason'
    }, {
      text: 'Status',
      value: 'status'
    }, {
      text: '',
      value: 'actions'
    }]
  },
  itemsTableHeaders: () => {
    return [{
      text: 'Item ID',
      value: 'item_id'
    }, {
      text: 'Title',
      value: 'title'
    }, {
      text: 'Template Type',
      value: 'template_type'
    }, {
      text: 'Skill',
      value: 'skill'
    }, {
      text: 'CEFR Level',
      value: 'cefr_level'
    }, {
      text: 'Batch',
      value: 'batch'
    }, {
      text: 'Bucket',
      value: 'bucket'
    }, {
      text: 'Domain',
      value: 'domain'
    }, {
      text: 'Section',
      value: 'section'
    }, {
      text: 'Item Sequence',
      value: 'sequence'
    }, {
      text: 'Question Id',
      value: 'question_id'
    }, {
      text: 'Question',
      value: 'question'
    }, {
      text: 'Question Template',
      value: 'question_template'
    }, {
      text: 'Question Type',
      value: 'question_type'
    }, {
      text: 'Datapoint',
      value: 'data_point'
    }, {
      text: 'Question Position',
      value: 'position'
    }, {
      text: 'Question Active',
      value: 'active'
    }, {
      text: 'Date Retired',
      value: 'date_retired'
    }, {
      text: 'Retired Reason',
      value: 'retired_reason'
    }]
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}