/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { apiClient } from '@/services/api'
import dateString from '@/plugins/filters/date-string'

// Generate a human-friendly label e.g. from "DND_READING" to "Dnd Reading"
const humanize = (string: string) => string && string
  .trim()
  .toLowerCase()
  .split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ')

const headers = [
  { text: 'Started ', value: 'started_time'},
  { text: 'Sitting', value: 'status' },
  { text: 'Peer', value: 'grading_status' },
  { text: 'Assessment Type', value: 'assessment_type_name' },
  { text: 'Skill', value: 'assessment_skills' },
  { text: 'Score', value: 'score' },
  { text: 'CEFR', value: 'cefr' },
  { text: '', value: 'assessment_instance_id', align: ' d-none'},
  { text: '', value: 'sitting_id', align: ' d-none'},
  { text: '', value: 'user_id', align: ' d-none'},
  { text: '', value: 'actions', align: 'right', sortable: false },
]

// @todo: Generate these filters from the items data?
const filters = [
  {
    label: 'Sitting Status',
    value: 'status',
    items: [
      { text: 'All' },
      { text: 'In Progress' },
      { text: 'Complete' },
      { text: 'Verified' },
      { text: 'Test Ended' },
      { text: 'Abandoned' },
    ],
    selectedIndex: 0,
  },
  {
    label: 'Peer Grading Status',
    value: 'grading_status',
    items: [
      { text: 'All' },
      { text: 'Graded' },
      { text: 'Pending' },
      { text: 'Blocked' },
    ],
    selectedIndex: 1, // Default to "Graded"
  },
]

const fetchItems = async ({ dispatch, state, rootState }) => {
  state.busy = true

  // This is the date that Writing assessments were introduced
  const inaugrualWritingAssessmentDate = '2022-09-01'
  const params = {
    user_id: rootState.account.user.user_id,
    application: 'CMS',
    include_item_responses: true,
    page_size: 5000,
    assessment_type: 'writing%',
    date_from: inaugrualWritingAssessmentDate,
    date_to: new Date().toISOString(),
  }

  try {
    const { data: { results } } = await apiClient.get('/v1.13/exam/sittings', { params })
    const unfilteredItems = results.map(item => {
      return {
        sitting_id: item.sitting_id,
        user_id: item.user_id,
        assessment_instance_id: item.assessment_instance_id,
        assessment_type_id: item.assessment_type_id,
        assessment_type_name: humanize(item.assessment_type_name),
        status: humanize(item.status),
        grading_status: humanize(item.grading_status),
        started_time: item.started_time,
        formattedStartTime: dateString(item.started_time),
        assessment_skills: humanize(item.assessment_skills.join('')), // Note: This should only be "Writing"
        score: item.score.englishscore,
        cefr: item.score.cefr ?? '-',
      }
    })

    // Give the unfiltered items to the store, then apply any existing filters
    state.unfilteredItems = unfilteredItems
    dispatch('filterItems')
  }
  catch (error) {
    console.error(error)
    state.items = state.unfilteredItems = []
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Could not load items.</strong>`,
    }, { root: true })
  }
  state.busy = false
}

const filterItems = async ({ state }) => {
  const { filters } = state

  // For each row in items, check if each property matches the corresponding filter value
  const filteredItems = state.unfilteredItems.filter(item => {
    const isCool = filters.every(({ value: filterBy, selectedIndex, items }) => {
      const filterText = items[selectedIndex].text
      if (filterText === 'All') return true
      const itemValue = item[filterBy]
      if (!itemValue) return false
      if (filterBy === 'assessment_skills') return itemValue.includes(filterText)
      return filterText.toLowerCase() === itemValue.toLowerCase()
    })
    return isCool
  })

  state.items = filteredItems
}

export default {
  namespaced: true,
  state: {
    busy: true,
    headers,
    items: [],
    unfilteredItems: [],
    filters,
  },
  actions: {
    fetchItems,
    filterItems,
  },
  mutations: {
    setFilters(state, filters) { state.filters = filters }
  },
}
