enum Platform {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios'
}

const callbackFunction = (callback: (err?: Error, data?: any) => void, timeout: number = (1000 * 10)) => {

  const callbackFunctionName = `cb_${Math.floor(Math.random() * 100000)}_${Date.now()}`
  let hasTimedout = false
  
  const timeoutCancelToken = setTimeout(() => {
    hasTimedout =  true
    callback(new Error('Native request timeout'))
  }, timeout)

  window[callbackFunctionName] = (err, data) => {

    delete window[callbackFunctionName]
    if (hasTimedout) return

    clearTimeout(timeoutCancelToken)
    
    if (callback) {
      callback(err, data)
    }
  }

  return callbackFunctionName
}

const callbackFunctionNoTimeout = (callback: (err?: Error, data?: any) => void) => {

  const callbackFunctionName = `cb_${Math.floor(Math.random() * 100000)}_${Date.now()}`
  
  window[callbackFunctionName] = (err, data) => {
    if (callback) {
      callback(err, data)
    }
  }

  return callbackFunctionName
}

export {
  Platform,
  callbackFunction,
  callbackFunctionNoTimeout
}