import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#220a5a',
        secondary: '#02c0ab',
        accent: '#8c9eff',
        error: '#b71c1c',
        default: '#f8f8f8',
        orange: '#ff4635',
        teal: '#04ae9b',
      },
    },
  },
})
