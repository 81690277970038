
export interface ExtraMetrics {
  confidence: number;
  words_per_minute: number;
  syllables_per_minute: number;
  phones_per_minute: number;
  correct_words_per_minute: number;
  correct_syllables_per_minute: number;
  correct_phones_per_minute: number;
  articulated_words_per_minute: number;
  articulated_syllables_per_minute: number;
  articulated_phones_per_minute: number;
  correct_words_ratio: number;
  correct_syllables_ratio: number;
  correct_phones_ratio: number;
  reaction_time_s: number;
  pause_ratio: number;
  pause_ratio_without_initial: number;
}

export interface Feedback {
  id: number;
  language: string;
  text: string;
  audio_link: string;
}

export interface Phoneme {
  text: string;
  start_index: number;
  end_index: number;
  start_time: number;
  end_time: number;
  trans: string;
  trans_arpabet: string;
  decision: string;
  nativeness_score: number;
  phoneme_error: string;
  phoneme_error_arpabet: string;
  feedback: Feedback[];
}

export interface WordStress {
  start_index: number;
  end_index: number;
  stress_level_measured: string;
  decision: string;
}

export interface Word {
  text: string;
  text_orig: string;
  start_index: number;
  end_index: number;
  start_time: number;
  end_time: number;
  trans_arpabet: string;
  nativeness_score: number;
  decision: string;
  decoded: boolean;
  phonemes: Phoneme[];
  word_stress: WordStress[];
}

export interface Utterance {
  initial_silence: boolean;
  total_time: number;
  decision: string;
  nativeness_score: number;
  nativeness_score_partial: number;
  extra_metrics: ExtraMetrics;
  sentence: string;
  sentence_id: number;
  has_speech: boolean;
  attempt_type: string;
  words: Word[];
}

export interface ELSAScore {
  api_version: string;
  utterance: Utterance[];
  total_time: number;
  success: boolean;
}

export enum WordStatus {
  MISSING = 'missing',
  INCORRECT = 'incorrect',
  MISPRONOUNCED = 'mispronounced',
  CORRECT = 'correct',
}

export enum WordDecision {
  INCORRECT = 'incorrect',
  CORRECT = 'correct',
  ALMOST_CORRECT = 'almost_correct',
  ERROR = 'error',
}

export interface MarkedWord {
  word: string;
  sequence: number;
  status: WordStatus;
}

export interface ELSAResult {
  rating: number;
  words: MarkedWord[];
}

export enum ELSAScoreApiVersion {
  V_2_0 = "2.0"
}