<template>
  <div class="es-questions-text-or-image" :class="{ 'disabled': disabled}">
    <div class="es-questions-container">
      <div class="es-questions-text-image">
        <h2 v-if="title">{{ title }}</h2>
        <img v-if="imageUrl" :src="imageUrl" :title="title" />
      </div>
      <div class="es-questions-options">
        <template v-for="option in options">
          <es-tap-btn @click="() => toggleSelection(option.id)" :class="{ 'active': isSelected(option.id)}" :key="`opt_${option.id}`">{{ option.value }}</es-tap-btn>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-questions-text-or-image',
  props: {
    title: {
      type: String,
      required: false,
    },
    imageUrl: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    selectedOptions: undefined
  }),
  methods: {
    isSelected(optionId) {
      return this.selectedOptions === optionId
    },
    toggleSelection(optionId) {
      if (this.disabled) {
        return;
      }
      this.selectedOptions = optionId;
      // if (this.selectedOptions[questionId].includes(optionId)) {
      //   const index = this.selectedOptions[questionId].indexOf(optionId)
      //   this.selectedOptions[questionId] = [
      //     ...this.selectedOptions[questionId].slice(0, index),
      //     ...this.selectedOptions[questionId].slice(index+1)
      //   ]
      // } else {
        
      // }
      // this.selectedOptions[questionId] = [
      //   optionId
      // ]
      this.emitResponse()
    },
    emitResponse() {
      if (this.selectedOptions) {
        this.$emit('input', {
          selectedOptionId: this.selectedOptions
        });
      } else { 
        this.$emit('input', null);
      }
    }
  }
}
</script>