import axios from 'axios'
import { fetchScore, parseScore } from '@/services/elsa'
import { container } from '@es/data'
import MarkingInteractor from '@es/domain/lib/interactors/MarkingInteractor'

export default {
  namespaced: true,
  state: {
    busy: false,
    atomData: null,
    error: null,
    elsaBusy: false,
    elsaResult: null
  },
  mutations: {
    updateAtomData: (state, atomData) => {
      state.atomData= atomData
    },
    updateError: (state, error) => {
      state.error= error
    },
    updateBusy: (state, busy) => {
      state.busy= busy
    },
    updateELSABusy: (state, elsaBusy) => {
      state.elsaBusy= elsaBusy
    },
    updateELSAResult: (state, elsaResult) => {
      state.elsaResult= elsaResult
    }
  },
  actions: {
    loadAtom: async ({ commit }) => {
      try {
        commit('updateBusy', true)
        commit('updateError', null)
        const markingInteractor = container.resolve<MarkingInteractor>('MarkingInteractor');
        const atomData = await markingInteractor.fetchMarkingAtom();
        commit('updateAtomData', atomData)
      } catch(err) {
        commit('updateError', err)
        commit('updateAtomData', null)
      } finally {
        commit('updateBusy', false)
      }
    },
    saveAtomMarking: async ({ commit }, markingResponse) => {
      try {
        commit('updateBusy', true)
        commit('updateError', null)
        const markingInteractor = container.resolve<MarkingInteractor>('MarkingInteractor')
        await markingInteractor.submitMarkingResponses(markingResponse)
        commit('updateELSAResult', null)
      } catch(err) {
        commit('updateError', err)
      } finally {
        commit('updateBusy', false)
      }
    },
    fetchELSAScore: async ({ commit }, { sentence , audioURL }) => {
      try {
        console.log('fetchELSAScore');
        commit('updateELSABusy', true)
        commit('updateError', null);

        const audioResponse = await axios.get(audioURL, {
          responseType: 'blob',
        });

        const { data } = await fetchScore(sentence, audioResponse.data);
        console.log('data', data)
        const elsaResult = parseScore(data)
        console.log('elsaResult', elsaResult);
        commit('updateELSAResult', elsaResult)
      } catch(err) {
        commit('updateELSAResult', null)
      } finally {
        commit('updateELSABusy', false)
      }
    }
  },
  getters: {
    isBusy: state => state.busy,
    atomData: state => state.atomData,
    error: state => state.error,
    isELSABusy: state => state.elsaBusy,
    elsaResult: state => state.elsaResult
  }
}