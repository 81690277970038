import { CameraImageRatio } from "@es/domain/lib/models/CameraService";
import { ProctoringCredential } from "@es/domain/lib/models/Proctoring";
import ProctoringRepository from "@es/domain/lib/repositories/ProctoringRepository";
import CloudStorage from "@es/domain/lib/services/CloudStorage";
import NativeCameraService from "@es/domain/lib/services/NativeCameraService";
import { inject, singleton } from "tsyringe";

@singleton()
export default class ProctoringRepositoryImpl implements ProctoringRepository {

  constructor (
    @inject('NativeCameraService') private nativeCameraService: NativeCameraService,
    @inject('CloudStorage') private cloudStorage: CloudStorage,
  ) {}

  private _credential!: ProctoringCredential;
  // private _totalItems: number;
  private _proctoringIndexes: number[] = [];
  // private _noOfImages = 3;
  private _proctoringState: Map<number, boolean> = new Map();

  /**
   * 
   * @param min number
   * @param max number
   * @returns number
   */
  private _getIndexBetween(min: number, max: number): number {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private _shouldProctor = (index: number) => this._proctoringIndexes.includes(index)

  init(totalItems: number, credential: ProctoringCredential, noOfImages: number) {
    // this._totalItems = totalItems
    this._credential = credential
    // this._noOfImages = noOfImages
    this._proctoringIndexes = []

    let min = 1;
    this._proctoringState = new Map<number, boolean>();
    for (let index = 0; index < noOfImages; index++) {
      const max: number = Math.ceil(totalItems / noOfImages) * (index + 1);
      const proctorIndex = this._getIndexBetween(min, max === totalItems ? max - 2 : max)
      this._proctoringIndexes.push(proctorIndex)
      this._proctoringState.set(proctorIndex, false)
      min = max + 1
    }
  }

  /**
   * 
   * @param index number
   */
  async proctor(index: number) {
    try {
      if (!this._shouldProctor(index)) {
        return ; // don't do anything
      }
      // console.log('proctor..');
      
      (await this.nativeCameraService).captureImage({
        proctoringIndex: this._proctoringIndexes.indexOf(index),
        format: 'jpg',
        ratio: CameraImageRatio.RATIO_3_4,
        onImageReady: async (err, capturedImage) => {
          if (err) {
            // console.error('onImageReady:err', err);
            return;
          }
          // console.log('capturedImage', capturedImage)
          if (capturedImage) {
            const { Bucket, AccessKeyId, SecretAccessKey, SessionToken, FilePath }  = this._credential;
            const imageName = `spoken_proctoring_${Number(capturedImage.proctoringIndex + 1).toString().padStart(3, '0')}.jpg`;
            // const destination = `${FilePath}`
            const destination = `${Bucket}/${FilePath}`.slice(0, -1)
            // console.log('destination', destination)
            await this.cloudStorage.upload(imageName, capturedImage.image.toString(), `${destination}/proctoring_images`, {
              accessKeyId: AccessKeyId,
              secretAccessKey: SecretAccessKey,
              sessionToken: SessionToken
            } , capturedImage.imageType)
            // console.log('uploadResponse', uploadResponse)
  
            this._proctoringState.set(index, true);
          }
        }
      });
    } catch(err) {
      console.log(err)
    }
  }

  canSubmit(): Promise<boolean> {
    // TODO: look for better options for proctoring check 
    return new Promise<boolean>((resolve, reject) => {
      resolve(true);
      // console.log('this._proctoringState', this._proctoringState);
      // console.log('this._proctoringState.size', this._proctoringState.size);
      // const interval = setInterval(() => {
      //   if (!Array.from(this._proctoringState.values()).includes(false)) {
      //     resolve(true)
      //   }
      // }, 100)

      // if it takes more than 3sec we will discard wait.
      // setTimeout(() => {
      //   clearInterval(interval)
      //   resolve(false)
      //   // reject(new Error('Unable to determine Proctoring state'))
      // }, 3 * 1000)
    })
  }

  clear() {
    this._proctoringIndexes = []
    this._proctoringState = new Map<number, boolean>()
  }

  async showCameraPreview(width: number, height: number, cameraCheck = false) {
    (await this.nativeCameraService).startCameraPreview({
      width,
      height,
      cameraCheck,
      enable: true
    })
  }

  async hideCameraPreview() {
    (await this.nativeCameraService).stopCameraPreview()
  }

}