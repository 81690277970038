<template>
  <div class="es-audio-recorder" :class="{ 'recording': recording, 'disabled': disabled, 'busy': busy }">
    <es-tooltip v-if="showTooltip" tooltip-position="top">
      <p v-if="title" class="title">{{ title }}</p>
      <p v-if="subTitle" class="sub-title">{{ subTitle }}</p>
    </es-tooltip>
    <es-tooltip v-if="!showTooltip && error" color="orange" tooltip-position="top">
      <p v-if="title" class="title">{{ error.message }}</p>
      <p v-if="subTitle" class="sub-title">Hold to record.</p>
    </es-tooltip>
    <div class="button">
      <div class="icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="83" viewBox="0 0 52 83" class="record-icon">
          <g fill="none" fill-rule="evenodd">
              <g id="micPath" stroke="#220A5A" stroke-width="8">
                  <g>
                      <g transform="translate(-180 -719) translate(131 684) translate(53 35)">
                          <rect width="19.704" height="44.148" x="12.148" y="4" rx="9.852"/>
                          <path stroke-linecap="round" d="M44 31.778v7.333c0 12.15-9.85 22-22 22s-22-9.85-22-22v-7.333"/>
                          <path d="M22 61.926L22 79.037"/>
                          <path stroke-linecap="round" d="M37 79L7 79"/>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
        <div class="busy"></div>
      </div>
    </div>
    <div class="touch-area" ref=button></div>
  </div>
</template>
<script>
export default {
  name: 'es-audio-recorder',
  data: () => ({
    showTooltip: true,
    done: false,
    debounce: null,
    canMove: false,
    isMouseDown: false
  }),
  props: {
    error: {
      type: Error,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    recording: {
      type: Boolean,
      default: false,
      required: false
    },
    busy: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    }
  },
  methods: {
    handleDown() {
      if (this.debounce) {
        clearTimeout(this.debounce)
        this.debounce = null
        if (this.recording) {
          return
        }
      }
      if(!this.disabled) {
        this.done = false
        this.showTooltip = false
        this.$emit('startRecording')
      }
    },
    handleUp() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if(!this.disabled && this.recording && !this.done) {
          this.done = true
          this.$emit('stopRecording')
        }
        this.debounce = null
      }, 500)
    }
  },
  mounted() {
    const { clientWidth, clientHeight} = this.$refs.button
    this.$refs.button.addEventListener('touchmove', (evt) => {
      const { layerX, layerY } = evt
      if (this.isMouseDown) {
        this.canMove = (layerX > 0 && layerX < clientWidth) && (layerY > 0 && layerY < clientHeight)
        if (!this.canMove) {
          this.isMouseDown = false
          this.handleUp()
        }
      }
    })
    this.$refs.button.addEventListener('touchstart', () => {
      this.isMouseDown = true
      this.handleDown()
    })
    this.$refs.button.addEventListener('touchend', () => {
      this.isMouseDown = false
      this.handleUp()
    })
  },
  beforeDestroy() {
    this.$refs.button.removeEventListener('touchmove', () => { })
    this.$refs.button.removeEventListener('touchstart', () => { })
    this.$refs.button.removeEventListener('touchend', () => { })
  }
}
</script>