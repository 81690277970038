import { inject, singleton } from "tsyringe";
import GradingApi from "../../apis/GradingApi";
import { AtomData, AtomPhraseOption, MarkingComponents, StarRatingMarkingResponseItem, TapSelectMarkingResponseItem } from "../../models/AtomResponse";
import { GradingItemResponse, GradingData, PatchGradingResponse, PatchGradingPayload } from "../../models/GradingResponse";
import CloudStorage from "../../services/CloudStorage";
import NativeUtils from "../../services/NativeUtils";
import { TemplateType } from "./SpokenUsecase";
import GuidedTaskAtomData from '@es/data/lib/data/guided-task-atom-data'

@singleton()
export default class GradingUsecase {
  constructor (
    @inject('NativeUtils') private nativeUtils: NativeUtils,
    @inject('CloudStorage') private cloudStorage: CloudStorage,
    @inject('GradingApi') private gradingApi: GradingApi
  ) {}

  private _supportV1AtomResponse = true
  private _unspentGradings = 0;
  private _totalRequired = 0;
  private _headerProgress = [0];

  /**
   * Build atom data based on template
   * @param atomResponse
   * @returns 
   */
  private _buildAtomDataByTemplate = (atomResponse: GradingItemResponse): AtomData => {
    const { template_type: template , speaking_text: speakingText, candidate_country: countryCode, unspent_gradings: unspentGradings, total_required: totalRequired  } = atomResponse;
    this._unspentGradings = unspentGradings;
    this._totalRequired = totalRequired;
    // const userId = atomResponse.download_url.split('/')[4];
    const atomData: AtomData = {
      audioUrl: atomResponse.download_url,
      id: atomResponse.grading_id,
      version: '1.0',
      marking: [],
      itemId: atomResponse.item_id,
      template,
      speakingText,
      userId: atomResponse.candidate_id,
      countryCode,
      speakerNumber: unspentGradings + 1
    };

    if (template === TemplateType.READ_ALOUD || template === TemplateType.LISTEN_AND_REPEAT || template === TemplateType.ELICITED_IMITATION || template === TemplateType.INDEPENDENT_VIDEO_DESCRIPTION || template === TemplateType.INDEPENDENT_TASK_COMPLETION || !template) {
      const [ question ] = atomResponse.questions;

      atomData.marking = [{
        component: MarkingComponents.TAP_SELECT,
        title: question.grading_question,
        id: question.grading_question_id,
        words: question.grading_question_options.map(option => ({
          id: option.option_id,
          value: option.value
        }))
      }, {
        id: '60f71ff1-aefb-4107-8c9b-55f49e78ff9c',
        component: MarkingComponents.QUESTION_LIST,
        title: 'This is what they should have said:',
        text: `“${speakingText}”`,
        questions: [{
          id: '979888cb-5b6b-4264-973d-7d20b6abada5',
          question: 'Did they say any extra words?',
          options: [{
            id: '76bc888b-2404-47e2-857c-9e63bfce4297',
            value: 'Yes'
          }, {
            id: 'af71d6fb-6082-4620-9d9b-485ed2e57d34',
            value: 'No'
          }]
        }, {
          id: '0b0b62c6-4e04-4389-ae1e-f955ecb5da68',
          question: 'Did they make any big pauses?',
          options: [{
            id: '2b8b9448-c2a1-4249-8c11-52355f4b906e',
            value: 'Yes'
          }, {
            id: '7e20c4f7-a798-489d-8506-16532977cf8a',
            value: 'No'
          }]
        }, {
          id: 'e65e98f2-f5f2-4163-89f5-9d3dc6d027a7',
          question: 'Did they repeat any words?',
          options: [{
            id: 'bfde5d48-5bab-476e-a761-2fca5f16bbe7',
            value: 'Yes'
          }, {
            id: '3b302f83-b3f2-4260-b1d5-ec292fa420b1',
            value: 'No'
          }]
        }]
      }, {
        id: 'd652f27a-955d-42d1-8ff1-1c8f36cf430d',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'How clear were they?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }];
    } else if (template === TemplateType.GUIDED_TASK_TEXTUAL) {
      const guidedTaskItem = GuidedTaskAtomData.find(item => item.item_id === atomResponse.item_id);
      const [ imageAssets ] = atomResponse.assets?.images ?? [];
      const imageUrl = imageAssets.file_location.replace('_1.svg', '_2.svg');
      atomData.marking = [{
        component: MarkingComponents.QUESTION_TEXT_OR_IMAGE,
        title: guidedTaskItem.marking_question_2,
        id: '5f5d91b8-02f1-4752-bc52-4ccfff8e3104',
        options: [{
          id: 'e989d7e5-f509-4ccd-a460-dbd746ab1318',
          value: 'Yes'
        }, {
          id: 'ad11b830-4151-4ee5-90fa-a304999deb3f',
          value: 'No'
        }]
      }, {
        component: MarkingComponents.QUESTION_TEXT_OR_IMAGE,
        imageUrl,
        id: '0b25b775-2780-400f-a08a-8ece8007b36c',
        options: [{
          id: 'c779d306-5254-45ec-94ab-94172f6feeed',
          value: 'Yes, both'
        }, {
          id: '33d420af-6dec-47b1-a04c-586cb1e99bd2',
          value: 'Yes, but only one'
        }, {
          id: '97cdb7dd-1815-4aba-b2b6-8087c0a9db2a',
          value: 'No'
        }]
      },{
        id: '507cf6c8-6794-4ff1-92ee-9e3cdd34c5cf',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'How clear were they?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }, {
        id: '829ea2f0-f644-4734-898f-1ca0338d48c6',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'Finally. How well did they do?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }];
    } else if (template === TemplateType.GUIDED_TASK_VISUAL) {
      const [ imageAssets ] = atomResponse.assets?.images ?? [];
      const imageUrl = imageAssets.file_location.replace('_1.svg', '_2.svg');
      atomData.marking = [{
        component: MarkingComponents.QUESTION_TEXT_OR_IMAGE,
        imageUrl,
        id: 'bec42b15-e141-4898-a79b-3b6f70065c47',
        options: [{
          id: 'c7b702b9-a606-45ae-b906-cc1a04932848',
          value: 'Yes'
        },{
          id: 'd13d4f25-ed39-4da5-bd9a-dc7d2011fed7',
          value: 'No'
        }]
      }, {
        id: '507cf6c8-6794-4ff1-92ee-9e3cdd34c5cf',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'How clear were they?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }, {
        id: 'addc4c41-1dbb-4d96-980d-cd454aa37d5f',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'Finally. How well did they do?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }];
    }
    return atomData;
  }

  /**
   * Fetch grading atom
   * @returns atom or boolean if not atom available
   */
  async fetchGrading(): Promise<AtomData | boolean> {
    const gradingData = await this.gradingApi.getGrading()
    if ( 'is_grading_complete' in gradingData  && gradingData.is_grading_complete) {
      return false
    }
    return this._buildAtomDataByTemplate(gradingData as GradingItemResponse)
  }

  /***
   * Submit grading responses
   */
  async submitGradingResponses(gradingData: GradingData): Promise<PatchGradingResponse> {
    const patchGradingPayload: PatchGradingPayload = {
      'grader_id': gradingData.grader_id,
      'grading_id': gradingData.grading_id,
      'grading_data': {
        ...gradingData.grading_data
      }
    }
    try {
      if (this._supportV1AtomResponse) {
        const tapSelectItem = gradingData.grading_data.additionalQuestions.find(value => value.component === MarkingComponents.TAP_SELECT) as TapSelectMarkingResponseItem;
        const ratingItem = gradingData.grading_data.additionalQuestions.find(value => value.component === MarkingComponents.STAR_RATING) as StarRatingMarkingResponseItem;
        patchGradingPayload['grading_data'] = {
          ...gradingData.grading_data,
          'easy_to_understand': ratingItem?.starRating ?? 0,
          'question_id': tapSelectItem?.id ?? gradingData.itemId,
          'words_heard': tapSelectItem?.wordsStatus.map((item, index) => ({
            'option_id': item.id,
            sequence: index,
            status: item.status,
            value: item.value
          } as AtomPhraseOption)) ?? [],
          phrases: tapSelectItem?.wordsStatus.map((item, index) => ({
            'option_id': item.id,
            sequence: index,
            status: item.status,
            value: item.value
          } as AtomPhraseOption)) ?? [],
          template: gradingData.template
        }
      }
    } catch (err) {
      console.log('err', err);
    }
    const patchGradingResponse = await this.gradingApi.patchGrading(patchGradingPayload)
    const { unspent_gradings: unspentGradings, total_required: totalRequired  } = patchGradingResponse;
    this._unspentGradings = unspentGradings;
    this._totalRequired = totalRequired;
    return patchGradingResponse
  }

  /**
   * Update header state
   */
  async updateHeaderState() {
    this._headerProgress = [
      Math.round(((this._unspentGradings) / this._totalRequired) * 100)
    ]
    window.dispatchEvent(new CustomEvent('header-progress', {
      detail: {
        headerProgress: this._headerProgress
      }
    }));
  }

  /***
   * Exit grading
   */
  async exit(flag = false) {
    (await this.nativeUtils).exitWebApp(flag);
  }
}