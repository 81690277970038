import * as firebase from 'firebase/app'
import 'firebase/auth'
import { apiClient } from './api'

/***
 * Checks email id valid
 */
const isValid = (input: string) => {
    return input && input.includes(`@${process.env.VUE_APP_GOOGLE_AUTH_DOMAIN}`)
}

/**
 * Firebase Login with Google
 */
const loginWithGoogle = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider()
    // Allow only englishscore domain to auth
    googleProvider.setCustomParameters({
        hd: process.env.VUE_APP_GOOGLE_AUTH_DOMAIN
    })
    googleProvider.addScope('email')
    const response = await firebase.auth().signInWithPopup(googleProvider)
    return isValid(response.user?.email!)
}

const loginWithMicrosoft = async () => {
    const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com')
    microsoftProvider.addScope('User.Read');
    const response = await firebase.auth().signInWithPopup(microsoftProvider)
    return isValid(response.user?.email!)
}

/**
 * Firebase Logout
 */
const logout = async () => {
    await firebase.auth().signOut()
}

const currentUser = async () => {
    return await firebase.auth().currentUser!
}

const token = async () => {
    return await firebase.auth().currentUser?.getIdToken()
}

const esUser = async () => {
    const user = await currentUser()
    const { data } = await apiClient.get('/v1.11/user', {
        params: {
            email: user.email || user.providerData[0]?.email,
        }
    });
    
    return data
}

export {
    loginWithGoogle,
    loginWithMicrosoft,
    currentUser,
    logout,
    token,
    isValid,
    esUser
}