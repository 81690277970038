import CloudStorage from '@es/domain/lib/services/CloudStorage'
import NativeAudioService from '@es/domain/lib/services/NativeAudioService'
import NativeCameraService from '@es/domain/lib/services/NativeCameraService'

import NativeUtils from '@es/domain/lib/services/NativeUtils'
import AndroidNativeUtils from '@es/data/lib/services/platform/android/AndroidNativeUtils'
import IOSNativeUtils from '@es/data/lib/services/platform/ios/IOSNativeUtils'
import WebNativeUtils from '@es/data/lib/services/platform/web/WebNativeUtils'

import AWSCloudStorage from '@es/data/lib/services/AWSCloudStorage'
import WebAudioService from '@es/data/lib/services/platform/web/WebAudioService'
import { Platform } from '@es/data/lib/services/platform'
import AndroidAudioService from '@es/data/lib/services/platform/android/AndroidAudioService'
import IOSAudioService from '@es/data/lib/services/platform/ios/IOSAudioService'

import AndroidCameraService from '@es/data/lib/services/platform/android/AndroidCameraService'
import IOSCameraService from '@es/data/lib/services/platform/ios/IOSCameraService'
import WebCameraService from '@es/data/lib/services/platform/web/WebCameraService'

import ProctoringRepository from '@es/data/lib/repositories/ProctoringRepository'
import ProctoringRepositoryImpl from '@es/data/lib/repositories/ProctoringRepository'

import { container, instanceCachingFactory } from 'tsyringe'
import PlatformProvider from '@es/domain/lib/providers/PlatformProvider'
import KeyValueStorage from '@es/data/lib/localstorage/KeyValueStorage'
import KeyValueStorageImpl from '@es/data/lib/localstorage/KeyValueStorageImpl'
import AssessmentApiImpl from './apis/AssessmentApi'
import AssessmentApi from '@es/domain/lib/apis/AssessmentApi'
import UserInfoProvider from '@es/domain/lib/providers/UserInfoProvider'
import AndroidUserInfoProvider from './providers/platform/android/AndroidUserInfoProvider'
import IOSUserInfoProvider from './providers/platform/ios/IOSUserInfoProvider'
import NetworkProvider from './network/NetworkProvider'
import SpokenInteractor from '@es/domain/lib/interactors/SpokenInteractor'
import SpokenUsecase from '@es/domain/lib/usecases/spoken/SpokenUsecase'
import MarkingUsecase from '@es/domain/lib/usecases/spoken/MarkingUsecase'
import MarkingApi from '@es/domain/lib/apis/MarkingApi'
import MarkingApiImpl from './apis/MarkingApi'

import GradingApi from '@es/domain/lib/apis/GradingApi'
import GradingApiImpl from './apis/GradingApi'
import MarkingInteractor from '@es/domain/lib/interactors/MarkingInteractor'
import GradingInteractor from '@es/domain/lib/interactors/GradingInteractor'


container.register<CloudStorage>('CloudStorage', {
  useClass: AWSCloudStorage
})

container.register<KeyValueStorage>('KeyValueStorage', {
  useClass: KeyValueStorageImpl
})

container.register<NetworkProvider>('NetworkProvider', {
  useClass: NetworkProvider
})

container.register<AssessmentApi>('AssessmentApi', {
  useClass: AssessmentApiImpl
})

container.register<SpokenInteractor>('SpokenInteractor', {
  useClass: SpokenInteractor
})

container.register<SpokenUsecase>('SpokenUsecase', {
  useClass: SpokenUsecase
})

container.register<MarkingApi>('MarkingApi', {
  useClass: MarkingApiImpl
})

container.register<MarkingInteractor>('MarkingInteractor', {
  useClass: MarkingInteractor
})

container.register<GradingApi>('GradingApi', {
  useClass: GradingApiImpl
})

container.register<GradingInteractor>('GradingInteractor', {
  useClass: GradingInteractor
})

container.register<MarkingUsecase>('MarkingUsecase', {
  useClass: MarkingUsecase
})

container.register<ProctoringRepository>('ProctoringRepository', {
  useClass: ProctoringRepositoryImpl
})


container.register('NativeAudioService', {
  useFactory: instanceCachingFactory<Promise<NativeAudioService>>(async (c) => {
    const platformProvider = await c.resolve<PlatformProvider>(PlatformProvider);
    const platform = await platformProvider.getPlatform()
    switch(platform) {
      case Platform.ANDROID:
        return new AndroidAudioService()
      case Platform.IOS:
        return new IOSAudioService()
      case Platform.WEB:
      default:
        return new WebAudioService()
    }
  })
})

container.register('NativeCameraService', {
  useFactory: instanceCachingFactory<Promise<NativeCameraService>>(async (c) => {
    const platformProvider = await c.resolve<PlatformProvider>(PlatformProvider);
    const platform = await platformProvider.getPlatform()
    switch(platform) {
      case Platform.ANDROID:
        return new AndroidCameraService()
      case Platform.IOS:
        return new IOSCameraService()
      case Platform.WEB:
      default:
        return new WebCameraService()
    }
  })
})

container.register('NativeUtils', {
  useFactory: instanceCachingFactory<Promise<NativeUtils>>(async (c) => {
    const platformProvider = await c.resolve<PlatformProvider>(PlatformProvider);
    const platform = await platformProvider.getPlatform();
    switch(platform) {
      case Platform.ANDROID:
        return new AndroidNativeUtils()
      case Platform.IOS:
        return new IOSNativeUtils()
      case Platform.WEB:
      default:
        return new WebNativeUtils()
    }
  })
})

const registerWebUserInfo = (webUserInfoProvider: UserInfoProvider) => {
  container.register('UserInfoProvider', {
    useFactory: instanceCachingFactory<Promise<UserInfoProvider>>(async (c) => {
      const platformProvider = await c.resolve<PlatformProvider>(PlatformProvider);
      const platform = await platformProvider.getPlatform()
      switch(platform) {
        case Platform.ANDROID:
          return new AndroidUserInfoProvider()
        case Platform.IOS:
          return new IOSUserInfoProvider()
        case Platform.WEB:
        default:
          return webUserInfoProvider
      }
    })
  })
}

export {
  container,
  registerWebUserInfo
}