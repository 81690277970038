<template>
  <button class="es-media-button" type="button" v-es-on:click="($evt) => $emit('click', $evt)">
    <img :src="imageIcon" />
  </button>
</template>
<script>
import playIcon from './../../assets/images/btns-playback-play.svg'
import replayIcon from './../../assets/images/replay-icon-3.svg'
import pauseIcon from './../../assets/images/btns-playback-pause.svg'

export default {
  name: 'es-media-button',
  props: {
    state: {
      type: String,
      required: true,
      default: 'play'
    }
  },
  computed: {
    imageIcon() {
      switch(this.state) {
        case 'pause':
          return pauseIcon
        case 're-play':
          return replayIcon
        case 'play':
        default:
          return playIcon
      }
    }
  }
}
</script>