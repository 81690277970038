import { ActionTree, GetterTree, MutationTree } from "vuex";
import {apiClient} from "@/services/api";

const mailchimpProxyServerUrl = process.env.VUE_APP_MAILCHIMP_PROXY_SERVER_URL;
/**
 * State interface.
 */
interface FluentifyState {
  list: unknown[];
  error?: Error | string | string[] | null;
  busy: boolean;
  pagination: {
    count: number;
    offset: number;
  },
  details?: any;
}

/**
 * State is used to store data.
 */
const state: FluentifyState = {
  list: [],
  error: null,
  busy: false,
  pagination: {
    count: 1000,
    offset: 0
  },
  details: null
}

/**
 * Enum for mutations.
 */
enum FluentifyMutations {
  SET_LIST = 'SET_LIST',
  SET_ERROR = 'SET_ERROR',
  SET_BUSY = 'SET_BUSY',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_DETAILS = 'SET_DETAILS'
}

/**
 * Mutations are used to update the state.
 */
const mutations: MutationTree<FluentifyState> = {
  [FluentifyMutations.SET_LIST](state, list: unknown[]) {
    state.list = list;
  },
  [FluentifyMutations.SET_ERROR](state, error: Error | string | string[] | null) {
    state.error = error;
  },
  [FluentifyMutations.SET_BUSY](state, busy: boolean) {
    state.busy = busy;
  },
  [FluentifyMutations.SET_PAGINATION](state, pagination: {count: number, offset: number}) {
    state.pagination = pagination;
  },
  [FluentifyMutations.SET_DETAILS](state, details: unknown) {
    state.details = details;
  }
}

/**
 * Actions are used to make asynchronous calls to the server.
 */
const actions: ActionTree<FluentifyState, unknown> = {
  fetchBatchList: async ({ commit }) => {
    try {
      commit(FluentifyMutations.SET_BUSY, true);
      const {data} = await apiClient.get(`/api/v0.1/mailchimp/batches?count=${state.pagination.count}&offset=${state.pagination.offset}`, {
        baseURL: mailchimpProxyServerUrl
      });
      commit(FluentifyMutations.SET_LIST, data.batches);
    } catch (err) {
      commit(FluentifyMutations.SET_ERROR, err);
    } finally {
      commit(FluentifyMutations.SET_BUSY, false);
    }
  },
  fetchBatchDetails: async ({ commit }, batchId: string) => {
    try {
      commit(FluentifyMutations.SET_BUSY, true);
      const {data} = await apiClient.get(`/api/v0.1/mailchimp/batches/${batchId}`, {
        baseURL: mailchimpProxyServerUrl
      });
      commit(FluentifyMutations.SET_DETAILS, data);
    } catch (err) {
      commit(FluentifyMutations.SET_ERROR, err);
    } finally {
      commit(FluentifyMutations.SET_BUSY, false);
    }
  },
  clearBatchDetails: async ({ commit }) => {
    commit(FluentifyMutations.SET_DETAILS, null);
  }
}

/**
 * Getters are used to retrieve data from the store.
 */
const getters: GetterTree<FluentifyState, unknown> = {
  batchItems: (state) => state.list.sort((itemA: any, itemB: any) => Date.parse(itemB.submitted_at) - Date.parse(itemA.submitted_at)),
  batchDetails: (state) => state.details,
  batchReport: (state) => {
    if (!state.details) return [];
    let report = [];
    let i = 0;
    for (let batchResponse of state.details.batchResponse) {
      report = batchResponse.reduce((acc, item, index) => {
        const response = JSON.parse(item.response);
        const key = item.status_code === 200 ? 'success' : response.title.toLowerCase().replaceAll(' ', '_');
        const accItemIndex = acc.findIndex((item: any) => item.key === key);
        if (accItemIndex === -1) {
          acc.push({
            key,
            title: item.status_code === 200 ? 'Success' : response.title,
            value: [`${i}_${index}`]
          })
        } else {
          acc[accItemIndex].value.push(`${i}_${index}`);
        }
        return acc;
      }, report)
    }
    return report
  },
  isBusy: (state) => state.busy,
  headers: () => ([{
    text: 'ID',
    value: 'id',
  }, {
    text: 'Status',
    value: 'status'
  }, {
    text: 'Operation Report',
    value: 'operation_report'
  }, {
    text: 'Submitted At',
    value: 'submitted_at'
  },{
    text: 'Completed At',
    value: 'completed_at'
  }, {
    text: 'Actions',
    value: 'actions'
  }]),
  reportHeader: () => ([{
    text: 'Title',
    value: 'title'
  }, {
    text: 'Count',
    value: 'count'
  }, {
    text: 'Actions',
    value: 'actions'
  }])
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}