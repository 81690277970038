<template>
  <div class="es-audio-player" :class="{ 'only-player': onlyPlayer}">
    <div class="audio-wrapper">
      <div v-if="!onlyPlayer" class="audio-ui">
        <div v-if="pristine && !error">
          <p>Listen</p>
        </div>
        <div v-if="error">
          <p class="error">{{ error.message }}</p>
        </div>
        <div v-if="!pristine && !error">
          <div>
            <div class="progress">
              <div ref="currentProgress" class="current-progess"></div>
            </div>
          </div>
          <span>{{ lapseDuration }}</span>
        </div>
      </div>
      <div v-if="!error" class="audio-control">
        <es-media-button
          @click="handleMedia"
          :state="mediaState" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'es-audio-player',
  props: {
    audioLink: {
      type: String,
      required: true
    },
    onlyPlayer: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    audio: null,
    mediaState: 'play',
    isPlaying: false,
    isPause: false,
    lapseDuration: 0,
    showDuration: false,
    pristine: true,
    error: null
  }),
  methods: {
    async handleMedia() {
      try {
        if (!this.isPlaying) {
          await this.audio.play()
        } else if (this.isPlaying && !this.pause) {
          await this.audio.pause()
        }
      } catch(err) {
        this.error = new Error('Can not play at the moment.')
        setTimeout(() => {
          this.error = null
        }, 5000)
      }
    },
    getTime() {
      if (!this.audio || !this.showDuration) {
        return '00:00'
      }
      if (this.$refs.currentProgress) {
        // update progress
        const progress = `${(this.audio.currentTime/this.audio.duration) * 100}%`
        this.$refs.currentProgress.style.width = progress
      }
      const timeLeft = this.audio.duration - this.audio.currentTime
      const minutes = Number(Math.floor(timeLeft / 60)).toString().padStart(2, '0')
      const seconds = Number(Math.floor(timeLeft % 60)).toString().padStart(2, '0')
      return `${minutes}:${seconds}`
    },
    stop() {
      if (this.isPlaying) {
        this.audio.load()
        this.audio.pause()
      }
    },
    getMIME() {
      if (this.audioLink.includes('.webm')) {
        return 'audio/webm'
      } else if (this.audioLink.includes('.mp4')) {
        return 'audio/mp4'
      } else if (this.audioLink.includes('.mp3')) {
        return 'audio/mpeg'
      }
      return ''
    }
  },
  mounted() {
    this.audio = new Audio();
    const source = document.createElement('source');

    source.setAttribute('src', this.audioLink);
    // source.setAttribute('type', 'audio/m4a');
    this.audio.append(source);

    this.audio.autoplay = false
    this.audio.loop = false

    const onError = () => {
      this.error = new Error('Audio is not playable.')
      setTimeout(() => {
        this.error = null
      }, 5000)
      this.$emit('onPlaying')
    }

    source.addEventListener('error',onError);
    // source.addEventListener('abort', (evt) => {
    //   console.log('evt.target.error.code', evt.target.error)
    // })
    // source.addEventListener('emptied', (evt) => {
    //   console.log('evt.target.error.code', evt.target.error)
    // })
    // source.addEventListener('stalled', (evt) => {
    //   console.log('evt.target.error.code', evt.target.error)
    // })
    // source.addEventListener('suspend', (evt) => {
    //   console.log('evt.target.error.code', evt.target.error)
    // })
    

    this.audio.addEventListener('durationchange', () => {
      this.showDuration = true
      this.lapseDuration = this.getTime()
    })

    this.audio.addEventListener('loadedmetadata', () => {
      this.lapseDuration = this.getTime()
    })

    this.audio.addEventListener('timeupdate', () => {
      this.lapseDuration = this.getTime()
    })

    this.audio.addEventListener('playing', () => {
      this.pristine = false
      this.isPlaying = true
      this.isPause = false
      this.mediaState = 'pause'
      this.$emit('onPlaying')
    })

    this.audio.addEventListener('pause', () => {
      this.isPlaying = false
      this.isPause = true
      this.mediaState = 'play'
    })

    this.audio.addEventListener('ended', () => {
      this.audio.load() 
      this.isPlaying = false
      this.isPause = false
      this.mediaState = 're-play'
      this.$emit('onPlayed')
    })
  },
  beforeDestroy() {
    this.audio.removeEventListener('durationchange', () => {})
    this.audio.removeEventListener('loadedmetadata', () => {})
    this.audio.removeEventListener('timeupdate', () => {})
    this.audio.removeEventListener('playing', () => {})
    this.audio.removeEventListener('pause', () => {})
    this.audio.removeEventListener('ended', () => {})
    if (this.isPlaying) {
      this.audio.pause()
    }
    this.audio = null
  }
}
</script>