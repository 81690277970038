import { inject, injectable } from "tsyringe";
import GradingApi from "@es/domain/lib/apis/GradingApi";
import UserInfoProvider from "@es/domain/lib/providers/UserInfoProvider";
import NetworkProvider from "../network/NetworkProvider";
import { GradingResponse, PatchGradingPayload, PatchGradingResponse } from "@es/domain/lib/models/GradingResponse";

@injectable()
export default class GradingApiImpl implements GradingApi {
  constructor (
    @inject('UserInfoProvider') private userInfoProvider: UserInfoProvider,
    @inject('NetworkProvider') private networkProvider: NetworkProvider
  ) { }
  
  async getGrading(): Promise<GradingResponse> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken, userId } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).get(`/v1.12/exam/gradings?grader_id=${userId}`)
      return data;
    } catch(err) {
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }

  async patchGrading(response: PatchGradingPayload): Promise<PatchGradingResponse> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken, userId } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).patch('/v1.12/exam/gradings', {
        ...response,
        'grader_id': userId
      })
      return data
    } catch(err) {
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }
}