<template>
  <div class="es-video-player-v2" :class="{ 'disabled': (disabled || busy), 'help': showHelp, 'as-marking': marking }">
    <div class="es-video-title-area">
      <p v-if="title" class="title">{{ title }}</p>
      <p v-if="showPlayCountLeft" class="play-count">{{ playsLeft }}</p>
    </div>
    <div class="video-wrapper">
        <video :src="src" preload="auto" ref="esPlayer" webkit-playsinline="true" playsinline="true" style="display:none;" />
        <div v-if="showOverlay" class="overlay" :class="{ 'hide' : isPlaying, 'low-opacity': lowOpacity }">
          <p v-if="!noOverlayText">{{ overlayText }}</p>
        </div>
        <img v-es-on:click="play" v-if="!isPlaying && playedCount === 0" class="icon" src="./../../assets/images/btn-play.svg" alt="Play">
        <img v-es-on:click="play" v-if="!isPlaying && playedCount > 0" class="icon" src="./../../assets/images/btn-replay.svg" alt="Replay">
        <es-tooltip
          v-if="showTooltip"
          color="purple-blue" 
          tooltip-position="bottom">
          <p>Press play to start</p>
        </es-tooltip>
      </div>
  </div>
</template>
<script>
export default {
  name: 'es-video-player-v2',
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    maxPlayCount: {
      type: Number,
      required: false,
      default: () => 2
    },
    showPlayCountLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    busy: {
      type: Boolean,
      required: false,
      default: false
    },
    showHelp: {
      type: Boolean,
      required: false,
      default: () => true
    },
    marking: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showOverlay: {
      type: Boolean,
      required: false,
      default: () => false
    },
    noOverlayText: {
      type: Boolean,
      required: false,
      default: () => false
    },
    canPlayInfinite: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    playedCount: 0,
    isPlaying: false,
    disabled: false,
    showTooltip: false,
    timeout: null,
    overlayText: 'Listen to the speaker then answer their question',
    lowOpacity: false
  }),
  computed: {
    playsLeft() {
      const leftCount = this.maxPlayCount - this.playedCount
      if (leftCount === 0) {
        return '0 plays left'
      }
      return `${leftCount} play${leftCount>=2?'s':''} left`
    },
    playLeft() {
      return this.canPlayInfinite ? 1 : this.maxPlayCount - this.playedCount
    }
  },
  methods: {
    play() {
      if (!this.disabled && this.playLeft > 0) {
        clearTimeout(this.timeout)
        if (this.$refs.esPlayer.muted) {
          this.$refs.esPlayer.muted = false
        }
        this.showTooltip = false
        // this.$refs.esPlayer.currentTime = 0
        this.$refs.esPlayer.play()
      }
    },
    mute() {
      this.$refs.esPlayer.muted = true
    }
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.showTooltip = true
    }, 10000)
    const { offsetWidth } = this.$refs.esPlayer.parentNode;
    const videoElementHeight = `${offsetWidth * 0.5625}px`;
    this.$refs.esPlayer.parentNode.style.height = videoElementHeight;
    this.$refs.esPlayer.style.height = videoElementHeight;
    this.$refs.esPlayer.style.display = 'initial';

    this.$refs.esPlayer.addEventListener('playing', () => {
      if (this.playLeft === 0) {
        this.$refs.esPlayer.currentTime = this.$refs.esPlayer.duration
        this.$refs.esPlayer.pause()
      } else {
        this.$emit('playing')
        this.isPlaying = true
      }
    })
    this.$refs.esPlayer.addEventListener('pause', () => {
      // Fixes: Even though there is no PAUSE control, it's possible to pause playback from the Apple Watch and also from the Control Panel.  Make sure that the "plays remaining" is only decremented when the audio completes, and not when it is paused.  Another solution is to ignore all pause signals.
      // If user pause via control, checks remaing time if it's not equal to 0, it continues playing it.
      if (Math.abs(this.$refs.esPlayer.duration - this.$refs.esPlayer.currentTime) !== 0) {
        this.$refs.esPlayer.play()
      }
    })
    this.$refs.esPlayer.addEventListener('ended', () => {
      if (this.playLeft > 0) {
        this.overlayText = 'Now answer the question'
        this.lowOpacity = true
        this.$emit('played')
        this.isPlaying = false
        this.playedCount = this.playLeft > 1 ? this.playedCount + 1 : this.maxPlayCount
        if (this.showPlayCountLeft && this.playedCount === 2) {
          this.disabled = true
        }
      }
    })
  }
}
</script>