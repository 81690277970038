import { DateTime } from "luxon";

export default function dateString(value) {
  if (!value) return "";

  /**
   * Date format should be "YYYY-mmm-dd HH:ii:ss TZ" 
   * (TZ: The timezone from the client computer.)
   * Eg: "2021-Dec-20 14:05:23 GMT"
   * @see: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   */
  let unformattedDate;
  if (typeof value === 'number') {
    unformattedDate = DateTime.fromMillis(value/1000);
  }
  else if (typeof value === 'object') {
    unformattedDate = DateTime.fromJSDate(value)
  }
  else {
    unformattedDate = DateTime.fromISO(value);
  }

  if (!unformattedDate.isValid) return "";

  const formattedDate = unformattedDate.toFormat('yyyy-LLL-dd TTT');
  return formattedDate;
}
