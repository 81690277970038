import { required, email, max, min, numeric, image, ext, min_value, max_value, alpha_num, alpha_dash } from 'vee-validate/dist/rules';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import ValidateUUID from 'uuid-validate'

export default {
  install: (Vue) => {
    Vue.mixin({
      components: {
        ValidationProvider,
        ValidationObserver
      }
    })
    // No message specified.
    extend('email', email);
    extend('min', min);
    extend('max', max);
    extend('numeric', numeric);
    extend('image', image);
    extend('ext', ext);
    extend('min_value', min_value);
    extend('max_value', max_value);
    extend('alpha_num', alpha_num);
    extend('alpha_dash', alpha_dash);

    // Used for endorsement image
    extend('png', {
      validate(value) {
        const file = Array.isArray(value) ? value[0] : value;
        return file && file.type === 'image/png';
      },
      message: 'Only PNG images are allowed'
    });

    // Validate File Size: Between 50KB and 250KB
    extend('size_limit', {
      validate(value) {
        const file = Array.isArray(value) ? value[0] : value;
        const sizeKB = file.size / 1024;
        return sizeKB >= 50 && sizeKB <= 250;
      },
      message: 'The image size should be between 50KB and 250KB'
    });

    // Validate Image Ratio: A4
    extend('a4_ratio', {
      async validate(value) {
        return new Promise((resolve) => {
          const file = Array.isArray(value) ? value[0] : value; // check if value is an array
          const reader = new FileReader();
          reader.onload = function(event) {
            const img = new Image();
            img.src = event.target!.result as string;
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              const ratio = height / width;
              // The A4 paper aspect ratio is 1.414 (approximately)
              const isValid = Math.abs(ratio - 1.414) < 0.05;
              resolve(isValid);
            };
          };
          reader.readAsDataURL(file);
        });
      },
      message: 'The image ratio should be A4'
    });       

    // Override the default message.
    extend('required', {
      ...required,
      message: 'This field is required'
    });

    extend('numeric', {
      ...numeric,
      message: 'Must be a number'
    });

    extend('uuid', {
      message: 'Invalid ID',
      validate: (value) => {
        return ValidateUUID(value, 4)
      }
    });

    extend('no-all-zeros', {
      message: 'Invalid Phone (All Zeros)',
      validate: (value) => {
        if (value && (value.match(/0/g) || []).length === value.length) {
          return false
        }
        return true
      }
    });

    extend('gapfill', {
      message: 'Must include gapfill text',
      validate: (value) => {
        if (value.includes('[')) {
          if(value.includes(']'))
            return true
        }
        return false
      }
    });

    extend('date', {
      message: 'Invalid Date',
      validate: (value) => {
        return value instanceof Date;
      }
    });

    /**
     * Custom validation rule for code.
     */
    extend('code_validation', (code: undefined | string, obj) => {
      // Check if code is defined.
      if (code === undefined) return 'Code is required'

      // Check if code is in uppercase.
      if (code && /[a-z]/.test(code)) return 'Alphabets must be in uppercase';

      const [items, shortCodes] = obj as [Array<{code: string}>, string[]];
      // Check if items and shortCodes are defined.
      if (items === undefined) return 'Items not defined'
      if (shortCodes === undefined) return 'Short codes not defined'

      // Check min and max length.
      const min = shortCodes.includes(code.trim()) ? 6 : 2
      if (code.trim().length < min) return `Min length is ${min}`
      if (code.trim().length > 20) return `Max length is 20`
      
      // Check if code is already in use.
      const itemWithSameCode = items.find(item => item.code.toLowerCase() === code.trim().toLowerCase())
      if (itemWithSameCode) return 'Code already in use'
      
      // All checks passed.
      return true
    })
  }
}