import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default {
  install(Vue, router) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['englishscore.com', /^\//],
        }),
      ],

      // Note: A tracesSampleRate of 1.0 wil capture 100% of transactions for performance monitoring.
      tracesSampleRate: 0.1,
      logErrors: true,
      enabled: process.env.NODE_ENV === 'production',
      environment: process.env.NODE_ENV || 'unknown',
    })
  }
}