<template>
    <button class="es-btn" :class="[color, { 'full': full } ]" v-es-on:click="($evt) => $emit('click')" :disabled="disabled">
        <slot>Default Button</slot>
    </button>
</template>
<script>
export default {
    name: 'es-tap-btn',
    props: ['color', 'disabled', 'full']
}
</script>