import { apiClient } from '@/services/api';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface DeleteUserPayload {
  userID: string;
  emailAddress: string;
  reason: string | null;
}

export interface UserState {
  busy: boolean;
  error: Error | null;
  payload: DeleteUserPayload;
  message: string | null;
}

const state: UserState = {
  busy: false,
  error: null,
  payload: {
    userID: '',
    emailAddress: '',
    reason: null
  },
  message: null
}

const mutations: MutationTree<UserState> = {
  UPDATE_BUSY: (state, busy: boolean) => {
    state.busy = busy
  },
  UPDATE_ERROR: (state, error: Error | null) => {
    state.error = error
  },
  UPDATE_PAYLOAD: (state, payload: DeleteUserPayload) => {
    state.payload = payload
  },
  UPDATE_MESSAGE: (state, message: string | null) => {
    state.message = message
  }
}

const actions: ActionTree<UserState, unknown> = {
  deleteUser: async ({ state, commit }) => {
    try {
      commit('UPDATE_BUSY', true);
      commit('UPDATE_ERROR', null);
      commit('UPDATE_MESSAGE', null);
      await apiClient.delete('/v1.11/user', {
        data: {
          user_id: state.payload.userID,
          email_address: state.payload.emailAddress,
          reason: state.payload.reason
        }
      });
      commit('UPDATE_PAYLOAD', {
        userID: '',
        emailAddress: '',
        reason: null
      });
      commit('UPDATE_MESSAGE', 'Successfully deleted user');
      setTimeout(() => {
        commit('UPDATE_MESSAGE', null);
      }, 5000);
      return true
    } catch (err) {
      if (err.response) {
        const { status, data } = err.response
        switch(status) {
          case 400:
          case 404:
          case 401:
            commit('UPDATE_ERROR', new Error(data.message));
            break;
          case 500:
          default:
            commit('UPDATE_ERROR', new Error('Something went wrong, Please try again.'));
        }
      } else {
        commit('UPDATE_ERROR', err); 
      }
      setTimeout(() => {
        commit('UPDATE_ERROR', null);
      }, 5000);
    } finally {
      commit('UPDATE_BUSY', false);
    }
  }
}

const getters: GetterTree<UserState, unknown> = {
  isBusy: state => state.busy,
  message: state => state.message,
  error: state => state.error,
  payload: state => state.payload
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}