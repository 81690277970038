export interface CameraPreview {
  height?: number;
  width?: number;
  cameraCheck: boolean;
  enable: boolean;
}

export enum CameraImageRatio {
  RATIO_3_4 = "3:4",
  RATIO_1_1 = "1:1"
}
interface CameraCallback<T> {
  (err: any, result?: undefined | null): void;
  (err: undefined | null, result: T): void;
}

export interface CameraCapture {
  format: 'png' | 'jpg';
  ratio: CameraImageRatio;
  onImageReady: (err?: Error, cameraImage?: CameraImage) => void;
  proctoringIndex: number;
}

export interface CameraImage {
  proctoringIndex: number;
  image: string | Buffer;
  imageType: 'image/png' | 'image/jpg';
}