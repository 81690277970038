<template>
  <div>
    <template v-if="showMarking">
      <es-mark-words
        v-if="!tapSelect"
        :disabled="disabled"
        v-model="markingResponse"
        :title="markWordData.title"
        :words="markWordData.words" />
      <es-select-words
        v-if="tapSelect"
        :disabled="disabled"
        v-model="markingResponse"
        title="Tap all the words that you hear"
        :words="markWordData.words" />
    </template>
    <template v-if="showRating">
      <es-rating
        v-model="ratingResponse"
        :is-l-r="true"
        title="How well did they do?" />
    </template>
    <template v-if="showQuestions">
      <es-questions
        :questions="atom.atom.rating_questions"
        v-model="questionResponse"
        title="This is what they should have said:"
        :text="`“${atom.atom.speaking_text}”`" />
    </template>
    <div class="bottom-actions" v-show="!busy">
      <es-tap-btn @click="next" :disabled="!techIssue && ((showMarking && !markingResponse) || (showRating && !ratingResponse) || (showQuestions && !questionResponse) || busy)" color="indigo">Next</es-tap-btn>
      <!-- <es-tap-btn color="indigo" @click="hasIssue" class="help">
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="28" viewBox="0 0 6 28">
          <g fill="none" fill-rule="evenodd">
            <g fill="#FFF">
                <g>
                    <g transform="translate(-365 -794) translate(0 772) translate(365 22)">
                        <rect width="5" height="5" x=".5" y="22.5" rx="2.5"/>
                        <rect width="5" height="18" x=".5" y=".5" rx="2.5"/>
                    </g>
                </g>
            </g>
        </g>
      </svg>
      </es-tap-btn> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReadAloud',
  props: {
    atom: {
      type: Object,
      required: true
    },
    busy: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    techIssue: {
      type: Boolean,
      default: false,
      required: false
    },
    tapSelect: {
      type: Boolean,
      default: false,
      required: false
    },
    isStatic: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    markingResponse: null,
    ratingResponse: null,
    questionResponse: null,
    showMarking: true,
    showRating: false,
    showQuestions: false
  }),
  computed: {
    markWordData() {
      const [ question ] = this.atom.atom.atom_questions
      return {
        id: question.atom_question_id,
        words: this.markingResponse ? (this.markingResponse.phrases || this.markingResponse.words_heard) : question.atom_question_options,
        title: 'Tap any words that are missing, or pronounced incorrectly'
      }
    },
  },
  methods: {
    next() {
      if (this.showMarking) {
        if (this.markingResponse.all_words_missing) {
          if (this.tapSelect) {
            this.markingResponse.phrases = this.markingResponse.words_heard
            delete this.markingResponse['words_heard']
          }
          this.$emit('marked', {
            template: 'READ_ALOUD',
            'question_id': this.markWordData.id,
            ...this.markingResponse,
            'easy_to_understand': 0
          })
        } else if(this.isStatic) {
          this.showMarking = false
          this.showQuestions = true
        } else {
          this.showMarking = false
          this.showRating = true
        }
      } else if (this.showRating) {
        this.showMarking = false
        this.showRating = false

        if (this.tapSelect) {
          this.markingResponse.phrases = this.markingResponse.words_heard
          delete this.markingResponse['words_heard']
        }
        this.$emit('marked', {
          template: 'READ_ALOUD',
          'question_id': this.markWordData.id,
          ...this.markingResponse,
          ...this.ratingResponse
        })
      } else if (this.showQuestions) {
        this.showMarking = false
        this.showQuestions = false

        if (this.tapSelect) {
          this.markingResponse.phrases = this.markingResponse.words_heard
          delete this.markingResponse['words_heard']
        }
        this.$emit('marked', {
          template: 'READ_ALOUD',
          'question_id': this.markWordData.id,
          ...this.markingResponse,
          ...this.questionResponse
        })
      }
    },
    hasIssue() {
      this.$emit('hasIssue', {
          template: 'READ_ALOUD',
          'question_id': this.markWordData.id,
            'all_words_heard': false,
            'all_words_not_heard': false,
            'all_words_missing': true,
            phrases: this.markWordData.words.map(word => ({
              ...word,
              status: this.tapSelect ? 'incorrect' : 'missing'
            })),
            'easy_to_understand': 0
        })
    }
  },
  watch: {
    value(data) {
      if (data) {
        const words = this.markWordData.words.map(word => {
          const markedWord = data.words.find(elsaWord => elsaWord.sequence == word.sequence)
          if (markedWord) {
            word.status = markedWord.status
          } else {
            word.status = 'missing'
          }
          return word
        })
        this.markingResponse = {}
        this.markingResponse[ this.tapSelect ? 'words_heard' : 'phrases' ] = words
        this.ratingResponse = {
          'easy_to_understand': data.rating
        }
      }
    }
  }
}
</script>