import { apiClient } from '@/services/api'
import dateString from '@/plugins/filters/date-string'

// Generate a human-friendly label e.g. from "DND_READING" to "Dnd Reading"
const humanize = (string: string) => string && string
  .trim()
  .toLowerCase()
  .split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ')

interface Item {
  item_id: string;
  updated: string;
  title: string;
  bucket: string;
  cefr_level: string;
  skill: string;
  status: string;
  template: string;
  template_type: string;
  domain: string;
  language_function: string;
}
const fetchItems = async ({ dispatch, state }) => {
  state.busy = true

  try {
    const response = await apiClient.get(`/v1.11/item?status=${state.currentStatusFilter.toLowerCase()}`)
    const { data: { results } } = response
    const items = Object.values(results[0]).flat() as Item[]
    state.rawItems = items
    const unfilteredItems = items.map((item) => {
      return {
        item_id: item.item_id,
        updated: item.updated,
        formatted_updated: dateString(item.updated),
        title: item.title,
        bucket: item.bucket,
        cefr_level: item.cefr_level,
        skill: humanize(item.skill === 'spoken' ? 'speaking' : item.skill),
        status: humanize(item.status),
        template: humanize(item.template_type),
        template_type: item.template_type,
        domain: humanize(item.domain),
        languageFunction: item.language_function,
      }
    })

    // Give the unfiltered items to the store, then apply any existing filters
    state.unfilteredItems = unfilteredItems
    dispatch('filterItems')
  }
  catch (error) {
    console.error(error)
    state.items = state.unfilteredItems = []
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Could not load items.</strong>`,
    }, { root: true })
  }
  state.busy = false
}

const filterItems = async ({ state, dispatch }) => {
  const { filters } = state

  // If the Status has changed, fetch new items
  // @note: in future we'll have a faster API endpoint so won't need to chunk data on status
  const { selectedIndex, items } = filters.find(({ value}) => value === 'status')
  const newStatusFilter = items[selectedIndex].text
  if(state.currentStatusFilter !== newStatusFilter) {
    state.currentStatusFilter = newStatusFilter
    await fetchItems({ state, dispatch })
  }

  // Apply all the other filters without fetching new items

  // For each row in items, check if each property matches the corresponding filter value
  const filteredItems = state.unfilteredItems.filter(item => {
    const isCool = filters.every(({ value: filterBy, selectedIndex, items }) => {
      if (filterBy === 'status') return true

      const filterText = items[selectedIndex].text
      if (filterText === 'All') return true

      const itemValue = item[filterBy]
      return filterText.toLowerCase() === itemValue.toLowerCase()
    })
    return isCool
  })

  state.items = filteredItems
}

const headers = [
  { text: 'Updated ', value: 'updated'},
  { text: 'Status', value: 'status' },
  { text: 'Skill', value: 'skill' },
  { text: 'CEFR', value: 'cefr_level' },
  { text: 'Bucket', value: 'bucket' },
  { text: 'Domain', value: 'domain' },
  { text: 'Title', value: 'title' },
  { text: 'Template', value: 'template' },
  { text: '', value: 'item_id', align: ' d-none'},
  { text: '', value: 'actions', align: 'right', sortable: false },
]

// @todo: Generate these filters from the items data?
const filters = [
  {
    label: 'Status',
    value: 'status',
    items: [
      { text: 'Draft' },
      { text: 'Live' },
      { text: 'Deactivated' },
      { text: 'Discarded' },
    ],
    selectedIndex: 0,
  },
  {
    label: 'Skill',
    value: 'skill',
    items: [
      { text: 'All' },
      { text: 'Grammar' },
      { text: 'Vocabulary' },
      { text: 'Reading' },
      { text: 'Listening' },
      { text: 'Speaking' },
      { text: 'Writing' },
    ],
    selectedIndex: 0,
  },
  {
    label: 'CEFR Level',
    value: 'cefr_level',
    items: [
      { text: 'All' },
      { text: 'A1' },
      { text: 'A2' },
      { text: 'B1' },
      { text: 'B2' },
      { text: 'C1' },
      { text: 'C2' },
    ],
    selectedIndex: 0,
  },
  {
    label: 'Template',
    value: 'template',
    items: [
      'all',
      'MCQ_GAPFILL_PARAGRAPH',
      'MCQ_GAPFILL_TITLE',
      'MCQ_GAPFILL_CHAT',
      'MCQ_LISTENING',
      'MCQ_TRIPLE_LONG_READING',
      'DND_READING',
      'READ_ALOUD',
      'LISTEN_AND_REPEAT',
      'GUIDED_TASK_TEXTUAL',
      'GUIDED_TASK_VISUAL',
      'DICTATION',
      'DESCRIBE_THE_IMAGE',
      'ANSWER_THE_QUESTION',
      'HAVE_A_CHAT',
    ].map(row => ({
      text: humanize(row),
      value: row,
    })),
    selectedIndex: 0,
  },
]

export default {
  namespaced: true,
  state: {
    busy: true,
    headers,
    items: [],
    rawItems: [],
    unfilteredItems: [],
    filters,
    currentStatusFilter: 'Draft',
  },
  actions: {
    fetchItems,
    filterItems,
  },
  mutations: {
    setFilters(state, filters) { state.filters = filters }
  },
}
