import { apiClient } from '@/services/api'

// Generate a human-friendly label e.g. from "DND_READING" to "Dnd Reading"
const humanize = (string: string) => string && string
  .trim()
  .toLowerCase()
  .split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ')

const loadSitting = async ({ dispatch, state, rootState }, sittingId) => {
  rootState.gradingList.busy = true
  state.showGradingDialog = false
  const params = {
    sitting_id: sittingId,
    user_id: rootState.account.user.user_id,
    application: 'CMS',
    include_item_responses: true,
  }
  try {
    const response = await apiClient.get(`/v1.13/exam/sittings`, { params })
    const { data: { results } } = response
    const item = results[0]

    state.sitting = {
      sitting_id: item.sitting_id,
      user_id: item.user_id,
      assessment_instance_id: item.assessment_instance_id,
      assessment_type_id: item.assessment_type_id,
      assessment_type_name: humanize(item.assessment_type_name),
      status: humanize(item.status),
      grading_status: humanize(item.grading_status),
      started_time: item.started_time,
      assessment_skills: item.assessment_skills?.map(humanize)
        .map(s => s === 'Core V1' ? 'Core' : s)
        .map(s => s === 'Spoken' ? 'Speaking' : s)
        .join(', '),
      score: item.score.englishscore ?? '-',
      cefr: item.score.cefr ?? '-',
    }

    if (!item.item_responses || !item.item_responses.length) {
      state.showGradingDialog = true
      rootState.gradingList.busy = false
      return
    }

    const item_responses = item.item_responses.reduce((accumulator, response) => {
      const { grader_type, grader_id, response_id } = response
      const processedResponse = {
        ...response,
        response: response.response[0],
        languageGrade: 0,
        organisationGrade: 0,
        communicationGrade: 0,
        gradingComment: '',
      }

      if (grader_type === 'expert') {
        const expertGradings = [...(accumulator[response_id]?.expertGradings || []), grader_id]
        processedResponse.expertGradings = expertGradings
      }

      accumulator[response_id] = processedResponse
      return accumulator
    }, {})

    state.sitting.item_responses = Object.values(item_responses)
    state.sitting.hasResponses = true
    state.showGradingDialog = true
  }
  catch (error) {
    console.error(error)
    state.items = state.unfilteredItems = []
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Could not load items.</strong>`,
    }, { root: true })
  }
  rootState.gradingList.busy = false
}

const loadItem = async ({ state, rootState }, item_id) => {
  state.busy = true
  state.item = {}
  const { data } = await apiClient.get(`/v1.11/item?id=${item_id}`)
  const item = Object.values(data.results[0]).flat()[0]
  state.item = item
  state.busy = false
  rootState.gradingList.busy = false
}

const saveGrading = async ({ state, rootState, dispatch }) => {
  state.busy = true
  const { item_responses, sitting_id, user_id, cefr, assessment_type_id } = state.sitting

  const promises = item_responses.map(async ({ item_id, question_id, response_id, languageGrade, organisationGrade, communicationGrade }) => {
    const payload = {
      grader_id: rootState.account.user.user_id,
      candidate_id: user_id,
      response_id,
      item_id,
      question_id,
      sitting_id,
      assessment_type_id,
      candidate_cefr: cefr,
      grading_data: {
        language: languageGrade,
        organisation: organisationGrade,
        communication: communicationGrade,
      },
    }
    try {
      const result = await apiClient.post(`/v1.13/exam/gradings`, payload)
      return result
    } catch (error) {
      return error
    }
  })
  try {
    // 01: Submit grades for each response
    await Promise.all(promises)

    // 02: Submit overall grades for the sitting (if it hasn't been done already)
    const hasScoreAlready = state.sitting.score >= 0
    if (!hasScoreAlready) {
      const { languageGrade, organisationGrade, communicationGrade } = state.sitting
      const averageScore = Math.floor((Number(languageGrade) + Number(organisationGrade) + Number(communicationGrade)) / 3)
      const payload = {
        sitting_id,
        score: averageScore,
        breakdown: {
          language: languageGrade,
          organisation: organisationGrade,
          communication: communicationGrade,
        }
      }
      await apiClient.post(`/v1.12/exam/score`, payload)
    }

    dispatch('snackbar/snack', {
      mode: 'success',
      message: `✅ Added your grades for the sitting.`,
    }, { root: true })
  }
  catch (error) {
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Sorry, something went wrong when adding your grades for this sitting.</strong>`,
    }, { root: true })
  }
  state.busy = false
  state.showGradingDialog = false
  dispatch('gradingList/fetchItems', {}, { root: true })
}

const calculateAverageResponseGrades = ({ state }) => {
  const responseCount = state.sitting.item_responses.length
  const calculate = (type) => {
    const total = state.sitting.item_responses.reduce((total, response) => total + Number(response[type]), 0)
    const average = total / responseCount
    const adjusted = Math.floor(average * 100)
    return adjusted
  }
  state.sitting.languageGrade = calculate('languageGrade')
  state.sitting.organisationGrade = calculate('organisationGrade')
  state.sitting.communicationGrade = calculate('communicationGrade')
}

export default {
  namespaced: true,
  state: {
    busy: false,
    showGradingDialog: false,
    sitting: {},
    item: {},
  },
  mutations: {
    setSitting(state, sitting) {
      state.sitting = sitting
    },
    setShowGradingDialog(state, showGradingDialog) {
      state.showGradingDialog = showGradingDialog
    },
  },
  actions: {
    loadSitting,
    loadItem,
    saveGrading,
    calculateAverageResponseGrades,
  }
}
