import ESVideoPlayer from './ESVideoPlayer.vue'
import ESVideoPlayerV2 from './ESVideoPlayerV2.vue'
import ESAudioRecoder from './ESAudioRecoder.vue'
import ESRecordMessage from './ESRecordMessage.vue'
import ESMarkWords from './ESMarkWords.vue'
import ESSelectWords from './ESSelectWords.vue'
import ESSelectPhrases from './ESSelectPhrases.vue'
import ESRating from './ESRating.vue'
import ESRatingV2 from './ESRatingV2.vue'
import ESQuestions from './ESQuestions.vue'
import ESQuestionsTextOrImage from './ESQuestionsTextOrImage.vue'
import ESAudioPlayer from './ESAudioPlayer.vue'
import ESReportItem from './ESReportItem.vue'
import Vue2TouchEvents from 'vue2-touch-events'
import { install } from 'vue-markdown/dist/vue-markdown'
import VueStarRating from 'vue-star-rating'


import ReadAloud from './marking/templates/ReadAloud.vue'
import ElicitedImitation from './marking/templates/ElicitedImitation.vue'
import IndependentTaskCompletion from './marking/templates/IndependentTaskCompletion.vue'
import IndependentVideoDescription from './marking/templates/IndependentVideoDescription.vue'

import ESRecorder from './assessment/ESRecorder.vue'

export default (Vue) => {
  Vue.use(Vue2TouchEvents, {
    touchHoldTolerance: 100
  })
  Vue.use({
    install
  })
  Vue.component(ESVideoPlayer.name, ESVideoPlayer)
  Vue.component(ESVideoPlayerV2.name, ESVideoPlayerV2)
  Vue.component(ESAudioRecoder.name, ESAudioRecoder)
  Vue.component(ESRecordMessage.name, ESRecordMessage)
  Vue.component(ESMarkWords.name, ESMarkWords)
  Vue.component(ESSelectWords.name, ESSelectWords)
  Vue.component(ESSelectPhrases.name, ESSelectPhrases)
  Vue.component(ESRating.name, ESRating)
  Vue.component(ESRatingV2.name, ESRatingV2)
  Vue.component(ESAudioPlayer.name, ESAudioPlayer)
  Vue.component(ESReportItem.name, ESReportItem)
  Vue.component(ESQuestions.name, ESQuestions)
  Vue.component(ESQuestionsTextOrImage.name, ESQuestionsTextOrImage)
  Vue.component('star-rating', VueStarRating)

  Vue.component(`Mark${ReadAloud.name}`, ReadAloud)
  Vue.component(`Mark${ElicitedImitation.name}`, ElicitedImitation)
  Vue.component(`Mark${IndependentTaskCompletion.name}`, IndependentTaskCompletion)
  Vue.component(`Mark${IndependentVideoDescription.name}`, IndependentVideoDescription)

  Vue.component(ESRecorder.name, ESRecorder)

}