import KeyValueStorage from '@es/data/lib/localstorage/KeyValueStorage'
import { openDb, DB } from 'idb'
import { injectable } from "tsyringe";

@injectable()
export default class KeyValueStorageImpl implements KeyValueStorage {

  private dbName = 'es-store-2'
  storeName = 'esSpokenLocalStorage'

  private storePromise = async () => openDb(this.dbName, 1, (db) => {
    db.createObjectStore(this.storeName)
  })

  async put<T>(key: string, value: T): Promise<void> {
    const db = await this.storePromise()
    const store = db.transaction(this.storeName, 'readwrite').objectStore(this.storeName)
    store.put(value, key)
  }

  async get<T>(key: string): Promise<T> {
    const db = await this.storePromise()
    const store = db.transaction(this.storeName, 'readwrite').objectStore(this.storeName)
    return store.get(key)
  }

  async remove(key: string): Promise<void> {
    const db = await this.storePromise()
    const store = db.transaction(this.storeName, 'readwrite').objectStore(this.storeName)
    store.delete(key)
  }
}