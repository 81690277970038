<template>
  <div>
    <template v-if="showMarking">
      <es-select-phrases
        :disabled="disabled"
        v-model="markingResponse"
        title="Tap all the things you hear the speaker do"
        :phrases="markWordData.phrases" />
    </template>
    <template v-if="showRating">
    <div class="mb-1">
      <es-record-message
        :show-help="false"
        :marking="true"
        title="The speaker is completing this task"
        :message="markWordData.title" />
    </div>
    <div>
      <es-rating
        :is-l-r="true"
        v-model="ratingResponse"
        title="How well did they do?" />
    </div>
    </template>
    <template v-if="showQuestions">
      <es-questions
        :large-font="true"
        :questions="atom.atom.rating_questions"
        v-model="questionResponse"
        title="This is what they should have said:" />
    </template>
    <div class="bottom-actions" v-show="!busy">
      <es-tap-btn @click="next" :disabled="!techIssue && ((showMarking && !markingResponse) || (showRating && !ratingResponse) || (showQuestions && !questionResponse) || busy)" color="indigo">Next</es-tap-btn>
      <!-- <es-tap-btn @click="hasIssue" color="indigo" class="help">
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="28" viewBox="0 0 6 28">
          <g fill="none" fill-rule="evenodd">
            <g fill="#FFF">
                <g>
                    <g transform="translate(-365 -794) translate(0 772) translate(365 22)">
                        <rect width="5" height="5" x=".5" y="22.5" rx="2.5"/>
                        <rect width="5" height="18" x=".5" y=".5" rx="2.5"/>
                    </g>
                </g>
            </g>
        </g>
      </svg>
      </es-tap-btn> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndependentTaskCompletion',
  props: {
    atom: {
      type: Object,
      required: true
    },
    busy: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    techIssue: {
      type: Boolean,
      default: false,
      required: false
    },
    isStatic: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    markingResponse: null,
    ratingResponse: null,
    questionResponse: null,
    showMarking: true,
    showRating: false,
    showQuestions: false
  }),
  computed: {
    markWordData() {
      const [ question ] = this.atom.atom.atom_questions
      return {
        id: question.atom_question_id,
        phrases: question.atom_question_options,
        title: question.atom_question
      }
    },
  },
  methods: {
    next() {
      if (this.showMarking) {
        if (this.isStatic) {
          this.showMarking = false
          this.showQuestions = true
        } else {
          this.showMarking = false
          this.showRating = true
        }
      } else if (this.showRating) {
        this.showMarking = false
        this.showRating = false
        this.$emit('marked', {
          template: 'INDEPENDENT_TASK_COMPLETION',
          'question_id': this.markWordData.id,
          ...this.markingResponse,
          ...this.ratingResponse
        })
      } else if (this.showQuestions) {
        this.showMarking = false
        this.showQuestions = false
        this.$emit('marked', {
          template: 'INDEPENDENT_TASK_COMPLETION',
          'question_id': this.markWordData.id,
          ...this.markingResponse,
          ...this.questionResponse
        })
      }
    },
    hasIssue() {
      this.$emit('hasIssue', {
        template: 'INDEPENDENT_TASK_COMPLETION',
        'question_id': this.markWordData.id,
          tasks: this.markWordData.phrases.map(phrase => ({
            ...phrase,
            status: 'incorrect'
          })),
          'easy_to_understand': 0
      })
    }
  }
}
</script>