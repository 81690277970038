import { apiClient } from './../services/api'
import { getImageSize } from './../services/utils'

export default {
  namespaced: true,
  state: {
    data: {
      version: 1,
      carousel_offers: [],
      dashboard_offers: [],
      form_groups: [],
      dialing_codes: [],
      leads: []
    },
    country: [],
    motivations: [
        "teacher",
        "student",
        "IELTS-study",
        "job",
        "IELTS-visa",
        "Other"
    ],
    validationRules: {
      uf: {
        headlineMaxLength: 75,
        offerMaxLength: 25,
        optInMaxLength: 45,
        ctaMaxLength: 18,
        ctaRegexPattern: /^Get .*/
      },
      db: {
        productMaxLength: 30,
        infoMaxLength: 65,
        ctaMaxLength: 20
      }
    }
  },
  getters: {
    leads: (_, getters) => getters.validatedLeads,
    validatedLeads: state => {
      const { uf , db } = state.validationRules

      return state.data.leads.map(lead => {
        lead.validation = {
          uf: {},
          db: {}
        }

        const urlIssue  = ['dumb_link', 'simple_link'].includes(lead.lead_type) ? ((!lead.offer_details.optional_offer_url || !lead.offer_details.optional_offer_url.length ) ? 'Offer Link Expected' : false) : false
        
        // Check UF data
        const { uf_cta, uf_headline, uf_logo_url, uf_offer, uf_offer_image_url, uf_opt_in } = lead.offer_details
        lead.validation.uf = {
          cta: (uf_cta.length > uf.ctaMaxLength ? `CTA Max Length (${uf.ctaMaxLength})` : false) || (!uf.ctaRegexPattern.test(uf_cta) ? 'CTA should start with "GET"' : false),
          headline: uf_headline.length > uf.headlineMaxLength ? `Headline Max Length (${uf.headlineMaxLength})` : false,
          logo: uf_logo_url.length < 0,
          offer: uf_offer.length > uf.offerMaxLength ? `Offer Max Length (${uf.offerMaxLength})` : false,
          image: uf_offer_image_url.length < 0,
          optIn: uf_opt_in.length > uf.optInMaxLength ? `Opt In Max Length (${uf.optInMaxLength})` : false,
          urlIssue
        }
        for(const key of Object.keys(lead.validation.uf)) {
          if (lead.validation.uf[key]) {
            lead.validation.uf.hasErrors = true
            break
          }
        }
        // Check DB data
        const { db_cta, db_image, db_info, db_logo, db_product } = lead.offer_details
        lead.validation.db = {
          cta: db_cta.length > db.ctaMaxLength ? `CTA Max Length (${db.ctaMaxLength})` : false,
          image: db_image.length < 0,
          info: db_info.length > db.infoMaxLength ? `Info Max Length (${db.infoMaxLength})` : false,
          logo: db_logo.length < 0,
          product: db_product.length > db.productMaxLength ? `Product Max Length (${db.productMaxLength})` : false,
          urlIssue
        }
        for(const key of Object.keys(lead.validation.db)) {
          if (lead.validation.db[key]) {
            lead.validation.db.hasErrors = true
            break
          }
        }

        return lead
      })
    },
    motivations: state => state.motivations,
    carouselOffers: state => state.data.carousel_offers,
    dashboardOffers: state => state.data.dashboard_offers,
    leadsByIDs: (_, getters) => ids => {
        const result: any[] = []
        const leads = getters.validatedLeads.filter(lead => ids.includes(lead.lead_id))
        ids.forEach(id => {
            result.push(leads.find(lead => id === lead.lead_id))
        })
        return result
    }
  },
  mutations: {
    updateLeads: (state, leads) => {
        state.data = leads
    }
  },
  actions: {
    fetchProducts: async ({ commit }) => {
      try {
        const response = await apiClient.get('/v1.11/products')
        const { carousel_offers, dashboard_offers, form_groups, dialing_codes, leads, version} = response.data.all_leads
        commit('updateLeads', {
          carousel_offers,
          dashboard_offers,
          form_groups,
          dialing_codes,
          leads,
          version
        })
      } catch(err) {
        console.log('API: fetchProducts (FAILED)', err.message)
      }
    },
    getImageSize: (_, url) => getImageSize(url)
  }
}