<template>
  <v-snackbar
      top
      v-model="showSnackbar"
      :color="color"
      timeout="5000"
    >
      <span v-html="message"></span>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {

  computed: {
    ...mapState('snackbar',[
      'show',
      'color',
      'message',
    ]),
    showSnackbar: {
      get() { return this.show },
      set(value) { this.$store.commit('snackbar/setShow', value) }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .v-snack__wrapper.theme--dark {
    &.error {
      background-color: var(--cranberry);
    }
    &.success {
      background-color: var(--teal);
    }
  }
}
</style>
