import { apiClient } from '../services/api'

const state = {
  payload: {
    platform: '',
    appVersion: '',
  },
  success: '',
  error: '',
}

const actions = {
  retireAppVersion: async ({ state }) => {
    state.success = ''
    state.error = ''

    console.log(state);

    try {
      const { platform, appVersion } = state.payload;
      const payload = {
        platform: platform,
        app_version: appVersion,
        retire: true,
        retain: false
      }
      await apiClient.patch('v1.11/app-version', payload)

      state.success = `
        <strong>App version retired successfully</strong><br />
        <small>
          &middot; Platform: ${platform} <br />
          &middot; App version: ${appVersion} <br />
        </small>`;
      state.payload = { platform: '', appVersion: '' };
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const message = error?.response?.data?.message || 'Unknown error occurred';
      state.error = `<p>Sorry. An error occurred.</p> <small>${message}</small>`;
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
}
