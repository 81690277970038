export default [
  {
    "item_id": "5ca2c647-b4c9-4aed-a1b6-f06fc055440f",
    "marking_question_1": "Did they describe this weather? [image:Weather forecast for night only]",
    "marking_question_2": "-"
  },
  {
    "item_id": "ffbffadf-c65b-4a15-be93-bb5c7ef97c68",
    "marking_question_1": "Did they say a time when they are free? [image: as question]",
    "marking_question_2": "-"
  },
  {
    "item_id": "599061ce-a33b-4010-848c-9b010910dc40",
    "marking_question_1": "Did they choose one of these holidays?  [image: as question]",
    "marking_question_2": "-"
  },
  {
    "item_id": "f66fd294-b86a-429f-8a5d-ce616f1f36a3",
    "marking_question_1": "Did they talk about these hobbies?  [image: as question]",
    "marking_question_2": "-"
  },
  {
    "item_id": "105f5b14-6bd1-4291-8e30-d865c8b83240",
    "marking_question_1": "Did they choose one of these meals?  [image: as question]",
    "marking_question_2": "-"
  },
  {
    "item_id": "383d75fb-c3d3-4526-b765-9b019753c7d8",
    "marking_question_1": "Did they say 'yes' to the party?  [image: as question]",
    "marking_question_2": "-"
  },
  {
    "item_id": "4440addd-dbf4-4f4d-85f8-c77574f49cfb",
    "marking_question_1": "Did they say where the supermarket is?  [image: as question]",
    "marking_question_2": "-"
  },
  {
    "item_id": "89230934-7603-4e46-9443-80449f2df970",
    "marking_question_1": "Did they say what the problem is?  [image: as question]",
    "marking_question_2": "-"
  },
  {
    "item_id": "cc46f938-f7c6-4a0f-93af-7d6b462b9973",
    "marking_question_1": "Did they talk about these two topics? [topics as question]",
    "marking_question_2": "Did they talk about what they like to do in their free time?"
  },
  {
    "item_id": "8eb4c08b-b47d-4743-b5d6-6ccb6018b53a",
    "marking_question_1": "Did they talk about these two topics? [topics as question]",
    "marking_question_2": "Did they say what they did last weekend?"
  },
  {
    "item_id": "a251dd5a-89af-4125-a103-e150dd04f176",
    "marking_question_1": "Did they talk about these two topics? [topics as question]",
    "marking_question_2": "Did they describe the town or city where they live?"
  },
  {
    "item_id": "890bce82-b768-41cb-9920-1c5d91be8206",
    "marking_question_1": "Did they talk about these two topics? [topics as question]",
    "marking_question_2": "Did they talk about the best place they've ever visited?"
  },
  {
    "item_id": "482188e0-e5f2-46cb-b620-f0699eea03ec",
    "marking_question_1": "Did they talk about these two topics? [topics as question]",
    "marking_question_2": "Did they talk about what they do when they want to relax?"
  },
  {
    "item_id": "17906b76-27b6-4a9c-8814-275e03b96861",
    "marking_question_1": "Did they talk about these two topics? [topics as question]",
    "marking_question_2": "Did they talk about their favourite type of movie?"
  },
  {
    "item_id": "d11a03bf-d363-4fb0-8fd3-2b269d9d9d53",
    "marking_question_1": "Did they talk about these two topics? [topics as question]",
    "marking_question_2": "Did they recommend somewhere to go for dinner?"
  },
  {
    "item_id": "12309d94-99f4-4a66-ae88-38666abe3a27",
    "marking_question_1": "Did they talk about these two topics? [topics as question]",
    "marking_question_2": "Did they talk about which hobby they would like to start doing?"
  },
  {
    "item_id": "95159ac1-8f25-493b-9964-0bbe645b89ec",
    "marking_question_1": "Did they say where they would like to live?  [image: as question]",
    "marking_question_2": ""
  },
  {
    "item_id": "828443a0-a8bd-40f0-9106-7df1e9c64bb1",
    "marking_question_1": "Did they give reasons for thier ideas?  [image: as question]",
    "marking_question_2": ""
  },
  {
    "item_id": "d153192d-c7a4-44b7-8f6e-f633051d300f",
    "marking_question_1": "Did they describe the last time they travelled somewhere?  [image: as question]",
    "marking_question_2": ""
  },
  {
    "item_id": "aa1aaadb-1aa0-463b-b368-64656bcbfd84",
    "marking_question_1": "Did they give reasons why they would or wouldn't like to try extreme sports?  [image: as question]",
    "marking_question_2": ""
  },
  {
    "item_id": "0150c844-5ea3-4d5e-8acd-e779267a9f5c",
    "marking_question_1": "Did they describe their ideal colleague?  [image: as question]",
    "marking_question_2": ""
  },
  {
    "item_id": "e3e32c67-fd03-4fba-a1e3-e7f565394ea7",
    "marking_question_1": "Did they describe what to wear to the interview?   [image: as question]",
    "marking_question_2": ""
  },
  {
    "item_id": "33c269bb-f599-405d-b714-777ba84f5548",
    "marking_question_1": "Did they give ideas on how to make the office more environmentally friendly?  [image: as question]",
    "marking_question_2": ""
  },
  {
    "item_id": "fbc68232-c28a-4b00-81b3-6e355dbf7032",
    "marking_question_1": "Did they say where they would prefer to work and why?  [image: as question]",
    "marking_question_2": ""
  },
  {
    "item_id": "c302c3ba-b668-4848-bb65-f03929e313b1",
    "marking_question_1": "Did they mention these two topics? [topics as question]",
    "marking_question_2": "Did they share their thoughts on workplace well-being?"
  },
  {
    "item_id": "a159edf5-dd68-4433-a853-a4113f3aceec",
    "marking_question_1": "Did they mention these two topics? [topics as question]",
    "marking_question_2": "Did they talk about what makes a good manager?"
  },
  {
    "item_id": "89b13609-5426-4548-8632-ef966db60e9f",
    "marking_question_1": "Did they mention these two topics? [topics as question]",
    "marking_question_2": "Did they talk about how they stay organised?"
  },
  {
    "item_id": "1649a6dd-c51d-4bfe-9a7c-f0543965be40",
    "marking_question_1": "Did they mention these two topics? [topics as question]",
    "marking_question_2": "Did they talk about who it's best to get advice from about work problems?"
  },
  {
    "item_id": "8eb6304b-db54-40b9-8793-d164aa8305bb",
    "marking_question_1": "Did they mention these two topics? [topics as question]",
    "marking_question_2": "Did they talk about whether they prefer working for themselves or for a company?"
  },
  {
    "item_id": "6ad154c2-b1b7-47ed-952f-d9cc7c6d5f54",
    "marking_question_1": "Did they mention these two topics? [topics as question]",
    "marking_question_2": "Did they talk about what their ideal job would be?"
  },
  {
    "item_id": "b0ed65a9-c4fd-440c-af95-7500f9945779",
    "marking_question_1": "Did they mention these two topics? [topics as question]",
    "marking_question_2": "Did they talk about how much people are influenced by their peers?"
  },
  {
    "item_id": "5eaa2583-d379-433d-a979-a9b5c930950c",
    "marking_question_1": "Did they mention these two topics? [topics as question]",
    "marking_question_2": "Did they give advice about an interview for a customer service role?"
  }
]