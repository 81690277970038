<template>
  <div class="es-recorder" :class="{ 'done' : showNext, 'disabled': disabled}">
    <es-tooltip v-if="error" color="orange" tooltip-position="top">
      <p v-if="title" class="title">{{ error.message }}</p>
      <p v-if="subTitle" class="sub-title">Hold to record.</p>
    </es-tooltip>
    <button class="mic-btn" ref="micBtn" :class="{ 'recording': recording, 'busy': !error && busy }">
      <div class="record">
        <div class="content">
          <p>{{ title }}</p>
          <span>{{ subTitle }}</span>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="41" viewBox="0 0 26 41">
            <g fill="none" fill-rule="evenodd">
                <g stroke="#220A5A" stroke-width="3">
                    <g>
                        <g transform="translate(-344 -785) translate(14 770) translate(332 15)">
                            <rect width="10.852" height="23.074" x="5.574" y="1.5" rx="5.426"/>
                            <path stroke-linecap="round" d="M22 15.889v3.667c0 6.075-4.925 11-11 11s-11-4.925-11-11v-3.667"/>
                            <path d="M11 30.963L11 39.519"/>
                            <path stroke-linecap="round" d="M18.5 39.5L3.5 39.5"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </div>
      </div>
      <div class="recording">
        <audio-wave :no-of-waves="noOfWaves"/>
      </div>
      <div class="busy">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <button class="try-again" v-es-on:click="() => $emit('tryagain')" :disabled="!canTryAgain || !showNext">
      <p>Try again?</p>
      <span>{{ subTitle }}</span>
    </button>
    <button class="next" v-es-on:click="() => $emit('next')" :disabled="!showNext">Next</button>
    <audio-progress v-if="showProgress" :min-duration="minDuration" :max-duration="maxDuration" :recording="recording && !error && !busy" @timeout="onMaxRecordingTimeout" />
    <div class="touch-area" :class="{ 'hide': showNext }" ref="touchArea"></div>
  </div>
</template>
<script>
import AudioWave from './AudioWave'
import AudioProgress from './AudioProgress';

export default {
  name: 'es-recorder',
  props: {
    showProgress: {
      type: Boolean,
      default: () => false,
      required: false
    },
    minDuration: {
      type: Number,
      default: 5000,
      required: false
    },
    maxDuration: {
      type: Number,
      default: 15000,
      required: false
    },
    maxTrials: {
      type: Number,
      default: 2
    },
    recording: {
      type: Boolean,
      default: () => false
    },
    canTryAgain: {
      type: Boolean,
      default: () => true
    },
    error: {
      type: Error,
      default: null
    },
    busy: {
      type: Boolean,
      default: () => false
    },
    showNext: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
  },
  data: () => ({
    hammer: null,
    done: false,
    state: 'record',
    noOfWaves: 32,
    interval: null,
    isTouched: false
  }),
  components: {
    AudioWave,
    AudioProgress
  },
  mounted() {
    const { clientWidth, clientHeight } = this.$refs.touchArea;
    let touchEndTimeout = null;
    this.$refs.touchArea.addEventListener('touchmove', (evt) => {
      if (this.disabled) return;
      const { layerX, layerY } = evt
      if (this.isTouched) {
        this.canMove = (layerX > 0 && layerX < clientWidth) && (layerY > 0 && layerY < clientHeight)
        if (!this.canMove) {
          this.isTouched = false
          this.handleUp()
        }
      }
    })

    this.$refs.touchArea.addEventListener('touchstart', () => {
      clearTimeout(touchEndTimeout);
      if (this.isTouched || this.recording || this.showNext || this.busy || this.disabled) return;
      this.isTouched = true
      this.handleDown();
    });

    this.$refs.touchArea.addEventListener('touchend', () => {
      touchEndTimeout = setTimeout(() => {
        if (!this.isTouched || this.showNext || this.busy || this.disabled) return;
        this.isTouched = false
        this.handleUp()
      }, 100)
    });
  },
  beforeDestroy() {
    this.$refs.touchArea.removeEventListener('touchmove', () => { })
    this.$refs.touchArea.removeEventListener('touchstart', () => { })
    this.$refs.touchArea.removeEventListener('touchend', () => { })
  },
  methods: {
    handleUp() {
      this.$emit('stopRecording')
    },
    handleDown() {
      this.$emit('startRecording')
    },
    onMaxRecordingTimeout() {
      this.$refs.touchArea.dispatchEvent(new Event('touchend'))
    }
  }
}
</script>