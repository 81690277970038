/**
 * See: README.md "#Developer note: Item to-do data"
 * Each to-do item contains an object of default values. For coding purposes, this object is called "presets"
 */
import data from '@/assets/data/item-todos.json'; // https://mariusschulz.com/blog/importing-json-modules-in-typescript
const rawPresets = data.values as unknown as Array<string | { [key: string]: string }>; // https://github.com/microsoft/TypeScript/issues/28067

const skillsMap = {
  g: 'Grammar',
  v: 'Vocabulary',
  r: 'Reading',
  l: 'Listening',
  s: 'Speaking',
  w: 'Writing',
}

// Generate a human-friendly label e.g. from "DND_READING" to "Dnd Reading"
const humanize = (string: string) => string
  .trim()
  .toLowerCase()
  .split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ')

const items = rawPresets.map((item, index) => {
  const name = item[0]
  const explanation = item[1]
  const data = item[2]

  // Provide a human-readable version of the permitted_formats for the "templates" column.
  const { permitted_formats } = data
  const templates = [].concat(permitted_formats).map(humanize)

  // Generate a pseudo-unique id for each to-do item
  const { section, level, bucket } = data
  const skill = skillsMap[section.toLowerCase()]
  const moniker = [skill, level, bucket].sort().join('-')

  const { function: languageFunction, domain, length_min, length_max } = data

  // Sometimes the language function text is unneccessarily long. It should be a single word.
  const cleanLanguageFunction = languageFunction.split(' ').length > 1 ? '' : languageFunction
  return {
    index,
    name,
    bucket: String(bucket).padStart(2, '0'),
    level,
    skill,
    explanation,
    templates,
    moniker,
    languageFunction: cleanLanguageFunction,
    domain,
    length_min,
    length_max,
  }
})

// These presets are loaded from the item-todos.json file
interface Item {
  moniker: string;
  skill: string;
  bucket: string;
  level: string;
  function: string;
  domain: string;
  length_min: number;
  length_max: number;
}

// https://vuex.vuejs.org/guide/getters.html#method-style-access
const getPresets = state => (index: number): Item => {
  const raw = rawPresets[index]
  const itemPresets = { ...raw[2], ...raw[3] }
  const skill = skillsMap[itemPresets.section.toLowerCase()]
  const domain = state.domains.find(({ value }) => value === itemPresets.domain.toLowerCase())
  const languageFunction = state.languageFunctions.find(({ value }) => value === itemPresets.function)
  return {
    ...itemPresets,
    skill,
    domain,
    languageFunction,
  }
}

// Derive a list of possible values from the raw data for a given property
const getPossibleValues = (propertyName: string) => {
  const keys = [...new Set(rawPresets.map(item => item[2][propertyName]).flat().map(v => v.toLowerCase()))]
  const values = keys.map(key => ({ value: key, text: humanize(key) }))
  return values
}
const domains = getPossibleValues('domain')
const languageFunctions = getPossibleValues('function')
const templates = getPossibleValues('permitted_formats')

const headers = [
  { text: 'Name', value: 'name' },
  { text: 'Skill', value: 'skill' },
  { text: 'CEFR', value: 'level' },
  { text: 'Bucket', value: 'bucket' },
  { text: 'Domain', value: 'domain' },
  { text: 'Function', value: 'languageFunction' },
  { text: 'Explanation', value: 'explanation' },
  { text: 'Template', value: 'templates' },
]

export default {
  namespaced: true,
  state: {
    headers,
    items,
    domains,
    languageFunctions,
    templates,
  },
  getters: {
    getPresets,
  },
}
