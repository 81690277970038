import axios from "axios";
import { apiClient } from "@/services/api";
import dateString from "@/plugins/filters/date-string";

const headers = [
  { text: "Order Updated", value: "order_updated" },
  { text: "Certificate", value: "certificate_status" },
  { text: "Proctoring", value: "proctoring_status" },
  { text: "Assessment Type", value: "assessment_type" },
  { text: "Country", value: "user_country" },
  { text: "Code", value: "voucher_code" },
  { text: "Tier", value: "tier" },
  { text: "Certificate Type", value: "product_title" },
  { text: "", value: "sitting_id", align: " d-none" },
  { text: "", value: "user_id", align: " d-none" },
  { text: "", value: "order_id", align: " d-none" },
  { text: "", value: "actions", align: "right", sortable: false }
];

const filters = [
  {
    label: "Certificate Status",
    value: "certificate_status",
    items: [
      { text: "All" },
      { text: "Available" },
      { text: "Pending" },
      { text: "Invalidated" }
    ],
    selectedIndex: 1 // Default to "Available"
  },
  {
    label: "Proctoring Status",
    value: "proctoring_status",
    items: [
      { text: "All" },
      { text: "Pass" },
      { text: "Fail" },
      { text: "Flag" },
      { text: "Unreviewed" }
    ],
    selectedIndex: 4 // Default to "Unreviewed"
  },
  {
    label: "Assessment Type",
    value: "assessment_type",
    items: [
      { text: "All" },
      { text: "Core" },
      { text: "Speaking" },
      { text: "Writing" }
    ],
    selectedIndex: 0
  }
];

// Generate a human-friendly label e.g. from "DND_READING" to "Dnd Reading"
const humanize = (string: string) =>
  string &&
  string
    .trim()
    .toLowerCase()
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const getSkill = assessmentTypeName => {
  if (assessmentTypeName.startsWith("crella")) return "Core Skills";
  if (assessmentTypeName.startsWith("spoken")) return "Speaking";
  if (assessmentTypeName.startsWith("writing")) return "Writing";
  return "Unknown";
};

type metaData = {
  status: string;
  seconds_into_the_test: number;
  parameters: string;
};

type imageData = {
  image: string;
  thumbnail: string;
  metadata: metaData | null;
};

type RoomScanData = {
  room_scan_video: string;
};

interface UserMediaData {
  loadingProctoringImages: boolean;
  proctoringImagesAreLoaded: boolean;
  main_photo: string | boolean;
  proctoring_data: Array<imageData>;
  violation_data: Array<imageData>;
  recording_data: Array<imageData>;
  room_scan_data: Array<RoomScanData>;
  violations: boolean;
  noImagesFound: boolean;
}
const getUserMediaData = async ({ user_id, item }) => {
  const { proctoringImagesAreLoaded } = item;
  if (proctoringImagesAreLoaded) return;

  const { user_id: test_taker_id, sitting_id } = item;
  const params = {
    main_photo: true,
    proctoring_images: true,
    violation_images: true,
    test_taker_id,
    user_id,
    sitting_id,
    application: "CMS"
  };

  const {
    data: {
      proctoring_data,
      main_data,
      violation_data,
      recording_data,
      room_scan_data,
      violations
    }
  } = await apiClient.get("/v0.1/dataflow/usermedia", { params });
  const noImagesFound =
    !main_data?.length && !proctoring_data?.length && !violation_data?.length;
  const userMediaData: UserMediaData = {
    loadingProctoringImages: false,
    proctoringImagesAreLoaded: true,
    main_photo: false,
    proctoring_data: [],
    violation_data: [],
    recording_data: [],
    room_scan_data: [],
    violations,
    noImagesFound
  };

  userMediaData.main_photo = main_data?.length ? main_data[0].image : false;
  userMediaData.proctoring_data = proctoring_data;
  userMediaData.violation_data = violation_data;
  userMediaData.recording_data = recording_data;
  userMediaData.room_scan_data = room_scan_data;

  return userMediaData;
};

const enrichItem = async ({ state, rootState }, item) => {
  if (!item || item.proctoringImagesAreLoaded) return;

  const { items } = state;
  const foundItemIndex = items.findIndex(
    ({ order_id }) => order_id === item.order_id
  );
  if (foundItemIndex !== -1) {
    // Immediately show the loading spinner.
    items.splice(foundItemIndex, 1, {
      ...item,
      loadingProctoringImages: true
    });
  }

  const { user_id } = rootState.account.user;
  const userMediaData = await getUserMediaData({ user_id, item });

  // Wait until the last second to load item data from state, as it might have changed.
  const foundItem = items.find(({ order_id }) => order_id === item.order_id);
  if (!foundItem) return;

  const enrichedItem = {
    ...foundItem,
    ...userMediaData
  };
  if (foundItemIndex !== -1) {
    items.splice(foundItemIndex, 1, enrichedItem);
  }
};

const fetchItems = async ({ dispatch, state, rootGetters }) => {
  state.busy = true;
  //Get current filters value to be sent to API (`proctoring_status` is `status` in API, so we need to convert it)
  const currentFilters = Object.fromEntries(
    state.filters.map(filter => [
      filter.value == "proctoring_status"
        ? "status"
        : filter.value.toLowerCase(),
      filter.items[filter.selectedIndex].text.toLowerCase()
    ])
  );
  // Different limits for different proctoring status
  // unreviewed: 100
  // flag: 500
  // fail: 1000
  // else: 5000
  const limit =
    currentFilters.status === "unreviewed"
      ? 100
      : currentFilters.status === "flag" || currentFilters.status === "fail"
      ? 1000
      : 5000;

  // @schema https://api2-staging.englishscore.com/v1.11/documentation
  const params = {
    limit: limit,
    ...currentFilters
  };

  try {
    const {
      data: { total, results }
    } = await apiClient.get(`/v1.11/proctoring`, { params: params });

    const items = results
      .map(item => {
        const [tier] = item.product_type.split("_");
        item.tier = humanize(tier);
        return item;
      })
      .map(item => ({
        certificate_status: item.certificate_status
          ? item.certificate_status.charAt(0).toUpperCase() +
            item.certificate_status.slice(1)
          : "",
        order_id: item.order_id,
        order_updated: item.order_updated,
        formattedOrderTime: dateString(item.order_updated),
        user_id: item.user_id,
        user_country: rootGetters["country/getCountryName"](item.user_country),
        sitting_id: item.sitting_id,
        proctoring_status: humanize(item.proctoring_status),
        voucher_code: item.voucher_code || "—",
        assessment_type: getSkill(item.assessment_type_name),
        started_time: item.sitting_started_at,
        product_title: item.product_title || "—",
        tier: item.tier || "—",
        hybrid_assessment: item.hybrid_assessment
      }))
      .sort(
        (a, b) =>
          new Date(a.order_updated).getTime() -
          new Date(b.order_updated).getTime()
      );

    state.items = items;
    state.itemsAreLoaded = true;
  } catch (error) {
    console.error(error);
    state.items = [];
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Could not load items.</strong>`
      },
      { root: true }
    );
  }
  state.busy = false;
};

const searchItems = async ({ dispatch, state, rootGetters }, searchTerm) => {
  state.busy = true;
  const currentFilters = Object.fromEntries(
    state.filters.map(filter => [
      filter.value == "proctoring_status"
        ? "status"
        : filter.value.toLowerCase(),
      filter.items[filter.selectedIndex].text.toLowerCase()
    ])
  );

  const limit = 100;

  // @schema https://api2-staging.englishscore.com/v1.11/documentation
  const params = {
    limit: limit,
    search_uuid: searchTerm.trim(),
    ...currentFilters
  };

  try {
    const {
      data: { total, results }
    } = await apiClient.get(`/v1.11/proctoring`, { params: params });

    const newItems = results
      .map(item => {
        const [tier] = item.product_type.split("_");
        item.tier = humanize(tier);
        return item;
      })
      .map(item => ({
        certificate_status: item.certificate_status
          ? item.certificate_status.charAt(0).toUpperCase() +
            item.certificate_status.slice(1)
          : "",
        order_id: item.order_id,
        order_updated: item.order_updated,
        formattedOrderTime: dateString(item.order_updated),
        user_id: item.user_id,
        user_country: rootGetters["country/getCountryName"](item.user_country),
        sitting_id: item.sitting_id,
        proctoring_status: humanize(item.proctoring_status),
        voucher_code: item.voucher_code || "—",
        assessment_type: getSkill(item.assessment_type_name),
        started_time: item.sitting_started_at,
        product_title: item.product_title || "—",
        tier: item.tier || "—",
        hybrid_assessment: item.hybrid_assessment
      }));

    const combinedItems = [...state.items, ...newItems];
    const uniqueItems = Array.from(
      new Set(combinedItems.map(item => item.order_id))
    ).map(order_id => combinedItems.find(item => item.order_id === order_id));

    state.items = uniqueItems;
    state.itemsAreLoaded = true;
  } catch (error) {
    console.error(error);
    state.items = [];
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Could not search item.</strong>`
      },
      { root: true }
    );
  }
  state.busy = false;
};

export default {
  namespaced: true,
  state: {
    busy: true,
    headers,
    items: [],
    filters,
    itemsAreLoaded: false
  },
  actions: {
    fetchItems,
    enrichItem,
    searchItems
  },
  mutations: {
    setFilters(state, filters) {
      state.filters = filters;
    }
  }
};
