<template>
  <div class="input">
    <label :for="id">{{ label }}</label>
    <input :id="id" type="text">
    <p class="errors">errors</p>
  </div>
</template>
<script>
export default {
  name: 'es-text-input',
  props: ['label', 'placeholder'],
  data: () => ({
    id: `es-text-input-${Math.ceil(Math.random() * 9999)}`
  })
}
</script>
<style lang="scss" scoped>
.input {

}
</style>