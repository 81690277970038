
import { singleton } from "tsyringe";
import NativeUtils from "@es/domain/lib/services/NativeUtils";
import { DeviceConfiguration } from "@es/domain/lib/models/DeviceConfiguration";

@singleton()
export default class WebNativeUtils implements NativeUtils {

  async checkMicPermission(onPermissionGranted: () => void): Promise<boolean> {
    try {
      await window.navigator.permissions.query({
        name: 'microphone'
      })
    } catch(err) {
      console.log('WebNativeUtils', err)
    }
    return true
  }

  async exitWebApp(_: boolean): Promise<void> {
    window.location.reload()
  }

  async updateMarkingState(markingState: object): Promise<void> {
    localStorage.setItem('markingState', JSON.stringify(markingState))
  }

  async openMicSetting(): Promise<void> {
    const misSettingA = document.createElement('a')
    misSettingA.setAttribute('href', 'prefs:root=Privacy&path=MICROPHONE')
    misSettingA.click()

    return
  }

  async getDeviceConfiguration(): Promise<DeviceConfiguration> {
    return {
      topSafeAreaHeight: 56,
      bottomSafeAreaHeight: 16
    }
  }
  
}