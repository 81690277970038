import { apiClient } from '@/services/api'

const hiddenEvents = process.env.VUE_APP_EVENTS_HIDDEN || ''

/**
 * @note "cht" stands for "Cheat"
 */
const getChtEvents = (eventData, userID) => {

  // Filter out events that are not CHT events
  const chtEvents = eventData.filter(({ event_name }) => event_name.toLowerCase().startsWith('cht_'))

  // Deduplicate events based on the event timestamp
  const dedupedEvents = Object.values(chtEvents.reduce((accumulator, event) => {
    const { event_timestamp } = event
    accumulator[event_timestamp] = event
    return accumulator
  }, {}))


  // Some events don't have sittingid, but all have ga_session_id
  // Make a map of ga_session_id to sittingid so that we can find sittingid based off ga_session_id
  const sessionIds = dedupedEvents.reduce<Record<string, string>>((accumulator, event) => {
    const { event_params } = event as ChtEvent
    const sittingid = event_params.find(({ key }) => key.toLowerCase() === 'sittingid')?.value?.string_value
    const ga_session_id = event_params.find(({ key }) => key === 'ga_session_id')?.value?.int_value
    if (!sittingid || !ga_session_id) return accumulator

    accumulator[ga_session_id] = sittingid
    return accumulator
  }, {})

  // Pluck some desired properties from the events
  interface ChtEvent {
    event_name: string;
    event_timestamp: string;
    event_params: [
      {
        key: string;
        value: {
          string_value: string;
          int_value: number;
        };
      }
    ];
  }

  // "Lean" as in "Trimmed down to only the properties we need"
  const leanEvents = dedupedEvents.map(event => {
    const { event_name, event_timestamp, event_params } = event as ChtEvent
    if (!event_name) return

    const filename = event_params.find(({ key }) => key === 'filename')?.value?.string_value
    const image_token = event_params.find(({ key }) => key === 'image_token')?.value?.string_value
    if (!filename && !image_token) return false

    const ga_session_id = event_params.find(({ key }) => key === 'ga_session_id')?.value?.int_value
    if (!ga_session_id) return false
    const sittingid = sessionIds[ga_session_id]
    const seconds_into_test = event_params.find(({ key }) => key === 'seconds_into_test')?.value?.int_value
    return {
      sittingid,
      userID,
      event_name,
      seconds_into_test,
      event_timestamp,
      filename: filename || image_token,
    }
  })
    .filter(Boolean)
    .sort((eventOne, eventTwo) => {
      if (!eventOne || !eventTwo) return 0
      return Number(eventOne.event_timestamp) - Number(eventTwo.event_timestamp)
    })

  // Split the lean events into groups based on sittingid
  const chtEventsBySittingId = leanEvents.reduce((accumulator, event) => {
    if (!event) return accumulator
    const sittingid = event.sittingid
    if (!accumulator[sittingid]) accumulator[sittingid] = {
      sittingid,
      events: [],
    }
    accumulator[sittingid].events.push(event)
    return accumulator
  }, {})

  return chtEventsBySittingId
}

/**
 * enrichedEvents: A copy of eventData, with added "src" property to appropriate image events
 */
const getEnrichedEvents = (eventData, userID) => {
  const enrichedEvents = eventData.map(event => {

    // @convention: Events with images are prefixed with "CHT_"
    const { event_name } = event
    if (!event_name.toLowerCase().includes("cht_")) {
      return event
    }

    // Only enrich CHT_ events that have an image_token or filename event_parameter
    const { event_params } = event
    const image_token = event_params.find(({ key }) => key === 'image_token')?.value?.string_value
    const filename = event_params.find(({ key }) => key === 'filename')?.value?.string_value
    if (!image_token && !filename) {
      return event
    }

    try {
      // Get the session ID of the event
      const sessionId = event_params.find(({ key }) => key === 'ga_session_id')?.value?.int_value

      // Get all events with the same session ID
      const eventsMatchingSessionId = eventData.filter(({ event_params }) => {
        const int_value = event_params.find(({ key }) => key === 'ga_session_id')?.value?.int_value
        return int_value === sessionId
      })

      // Get the first event with that session ID that has a sittingid
      const eventWithSittingId = eventsMatchingSessionId.find(({ event_params }) => {
        return event_params.some(({ key }) => key === 'sittingid')
      })

      if (!eventWithSittingId) {
        console.warn('No events with a sittingid were found for session:', sessionId)
        return event
      }
      const sitting_id = eventWithSittingId.event_params.find(({ key }) => key === 'sittingid')?.value?.string_value

      // Generate the src property for the image and return the enriched event.
      if (sitting_id) {
        const newEvent = {
          ...event,
          hasProctoringImage: true,
          userID,
          sittingid: sitting_id,
          filename: image_token || filename
        }
        return newEvent
      }
    } catch (error) {
      console.log(error)
    }

    // As a failsafe return the original event
    return event
  })
  return enrichedEvents
}

const loadEvents = async ({ commit }, { eventDate, userID }) => {
  try {
    commit('setChtEvents', {})
    commit('setEvents', [])
    commit('setBusy', true)
    const { data } = await apiClient.get(`/v1.11/analytics/user?user_id=${userID}&event_date=${eventDate}`)
    const { user_events: eventData, user_deleted } = data;

    // chtEvents are the violation images displayed above the user events table
    const chtEvents = getChtEvents(eventData, userID)
    commit('setChtEvents', chtEvents)

    // enrichedEvents are the events displayed in the user events table
    const enrichedEvents = getEnrichedEvents(eventData, userID)
    commit('setEvents', enrichedEvents)
    commit('setUserDeleted', user_deleted)
    console.log(user_deleted)
  } catch (error) {
    // console.log(error)
  } finally {
    commit('setBusy', false)
  }
}

export default {
  namespaced: true,
  state: {
    events: [],
    chtEvents: {},
    busy: false,
    hiddenEvents: hiddenEvents.split(','),
    userDeleted: false,
  },
  mutations: {
    setEvents: (state, events) => {
      state.events = events
    },
    setChtEvents: (state, chtEvents) => {
      state.chtEvents = chtEvents
    },
    setBusy: (state, busy) => {
      state.busy = busy
    },
    setUserDeleted: (state, userDeleted) => {
      state.userDeleted = userDeleted
    }
  },
  actions: {
    loadEvents,
  },
  getters: {
    chtEvents: state => state.chtEvents,
    events: state => state.events.sort((eventOne, eventTwo) => Number(eventOne.event_timestamp) - Number(eventTwo.event_timestamp)),
    isBusy: state => state.busy,
    hiddenEvents: state => state.hiddenEvents,
    userDeleted: state => state.userDeleted,
  }
}
