import { apiClient } from './../services/api'

const maskText = {
  percentage_discount: 'Discount',
  valid_from: 'Valid From',
  valid_to: 'Valid To',
  code: 'Voucher Code',
}

export default {
  namespaced: true,
  state: {
    busy: false,
    vouchers: [],
    organisations: [],
    connectCodes: [],
    error: null,
    voucherTypes: [],
  },
  mutations: {
    updateVouchers: (state, vouchers) => {
      state.vouchers = vouchers
    },
    updateConnectCodes: (state, connectCodes) => {
      state.connectCodes = connectCodes
    },
    updateError: (state, error) => {
      state.error = error
    },
    updateBusy: (state, busy) => {
      state.busy = busy
    },
    updateOrganisations: (state, organisations) => {
      state.organisations = organisations
    },
    updateDescription: (state, description) => {
      state.description = description
    },
    updateVoucherTypes: (state, voucherTypes) => {
      state.voucherTypes = voucherTypes
    },
  },
  actions: {
    fetchVouchers: async ({ commit }) => {
      try {
        commit('updateError', null)
        commit('updateBusy', true)
        const { data } = await apiClient.get(`/v1.11/voucher`,{params: {skip_usage_stats: true}})
        commit('updateVouchers', data.results)
      } catch(err) {
        commit('updateError', err)
      } finally {
        commit('updateBusy', false)
      }
    },
    fetchConnectCodes: async ({ commit }) => {
      try {
        commit('updateError', null)
        commit('updateBusy', true)
        const { data: { voucher_types } } = await apiClient.get(`/v1.11/voucher/types`)
        const { voucher_type_id } = voucher_types.find(({ product_type }) => product_type === 'employee_sharing')
        const { data: { results } } = await apiClient.get(`/v1.11/voucher?voucher_type_id=${voucher_type_id}`,{params: {skip_usage_stats: true}})
        commit('updateConnectCodes', results)
      } catch(err) {
        commit('updateError', err)
      } finally {
        commit('updateBusy', false)
      }
    },
    addVoucher: async ({ commit }, voucherPayload) => {
      try {
        commit('updateError', null)
        commit('updateBusy', true)

        // Use multipart/form-data to allow image uploads
        const formData = new FormData()
        Object.keys(voucherPayload).forEach(key => {
          formData.append(key, voucherPayload[key])
        })
        await apiClient.post(`/v1.11/voucher`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
      } catch(err) {
        commit('updateError', err)
        if (err.response) {
          const errorMessages: string[] = []
          switch(err.response.status) {
            case 400:
              if (err.response.data.errors) {
                for(const key in err.response.data.errors) {
                  errorMessages.push(`${maskText[key]}: ${err.response.data.errors[key]} `)
                }
                throw new Error(errorMessages.join("\n"))
              }
              throw new Error(err.response.data.message)
            case 404:
              throw new Error(err.response.data.message)
            case 500:
            default:
              throw new Error('Something went wrong, Please try again.')
          }
        } else {
          throw new Error('Something went wrong, Please try again.')
        }
      } finally {
        commit('updateBusy', false)
      }
    },
    removeVoucher: async ({ commit }, voucherPayload) => {
      try {
        commit('updateError', null)
        commit('updateBusy', true)
        await apiClient.delete(`/v1.11/voucher`, {
          data: {
            code: voucherPayload.code
          }
        })
      } catch(err) {
        commit('updateError', err)
      } finally {
        commit('updateBusy', false)
      }
    },
    fetchOrganisations: async ({ commit }) => {
      try {
        commit('updateError', null)
        commit('updateBusy', true)
        const { data } = await apiClient.get(`/v1.11/organisations`)
        commit('updateOrganisations', data.results)
      } catch(err) {
        commit('updateError', err)
      } finally {
        commit('updateBusy', false)
      }
    },
    fetchVoucherTypes: async ({ commit }) => {
      try {
        commit('updateError', null)
        commit('updateBusy', true)
        const { data } = await apiClient.get(`/v1.11/voucher/types`)
        commit('updateVoucherTypes', data.voucher_types)
      } catch(err) {
        commit('updateError', err)
      } finally {
        commit('updateBusy', false)
      }
    },

  },
  getters: {
    isBusy: state => state.busy,
    vouchers: state => state.vouchers,
    connectCodes: state => state.connectCodes,
    error: state => state.error,
    organisations: state => state.organisations.sort((a, b) => a.name.localeCompare(b.name)),
    voucherTypes: state => state.voucherTypes,
  },
}