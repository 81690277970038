import { apiClient } from "@/services/api";
import { ActionTree, GetterTree, MutationTree } from "vuex";

type AssessmentType = string;

type ConfigurationItem<T> = {
  value: T;
  active: boolean;
};

type User = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};

type RetakeRestriction = {
  amount: Number;
  duration: Number;
  time_unit: "days" | "hours";
};

type RetakeConfiguration = Record<
  AssessmentType,
  {
    restrictions: Array<RetakeRestriction>;
  }
>;

type Retake = ConfigurationItem<RetakeConfiguration>;

interface UserMetadataResponse {
  configurations?: {
    retake?: RetakeConfiguration;
  };
  assessment_types: Array<AssessmentType>;
  user: User;
  default_configurations: {
    retake: RetakeConfiguration;
  };
}

interface UserConfigurationsState {
  userId?: string;
  configurations?: {
    retake?: Retake;
  };
  busy?: boolean;
  error?: Error;
  assessment_types?: Array<AssessmentType>;
  user?: User;
  default_configurations?: {
    retake: RetakeConfiguration;
  };
}

enum UserConfigurationsMutationTypes {
  SET_USER_ID = "SET_USER_ID",
  SET_CONFIGURATIONS = "SET_CONFIGURATIONS",
  SET_BUSY = "SET_BUSY",
  SET_ERROR = "SET_ERROR",
  SET_RETAKE = "SET_RETAKE",
  SET_ASSESSMENT_TYPES = "SET_ASSESSMENT_TYPES",
  SET_USER = "SET_USER",
  SET_DEFAULT_CONFIGURATIONS = "SET_DEFAULT_CONFIGURATIONS"
}

const state: UserConfigurationsState = {
  userId: undefined,
  configurations: undefined,
  busy: false,
  error: undefined,
  assessment_types: undefined,
  user: undefined,
  default_configurations: undefined
};

const getters: GetterTree<UserConfigurationsState, unknown> = {
  user: state => state.user ?? null,
  assessmentTypes: state => state.assessment_types ?? [],
  defaultRetakeConfigurations: state =>
    state.default_configurations?.retake ?? {},
  retakeConfigurations: state => state.configurations?.retake?.value ?? null,
  isRetakeActive: state => state.configurations?.retake?.active ?? false,
  isBusy: state => state.busy ?? false
};

const mutations: MutationTree<UserConfigurationsState> = {
  [UserConfigurationsMutationTypes.SET_USER_ID](state, userId: string) {
    state.userId = userId;
  },
  [UserConfigurationsMutationTypes.SET_CONFIGURATIONS](
    state,
    configurations: UserConfigurationsState["configurations"]
  ) {
    state.configurations = configurations;
  },
  [UserConfigurationsMutationTypes.SET_BUSY](state, busy?: boolean) {
    state.busy = busy;
  },
  [UserConfigurationsMutationTypes.SET_ERROR](state, error?: Error) {
    state.error = error;
  },
  [UserConfigurationsMutationTypes.SET_RETAKE](state, retake: Retake) {
    state.configurations = {
      ...state.configurations,
      retake
    };
  },
  [UserConfigurationsMutationTypes.SET_ASSESSMENT_TYPES](
    state,
    assessment_types: Array<AssessmentType>
  ) {
    state.assessment_types = assessment_types;
  },
  [UserConfigurationsMutationTypes.SET_USER](state, user: User) {
    state.user = user;
  },
  [UserConfigurationsMutationTypes.SET_DEFAULT_CONFIGURATIONS](
    state,
    default_configurations: UserConfigurationsState["default_configurations"]
  ) {
    state.default_configurations = default_configurations;
  }
};

const actions: ActionTree<UserConfigurationsState, unknown> = {
  fetchConfigurationsForUser: async ({ commit }, { userId }) => {
    try {
      commit(UserConfigurationsMutationTypes.SET_USER_ID, userId);
      commit(UserConfigurationsMutationTypes.SET_BUSY, true);
      const {
        data: { assessment_types, user, configurations, default_configurations }
      } = await apiClient.get<UserMetadataResponse>("/v1.11/user-metadata", {
        params: {
          user_id: userId
        }
      });
      commit(UserConfigurationsMutationTypes.SET_USER, user);
      commit(
        UserConfigurationsMutationTypes.SET_CONFIGURATIONS,
        configurations
      );
      commit(
        UserConfigurationsMutationTypes.SET_ASSESSMENT_TYPES,
        assessment_types
      );
      commit(
        UserConfigurationsMutationTypes.SET_DEFAULT_CONFIGURATIONS,
        default_configurations
      );
    } catch (err) {
      commit(UserConfigurationsMutationTypes.SET_ERROR, err);
    } finally {
      commit(UserConfigurationsMutationTypes.SET_BUSY, false);
    }
  },
  updateRetakeConfigurations: async (
    { commit, state },
    retakeValue: Retake["value"]
  ) => {
    try {
      commit(UserConfigurationsMutationTypes.SET_RETAKE, {
        value: retakeValue,
        active: state?.configurations?.retake?.active ?? false
      });
    } catch (err) {
      // commit(UserConfigurationsMutationTypes.SET_ERROR, err);
    } finally {
      // commit(UserConfigurationsMutationTypes.SET_BUSY, false);
    }
  },
  updateRetakeActive: async ({ commit, state }, retakeActive: boolean) => {
    try {
      commit(UserConfigurationsMutationTypes.SET_RETAKE, {
        value: state?.configurations?.retake?.value ?? {},
        active: retakeActive
      });
    } catch (err) {
      // commit(UserConfigurationsMutationTypes.SET_ERROR, err);
    } finally {
      // commit(UserConfigurationsMutationTypes.SET_BUSY, false);
    }
  },
  saveChanges: async ({ commit, state, dispatch, rootGetters }) => {
    try {
      commit(UserConfigurationsMutationTypes.SET_BUSY, true);
      const staffUserId = rootGetters["account/user"].user_id;
      await apiClient.post(
        "/v1.11/user-metadata",
        {
          user_id: state.userId,
          configurations: state.configurations
        },
        {
          params: {
            staff_user_id: staffUserId
          }
        }
      );
      dispatch(
        "snackbar/snack",
        {
          mode: "success",
          message: `✅ Changes saved successfully!`
        },
        { root: true }
      );
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore:next-line
      const { message } = error.response?.data || error;
      commit(UserConfigurationsMutationTypes.SET_ERROR, error);
      dispatch(
        "snackbar/snack",
        {
          mode: "error",
          message: `⚠️ Error: <strong class="px-4">${message}</strong>`
        },
        { root: true }
      );
    } finally {
      commit(UserConfigurationsMutationTypes.SET_BUSY, false);
    }
  },
  resetUserConfiguration: async ({ commit }) => {
    commit(UserConfigurationsMutationTypes.SET_USER_ID, undefined);
    commit(UserConfigurationsMutationTypes.SET_CONFIGURATIONS, undefined);
    commit(UserConfigurationsMutationTypes.SET_BUSY, false);
    commit(UserConfigurationsMutationTypes.SET_ERROR, undefined);
    commit(UserConfigurationsMutationTypes.SET_ASSESSMENT_TYPES, undefined);
    commit(UserConfigurationsMutationTypes.SET_USER, undefined);
    commit(
      UserConfigurationsMutationTypes.SET_DEFAULT_CONFIGURATIONS,
      undefined
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
