import { CameraPreview, CameraCapture, CameraImage } from "@es/domain/lib/models/CameraService";
import NativeCameraService from "@es/domain/lib/services/NativeCameraService";
import { singleton } from "tsyringe";
import { callbackFunction, callbackFunctionNoTimeout } from "..";

@singleton()
export default class AndroidCameraService implements NativeCameraService {

  startCameraPreview(cameraPreview: CameraPreview): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'cameraPreview',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }),
        params: JSON.stringify(cameraPreview)
      }))
    })
  }

  stopCameraPreview(): Promise<void> {
    return new Promise((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'cameraPreview',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }),
        params: JSON.stringify({
          enable: false,
          width: 0,
          height: 0
        })
      }))
    })
  }

  captureImage(cameraCapture: CameraCapture): Promise<CameraImage> {
    return new Promise((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'captureImage',
        callback: callbackFunction((err, result) => {
          if (err) {
            return reject(err)
          }
          resolve(result)
        }),
        params: JSON.stringify({
          ...cameraCapture,
          onImageReadyCallback: callbackFunctionNoTimeout((err?: any, data?: any) => {
            if (err) {
              cameraCapture.onImageReady(err);
              return;
            }
            cameraCapture.onImageReady(undefined, JSON.parse(data) as CameraImage)
          })
        })
      }))
    })
  }

}