import { singleton } from "tsyringe";
import CloudStorage from "@es/domain/lib/services/CloudStorage";
import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'

declare let Buffer: any;

export interface AWSCredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
}

@singleton()
export default class AWSCloudStorage implements CloudStorage {

  constructor() {
    AWS.config.region = 'eu-west-2'
  }
  
  async upload(filename: string, base64Data: string, destination: string, credentials: AWSCredentials, contentType: string): Promise<string> {
    AWS.config.credentials = new AWS.Credentials({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    })
    // console.log('base64Data', base64Data)
    const s3Instance = new S3()
    const buffer = Buffer.from(base64Data, 'base64')
    const response = await s3Instance.upload({
      Bucket: destination,
      Key: filename,
      Body: buffer,
      ContentEncoding: 'base64',
      ContentType: contentType
    }).promise()

    // console.log('response', response)

    return response.Key
  }
}