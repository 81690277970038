import { apiClient } from '@/services/api'
import toDo from '@/store/items/to-do'

const headers = [
  { text: 'Bucket', value: 'bucket' },
  { text: 'Name', value: 'name' },
  { text: 'Skill', value: 'skill' },
  { text: 'CEFR', value: 'level' },
  { text: 'Domain', value: 'domain' },
  { text: 'Template', value: 'templates' },
  { text: '', value: 'data-table-expand' },
]

const { state } = toDo
const itemTemplates = state.items.filter(({ skill }) => skill.toLowerCase() === 'writing')

const loadAssessmentItems = async ({ state }) => {
  state.busy = true
  const params = {
    skill: "writing",
    active: true,
  }
  const { data: { results }} = await apiClient.get(`/v1.11/item`, { params })
  const { DICTATION, DESCRIBE_THE_IMAGE, ANSWER_THE_QUESTION, HAVE_A_CHAT } = results[0]
  const writingAssessmentItems = [
    ...DICTATION,
    ...DESCRIBE_THE_IMAGE,
    ...ANSWER_THE_QUESTION,
    ...HAVE_A_CHAT,
  ]

  // Distribute the results into the items array
  for (const itemTemplate of itemTemplates) {
    const assessmentItems = writingAssessmentItems.filter(item => {
      const template = itemTemplate.templates[0].toUpperCase().replace(/\s/g, '_')
      if (Number(item.bucket) !== Number(itemTemplate.bucket)) return false
      if (item.template_type !== template) return false
      if (item.domain !== itemTemplate.domain.toLowerCase()) return false
      return true
    }).sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore:next-line
    itemTemplate.assessmentItems = assessmentItems
  }
  state.busy = false
}

const getAssessmentInstanceIds = async (dispatch) => {
  try {
    // Get the assessment_type_id for each "Writing" difficulty level
    const { data: { assessment_types } } = await apiClient.get('/v1.11/assessment/types')
    const easy = assessment_types.find(({ name }) => name === 'writingv1_easy').id
    const medium = assessment_types.find(({ name }) => name === 'writingv1_medium').id
    const hard = assessment_types.find(({ name }) => name === 'writingv1_hard').id
    return { easy, medium, hard }
  }
  catch (error) {
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Sorry, something went wrong when adding new Assessment Instance:</strong><center>Could not load assessment_type_id values.</center>`,
    }, { root: true })
    return false
  }
}

const saveAssessmentInstance = async ({ dispatch, state }, { difficulty, selectedAssessmentItems }) => {
  state.busy = true
  const assessmentInstanceIds = await getAssessmentInstanceIds(dispatch)
  if (!assessmentInstanceIds) {
    state.busy = false
    return false
  }

  const sectionCodes = {
    dictation: '1_D',
    answer_the_question: '2_AQ',
    describe_the_image: '3_DI',
    have_a_chat: '4_HC',
  }

  const payload = {
    assessment_type_id: assessmentInstanceIds[difficulty],
    instance_items: selectedAssessmentItems.map((item, sequence) => ({
      sequence,
      item_id: item.item_id,
      section: sectionCodes[item.template_type.toLowerCase()],
    }))
  }

  try {
    await apiClient.post(`/v1.11/assessment/instance`, payload)
    dispatch('snackbar/snack', {
      mode: 'success',
      message: `✅ Added new Assessment Instance. <a href="/assessment-instances">View the list of all Assessment Instances here</a>`,
    }, { root: true })
    state.busy = false
    return true
  }
  catch (error) {
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Sorry, something went wrong.</strong> The new Assessment Instance was not saved.`,
    }, { root: true })
    state.busy = false
    return false
  }
}

export default {
  namespaced: true,
  state: {
    busy: false,
    headers,
    items: itemTemplates,
  },
  actions: {
    loadAssessmentItems,
    saveAssessmentInstance,
  }
}
