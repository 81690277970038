<template>
  <div class="es-rating-v2" :class="{ 'disabled': disabled }">
    <div class="rating-wrapper">
      <h3>{{ title }}</h3>
      <div class="rating">
        <div class="star-response emoji">
          <p :style="starMessageStyle">{{ starMessage }}</p>
        </div>
        <div class="stars">
          <star-rating
          :read-only="disabled"
          @rating-selected="emitResponse"
          :show-rating="false"
          v-model="ratingResponse.easy_to_understand"
          :border-width="2"
          border-color="#e1e0e0"
          :active-border-color="starColor"
          :active-color="starColor"
          inactive-color="#f4f4f4"
          :max-rating="5" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-rating-v2',
  props: {
    title: {
      type: String,
      required: false,
      default: 'How easy are they to understand?'
    },
    isLR: {
      type: Boolean,
      required: false,
      default: false
    },
    ratingText: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    ratingResponse: {
      easy_to_understand: 0
    }
  }),
  computed: {
    starColor() {
      // switch(this.ratingResponse.easy_to_understand) {
      //   case 5:
      //     return '#5deb4c'
      //   case 4:
      //     return '#b2eb4c'
      //   case 3:
      //     return '#ffe300'
      //   case 2:
      //     return '#ff4635'
      //   case 1:
      //     return '#ab0033'
      // }
      return '#ffe300'
    },
    starMessage() {
      return this.ratingText[this.ratingResponse.easy_to_understand - 1] || '󠀠';
    },
    starMessageStyle() {
     return {
      opacity : this.ratingResponse.easy_to_understand === 0 ? 0 : 1
     }
    }
  },
  methods: {
    emitResponse() {
      this.$emit('input', this.ratingResponse)
    }
  },
  beforeMount() {
    if (this.value !== null) {
      this.ratingResponse = {
        ...this.value
      }
    }
  }
}
</script>