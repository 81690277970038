import firebase from "firebase/app";
import "firebase/remote-config";

const getImageSize = url =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height
      });
    };
    img.onerror = err => {
      reject(err);
    };
    img.src = url;
  });

const getProctoringStatusRemoteConfig = async () => {
  try {
    const remoteConfig = firebase.remoteConfig();
    // remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    remoteConfig.settings.minimumFetchIntervalMillis = 1000; // for testing
    await remoteConfig.ensureInitialized();
    await remoteConfig.fetchAndActivate();
    try {
      const proctoringReasons = JSON.parse(
        remoteConfig.getString(process.env.VUE_APP_PROCTORING_REASON_RC_KEY!!)
      );
      return proctoringReasons;
    } catch (error) {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export { getImageSize, getProctoringStatusRemoteConfig };
