import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { apiClient } from '@/services/api';
import axios from 'axios';
import ValidateUUID  from 'uuid-validate';

export interface UsersState {
  users: unknown[];
  busy: boolean;
  pagination: {
    perPage: number;
    offset: number;
  };
  searchKeyword?: string;
  error?: Error;
}

export enum UsersMutations {
  SET_BUSY = 'SET_BUSY',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD',
  SET_USERS = 'SET_USERS',
  SET_ERROR = 'SET_ERROR',
}

const initialState: UsersState = {
  busy: false,
  pagination: {
    perPage: 100,
    offset: 0,
  },
  users: [],
  searchKeyword: undefined,
}

const state: UsersState = {
  ...initialState,
}

const mutations: MutationTree<UsersState> = {
  [UsersMutations.SET_BUSY](state, busy: boolean) {
    state.busy = busy
  },
  [UsersMutations.SET_PAGINATION](state, pagination: { perPage: number; offset: number }) {
    state.pagination = pagination
  },
  [UsersMutations.SET_SEARCH_KEYWORD](state, searchKeyword: string | undefined) {
    state.searchKeyword = searchKeyword
  },
  [UsersMutations.SET_USERS](state, users: unknown[]) {
    state.users = users
  },
  [UsersMutations.SET_ERROR](state, error: Error | undefined) {
    state.error = error
  }
}

const actions: ActionTree<UsersState, any> = {
  fetchUsers: async ({ state, commit, rootState }) => {
    try {
      commit(UsersMutations.SET_BUSY, true)
      commit(UsersMutations.SET_ERROR, undefined)
      const params = {
        user_id: rootState.account.user.user_id,
        page_size: state.pagination.perPage,
        offset: state.pagination.offset
      }
      if (state.searchKeyword && state.searchKeyword.length > 3) {
        params[ ValidateUUID(state.searchKeyword, 4) ? 'es_user_id' :  'email'] = state.searchKeyword
      }
      const { data: { users, page_size, offset } } = await apiClient.get('/v1.13/users', {
        params
      })
      commit(UsersMutations.SET_USERS, users)
      commit(UsersMutations.SET_PAGINATION, { perPage: page_size, offset })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.response?.data.message && commit(UsersMutations.SET_ERROR, new Error(err.response.data.message))
      } else {
        commit(UsersMutations.SET_ERROR, err)
      }
    } finally {
      commit(UsersMutations.SET_BUSY, false)
    }
  }
}

const getters: GetterTree<UsersState, unknown> = {
  users: (state) => state.users,
  isBusy: (state) => state.busy,
  error: (state) => state.error,
  pagination: (state) => state.pagination,
  searchKeyword: (state) => state.searchKeyword,
  headers: () => {
    return [{
      text: 'User Id',
      value: 'user_id'
    }, {
      text: 'First Name',
      value: 'first_name'
    }, {
      text: 'Last Name',
      value: 'last_name'
    }, {
      text: 'Email Address',
      value: 'email'
    }, {
      text: '',
      value: 'actions'
    }]
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}