export default {
  namespaced: true,
  state: {
    products: [
      {
        "unique_type": "englishscore_certificate",
        "product_manifest": [
          {
            "id": "1",
            "product_name": "tier1",
            "product_image_url": "bucket/image.png",
            "product_title": "Standard",
            "product_detail": "Global CEFR level\nDigital PDF certificate",
            "product_CTA": "Buy certificate",
            "currencies": [
              {
                "id": "01",
                "country_codes": [
                  "IN"
                ],
                "billing_price": "39900",
                "billing_currency": "inr",
                "allowed_payment_methods": [
                  "0003",
                  "0004",
                  "0005",
                  "0006"
                ],
                "currency_display_symbol": "₹",
                "display_value": "399",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_ind",
                "is_default": false
              },
              {
                "id": "02",
                "country_codes": [
                  "IT",
                  "ES",
                  "FR",
                  "AT",
                  "BE",
                  "CY",
                  "EE",
                  "FI",
                  "DE",
                  "GR",
                  "IE",
                  "HR",
                  "LV",
                  "LT",
                  "LU",
                  "MT",
                  "NL",
                  "PT",
                  "SK",
                  "SI"
                ],
                "billing_price": "499",
                "billing_currency": "eur",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "€",
                "display_value": "4.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_eur",
                "is_default": false
              },
              {
                "id": "03",
                "country_codes": [
                  "GB"
                ],
                "billing_price": "399",
                "billing_currency": "gbp",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "£",
                "display_value": "3.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_gbp",
                "is_default": false
              },
              {
                "id": "04",
                "country_codes": [
                ],
                "billing_price": "499",
                "billing_currency": "usd",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "$",
                "display_value": "4.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_usd",
                "is_default": true
              },
              {
                "id": "05",
                "country_codes": [
                  "UA"
                ],
                "billing_price": "1790",
                "billing_currency": "aed",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "Dh",
                "display_value": "17.90",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_aed",
                "is_default": false
              },
              {
                "id": "06",
                "country_codes": [
                  "BR"
                ],
                "billing_price": "2590",
                "billing_currency": "brl",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "R$",
                "display_value": "25.90",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_brl",
                "is_default": false
              },
              {
                "id": "07",
                "country_codes": [
                  "EG"
                ],
                "billing_price": "7900",
                "billing_currency": "egp",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "E£",
                "display_value": "79.00",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_egp",
                "is_default": false
              },
              {
                "id": "08",
                "country_codes": [
                  "ID"
                ],
                "billing_price": "7900000",
                "billing_currency": "idr",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "Rp",
                "display_value": "79,000",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_idr",
                "is_default": false
              },
              {
                "id": "09",
                "country_codes": [
                  "TR"
                ],
                "billing_price": "3390",
                "billing_currency": "try",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "TL",
                "display_value": "33.90",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_try",
                "is_default": false
              },
              {
                "id": "10",
                "country_codes": [
                  "UA"
                ],
                "billing_price": "13900",
                "billing_currency": "uah",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "₴",
                "display_value": "139",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_uah",
                "is_default": false
              }
            ]
          },
          {
            "id": "2",
            "product_name": "tier2",
            "product_image_url": "bucket/image.png",
            "product_title": "Certified",
            "product_detail": "Global CEFR level\nDigital PDF certificate\nPhoto identification\nEmployer validation link\nScore on all 4 sections",
            "product_CTA": "Buy certificate",
            "currencies": [
              {
                "id": "01",
                "country_codes": [
                  "IN"
                ],
                "billing_price": "69900",
                "billing_currency": "inr",
                "allowed_payment_methods": [
                  "0003",
                  "0004",
                  "0005",
                  "0006"
                ],
                "currency_display_symbol": "₹",
                "display_value": "699",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_ind",
                "is_default": false
              },
              {
                "id": "02",
                "country_codes": [
                  "IT",
                  "ES",
                  "FR",
                  "AT",
                  "BE",
                  "CY",
                  "EE",
                  "FI",
                  "DE",
                  "GR",
                  "IE",
                  "HR",
                  "LV",
                  "LT",
                  "LU",
                  "MT",
                  "NL",
                  "PT",
                  "SK",
                  "SI"
                ],
                "billing_price": "999",
                "billing_currency": "eur",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "€",
                "display_value": "9.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_eur",
                "is_default": false
              },
              {
                "id": "03",
                "country_codes": [
                  "GB"
                ],
                "billing_price": "899",
                "billing_currency": "gbp",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "£",
                "display_value": "8.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_gbp",
                "is_default": false
              },
              {
                "id": "04",
                "country_codes": [
                ],
                "billing_price": "999",
                "billing_currency": "usd",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "$",
                "display_value": "9.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_usd",
                "is_default": true
              },
              {
                "id": "05",
                "country_codes": [
                  "UA"
                ],
                "billing_price": "3690",
                "billing_currency": "aed",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "Dh",
                "display_value": "36.90",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_aed",
                "is_default": false
              },
              {
                "id": "06",
                "country_codes": [
                  "BR"
                ],
                "billing_price": "5190",
                "billing_currency": "brl",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "R$",
                "display_value": "51.90",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_brl",
                "is_default": false
              },
              {
                "id": "07",
                "country_codes": [
                  "EG"
                ],
                "billing_price": "15900",
                "billing_currency": "egp",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "E£",
                "display_value": "159.00",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_egp",
                "is_default": false
              },
              {
                "id": "08",
                "country_codes": [
                  "ID"
                ],
                "billing_price": "15900000",
                "billing_currency": "idr",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "Rp",
                "display_value": "159,000",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_idr",
                "is_default": false
              },
              {
                "id": "09",
                "country_codes": [
                  "TR"
                ],
                "billing_price": "6790",
                "billing_currency": "try",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "TL",
                "display_value": "67.90",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_try",
                "is_default": false
              },
              {
                "id": "10",
                "country_codes": [
                  "UA"
                ],
                "billing_price": "26900",
                "billing_currency": "uah",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "₴",
                "display_value": "269",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_uah",
                "is_default": false
              }
            ]
          },
          {
            "id": "3",
            "product_name": "tier3",
            "product_image_url": "bucket/image.png",
            "product_title": "Delivered",
            "product_detail": "Certified version printed, framed, and delivered to your address.",
            "product_CTA": "Buy certificate",
            "currencies": [
              {
                "id": "01",
                "country_codes": [
                  "IN"
                ],
                "billing_price": "159900",
                "billing_currency": "inr",
                "allowed_payment_methods": [
                  "0003",
                  "0004",
                  "0005",
                  "0006"
                ],
                "currency_display_symbol": "₹",
                "display_value": "1599",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_ind",
                "is_default": false
              },
              {
                "id": "02",
                "country_codes": [
                  "IT",
                  "ES",
                  "FR",
                  "AT",
                  "BE",
                  "CY",
                  "EE",
                  "FI",
                  "DE",
                  "GR",
                  "IE",
                  "HR",
                  "LV",
                  "LT",
                  "LU",
                  "MT",
                  "NL",
                  "PT",
                  "SK",
                  "SI"
                ],
                "billing_price": "249900",
                "billing_currency": "eur",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "€",
                "display_value": "24.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_eur",
                "is_default": false
              },
              {
                "id": "03",
                "country_codes": [
                  "GB"
                ],
                "billing_price": "219900",
                "billing_currency": "gbp",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "£",
                "display_value": "21.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_gbp",
                "is_default": false
              },
              {
                "id": "04",
                "country_codes": [
                ],
                "billing_price": "249900",
                "billing_currency": "usd",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "$",
                "display_value": "24.99",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_usd",
                "is_default": true
              },
              {
                "id": "05",
                "country_codes": [
                  "UA"
                ],
                "billing_price": "7290",
                "billing_currency": "aed",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "Dh",
                "display_value": "72.90",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_aed",
                "is_default": false
              },
              {
                "id": "06",
                "country_codes": [
                  "BR"
                ],
                "billing_price": "10390",
                "billing_currency": "brl",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "R$",
                "display_value": "103.90",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_brl",
                "is_default": false
              },
              {
                "id": "07",
                "country_codes": [
                  "EG"
                ],
                "billing_price": "30900",
                "billing_currency": "egp",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "E£",
                "display_value": "309.00",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_egp",
                "is_default": false
              },
              {
                "id": "08",
                "country_codes": [
                  "ID"
                ],
                "billing_price": "31900000",
                "billing_currency": "idr",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "Rp",
                "display_value": "319,000",
                "display_formatting_template": "\u003cCURRENCY\u003e \u003cVALUE\u003e",
                "product_name_appendage": "_idr",
                "is_default": false
              },
              {
                "id": "09",
                "country_codes": [
                  "TR"
                ],
                "billing_price": "13490",
                "billing_currency": "try",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "TL",
                "display_value": "134.90",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_try",
                "is_default": false
              },
              {
                "id": "10",
                "country_codes": [
                  "UA"
                ],
                "billing_price": "54900",
                "billing_currency": "uah",
                "allowed_payment_methods": [
                  "0003"
                ],
                "currency_display_symbol": "₴",
                "display_value": "549",
                "display_formatting_template": "\u003cVALUE\u003e \u003cCURRENCY\u003e",
                "product_name_appendage": "_uah",
                "is_default": false
              }
            ]
          }
        ]
      }
    ]
  },
  getters: {
    products: state => state.products
  }
}