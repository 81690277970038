<template>
  <div class="es-tooltip" :class="[tooltipPosition, color]">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'es-tooltip',
  props: ['color', 'tooltipPosition']
}
</script>