<template>
  <h1>
    <slot></slot>
  </h1>
</template>
<script>
export default {
  name: 'es-h1'
}
</script>
<style lang="scss" scoped>
h1 {
  color: #220a5a;
}
</style>