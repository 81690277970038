import { apiClient } from "@/services/api"

export default {
  namespaced: true,
  state: {
    products: [],
    certBusy: false,
    preview: null
  },
  mutations: {
    setProducts: (state, products) => {
      state.products = products
    },
    setCertBusy(state, certBusy) {
      state.certBusy = certBusy
    },
    setPreview(state, preview) {
      state.preview = preview
    }
  },
  actions: {
    fetchProducts: async ({ commit }) => {
      try {
        const { data } = await apiClient.get('/v1.11/products')
        const { product_manifest : products } = data.all_products[0]
        commit('setProducts', products)
      } catch (err) {
        console.error(err)
      }
    },
    requestPreview: async ({ commit }, payload) => {
      try {
        commit('setCertBusy', true)
        const formData = new FormData()
        Object.keys(payload).forEach(key => {
          formData.append(key, payload[key])
        })
        const { data } = await apiClient.post('/v1.11/certificate', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType:  payload.is_preview ? 'blob' : 'json'
        })
        if (payload.is_preview) {
            const blobUrl = window.URL.createObjectURL(data)
            const link = document.createElement('a')
                  link.href = blobUrl
                  link.setAttribute('download', 'pdf-preview.pdf')
                  link.click();
                  link.remove();
            URL.revokeObjectURL(blobUrl);
            // console.log('data', data);
            // console.log(Buffer.from(data).toString('base64'));
            // const base64 = Buffer.from(data, 'binary').toString('base64')
            // if (payload.cert_format === 'png') {
              // commit('setPreview', `data:image/png;base64,${base64}`)
            // } else if  (payload.cert_format === 'pdf') {
              commit('setPreview', blobUrl)
            // }
        } else {
          commit('setPreview', null)
        }
      } catch(err) {
        commit('setPreview', null)
        if (err.response) {
          const parsedData =  err.response.data instanceof Blob ? JSON.parse(err.response.data.text()) : err.response.data;
          switch(err.response.status) {
            case 400:
              throw new Error(parsedData.message)
            case 404:
              throw new Error(parsedData.message)
            case 401:
              throw new Error(parsedData.message)
            case 500:
            default:
              throw new Error('Something went wrong, Please try again.')
          }
        } else {
          throw new Error('Something went wrong, Please try again.')
        }
      } finally {
        commit('setCertBusy', false)
      }
    },
    cancelPreview: ({ commit }) => {
      commit('setPreview', null)
    },
  },
  getters: {
    products: state => state.products.map(product => ({
      id: product.id,
      name: `${product.product_title} (${product.product_name})`
    })),
    isCertBusy: state => state.certBusy,
    preview: state => state.preview
  }
}
