<template>
  <div class="es-modal">
    <div class="modal">
      <div v-es-on:click="closeModal" class="modal-backdrop"></div>
      <div class="modal-wrapper">
        <div v-if="showIcon" class="exclaim">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g transform="translate(-156 -217) translate(20 157) translate(136 60)">
                            <circle cx="50" cy="50" r="50" fill="#AB0033"/>
                            <g fill="#FFF" transform="translate(44 17)">
                                <circle cx="6" cy="60" r="6"/>
                                <rect width="12" height="47" rx="6"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </div>
        <div>
          <slot name="title"></slot>
        </div>
        <div>
          <slot name="content"></slot>
        </div>
        <div class="actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-modal',
  props: {
    showIcon: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>