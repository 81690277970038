/**
 * This plugin can be used to register filters.
 * As new filters are created, register them here.
 */
import dateString from "./date-string";
import status from "./status";

export default {
  install: Vue => {
    Vue.filter("dateString", dateString);
    Vue.filter("status", status);
  }
};
