import Vue from "vue";
import Vuex from "vuex";

import account from "./account";
import browse from "./browse";
import product from "./product";
import leads from "./leads";
import country from "./country";
import ui from "./ui";
import snackbar from "./snackbar";
import proctoringOldversion from "./proctoring-oldversion";
import marking from "./marking";
import vouchers from "./vouchers";
import item from "./items/item";
import list from "./items/list";
import todo from "./items/to-do";
import gradingList from "./grading/grading-list";
import gradeSitting from "./grading/grade-sitting";
import certificate from "./certificate";
import certify from "./certify";
import revokeCertificate from "./revoke-certificate";
import reinstateCertificate from "./reinstate-certificate";
import user from "./user";
import tokens from "./tokens";
import users from "./users";
import sittings from "./sittings";
import assessmentInstances from "./assessment-instances/assessment-instances";
import newAssessmentInstance from "./assessment-instances/new";
import organisation from "./organisation";
import accessManagement from "./access-management";
import proctoringList from "./proctoring/proctoring-list";
import proctorSitting from "./proctoring/proctor-sitting";
import userEvents from "./user-events";
import fluentify from "./fluentify";
import appVersion from "./app-version";
import orgHubSpot from "./org-hubspot";
import userConfigurations from "./user-configurations";

Vue.use(Vuex);

const store = new Vuex.Store({});

store.registerModule("ui", ui);
store.registerModule("snackbar", snackbar);
store.registerModule("account", account);
store.registerModule("browse", browse);
store.registerModule("product", product);
store.registerModule("leads", leads);
store.registerModule("country", country);
store.registerModule("proctoringOldversion", proctoringOldversion);
store.registerModule("marking", marking);
store.registerModule("vouchers", vouchers);
store.registerModule("item", item);
store.registerModule("list", list);
store.registerModule("todo", todo);
store.registerModule("gradingList", gradingList);
store.registerModule("gradeSitting", gradeSitting);
store.registerModule("certificate", certificate);
store.registerModule("certify", certify);
store.registerModule("revokeCertificate", revokeCertificate);
store.registerModule("reinstateCertificate", reinstateCertificate);
store.registerModule("user", user);
store.registerModule("tokens", tokens);
store.registerModule("users", users);
store.registerModule("sittings", sittings);
store.registerModule("assessmentInstances", assessmentInstances);
store.registerModule("newAssessmentInstance", newAssessmentInstance);
store.registerModule("organisation", organisation);
store.registerModule("accessManagement", accessManagement);
store.registerModule("proctoringList", proctoringList);
store.registerModule("proctorSitting", proctorSitting);
store.registerModule("userEvents", userEvents);
store.registerModule("fluentify", fluentify);
store.registerModule("appVersion", appVersion);
store.registerModule("orgHubSpot", orgHubSpot);
store.registerModule("userConfigurations", userConfigurations);

export default store;
