import { injectable } from 'tsyringe'
import { AssessmentType } from '../models/Assessment'
import SpokenUsecase from './../usecases/spoken/SpokenUsecase'

@injectable()
class SpokenInteractor {
  
  constructor (
    private spokenUsecase: SpokenUsecase,
  ) { }

  headerConfiguration = () => this.spokenUsecase.headerConfiguration()
  startRecording = () => this.spokenUsecase.startRecording()
  stopRecording = () => this.spokenUsecase.stopRecording()
  getRecording = (token: string) => this.spokenUsecase.getRecording(token)
  startUploading = (token: string, fileName: string) => this.spokenUsecase.upload(token, fileName)
  startAssessment = (assessmentType: AssessmentType) => this.spokenUsecase.startAssessment(assessmentType)
  getSittingId = () => this.spokenUsecase.getSittingId()
  consumeTestState = () => this.spokenUsecase.consumeTestState()
  currentTestState = () => this.spokenUsecase.currentTestState()
  loadTemplateData = (templateId: string) => this.spokenUsecase.loadTemplateData(templateId)
  loadSectionData = (sectionId: string) => this.spokenUsecase.loadSectionData(sectionId)
  checkPermission = (onPermissionGranted: () => void) => this.spokenUsecase.checkPermission(onPermissionGranted)
  updateHeaderState = () => this.spokenUsecase.updateHeaderState()
  exitTest = (success: boolean) => this.spokenUsecase.exitTest(success)
  requestPermission = () => this.spokenUsecase.requestPermission()
  updateResponse = (questionId: string, uploadUrl: string, skip: boolean)=> this.spokenUsecase.updateResponse(questionId, uploadUrl, skip)
  submitResponses = () => this.spokenUsecase.submitResponses()
  spokenReport = (sitting: string) => this.spokenUsecase.fetchReport(sitting)
  showCameraPreview = (width: number, height: number, cameraCheck: boolean) => this.spokenUsecase.showCameraPreview(width, height, cameraCheck)
  hideCameraPreview = () => this.spokenUsecase.hideCameraPreview()
  getAssessmentType = () => this.spokenUsecase.getAssessmentType()
  playAudio = (token: string, onDone: () => void) => this.spokenUsecase.playAudio(token, onDone)
  pauseAudio = () => this.spokenUsecase.pauseAudio()
}

export default SpokenInteractor