<template>
  <div class="report-item">
    <div class="title">
      <h3>{{ reportTitle }}</h3>
      <div v-es-on:click="toggleBlindMode" v-if="reportType === 'ra'">
        <div v-if="blindMode">
          <img src="./../../assets/images/colourblind-icon-and-btn-selected.svg">
        </div>
        <div v-if="!blindMode">
          <img src="./../../assets/images/colourblind-icon-and-btn-unselected.svg">
        </div>
      </div>
    </div>
    <div class="report">
      <div v-if="scores" class="over-all-score">
        <p>Overall Score</p>
        <div class="details">
          <template v-for="(score, $index) in scores">
            <es-progress-view
              :key="`epv_${$index}_${score.id}`"
              :progress="score.value"
              :title="`${score.text} ${score.value}%`" />
          </template>
        </div>
      </div>
      <div v-if="reportType" class="detail">
        <div v-if="reportType === 'ra'">
          <div :key="`ra_best_${bestPhrase.sequence}`" class="blue-box ra">
            <div class="best-flag">
              <img src="./../../assets/images/star-flag.svg">
            </div>
            <p>Your best phrase</p>
            <es-audio-player
                v-if="bestPhrase.download_url"
                :audio-link="bestPhrase.download_url" 
                :only-player="true"/>
            <div class="content">
              <div v-if="bestPhrase.too_difficult" class="too-difficult">
                <p>🚧 You skipped this one</p>
              </div>
              <div v-if="bestPhrase.issues" class="issue">
                <p>❌ There was a problem:</p>
                <p>{{ maskIssuesText(bestPhrase.issues) }}</p>
              </div>
              <div v-if="!bestPhrase.too_difficult" class="phrase-progress">
                <es-progress-view
                  :key="`ra_phr_ppr_${bestPhrase.sequence}`"
                  :progress="Number(Math.round(bestPhrase.pronunciation * 100))"
                  :title="`PRONUNCIATION ${Number(Math.round(bestPhrase.pronunciation * 100))}%`" />
                <es-progress-view
                :key="`ra_phr_upr_${bestPhrase.sequence}`"
                  :progress="Number(Math.round(bestPhrase.understandability * 100))"
                  :title="`UNDERSTANDABILITY ${Number(Math.round(bestPhrase.understandability * 100))}%`" />
              </div>
            </div>
            <div class="content mt-5">
              <ul>
                <li v-for="(wordItem, $index) in sortBySeq(bestPhrase.phrase)" :key="`wrd_${bestPhrase.sequence}_${$index}`">
                  <span :class="[wordItem.status, { 'blind': blindMode }]">{{ wordItem.word }}</span>
                </li>
              </ul>
            </div>
            <div class="content mt-5">
              <ul class="example">
                <li v-for="(wordItem, $index) in exmapleWord" :key="`wrd_exp_${bestPhrase.sequence}_${$index}`">
                  <span :class="[wordItem.status, { 'blind': blindMode }]">{{ wordItem.word }}</span>
                </li>
              </ul>
            </div>
          </div>
          <template v-for="phrase in reportPhrases">
            <div v-show="seeAllPhrases" :key="`ra_phr_${phrase.sequence}`" class="blue-box ra">
              <div v-if="phrase.best_phrase" class="best-flag">
                <img src="./../../assets/images/star-flag-2.svg">
              </div>
                <p>{{ `Phrase ${phrase.sequence + 1}` }}</p>
                <es-audio-player
                  v-if="phrase.download_url"
                  :audio-link="phrase.download_url" 
                  :only-player="true"/>
              <div class="content">
              <div v-if="phrase.too_difficult" class="too-difficult">
                <p>🚧 You skipped this one</p>
              </div>
              <div v-if="phrase.issues" class="issue">
                <p>❌ There was a problem:</p>
                <p>{{ maskIssuesText(phrase.issues) }}</p>
              </div>
              <div v-if="!phrase.too_difficult && !phrase.issues" class="phrase-progress">
                  <es-progress-view
                    :key="`ra_phr_ppr_${phrase.sequence}`"
                    :progress="Number(Math.round(phrase.pronunciation * 100))"
                    :title="`PRONUNCIATION ${Number(Math.round(phrase.pronunciation * 100))}%`" />
                  <es-progress-view
                  :key="`ra_phr_upr_${phrase.sequence}`"
                    :progress="Number(Math.round(phrase.understandability * 100))"
                    :title="`UNDERSTANDABILITY ${Number(Math.round(phrase.understandability * 100))}%`" />
                </div>
              </div>
              <div class="content mt-5">
                <ul>
                  <li v-for="(wordItem, $index) in sortBySeq(phrase.phrase)" :key="`wrd_${phrase.sequence}_${$index}`">
                    <span :class="[wordItem.status, { 'blind': blindMode }]">{{ wordItem.word }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <div class="best-phrase-toggle">
            <p v-es-on:click="() => seeAllPhrases = !seeAllPhrases">{{ seeAllPhrases ? 'Hide' : 'See all phrases' }}</p>
          </div>
        </div>
        <div v-if="reportType === 'ei'">
          <div :key="`ei_best_${bestPhrase.sequence}`" class="blue-box ei">
            <div class="best-flag">
              <img src="./../../assets/images/star-flag.svg">
            </div>
            <p>Your best phrase</p>
            <es-audio-player
                v-if="bestPhrase.download_url"
                :audio-link="bestPhrase.download_url" 
                :only-player="true"/>
            <div class="content">
              <div v-if="bestPhrase.too_difficult" class="too-difficult">
                <p>🚧 You skipped this one</p>
              </div>
              <div v-if="bestPhrase.issues" class="issue">
                <p>❌ There was a problem:</p>
                <p>{{ maskIssuesText(bestPhrase.issues) }}</p>
              </div>
              <div v-if="!bestPhrase.too_difficult && !bestPhrase.issues" class="phrase-progress">
                <es-progress-view
                  :key="`ei_phr_ppr_${bestPhrase.sequence}`"
                  :progress="Number(Math.round(bestPhrase.pronunciation * 100))"
                  :title="`PRONUNCIATION ${Number(Math.round(bestPhrase.pronunciation * 100))}%`" />
                <es-progress-view
                :key="`ei_phr_upr_${bestPhrase.sequence}`"
                  :progress="Number(Math.round(bestPhrase.understandability * 100))"
                  :title="`UNDERSTANDABILITY ${Number(Math.round(bestPhrase.understandability * 100))}%`" />
              </div>
            </div>
            <div class="content mt-5">
              <ul>
                <li v-for="(wordItem, $index) in sortBySeq(bestPhrase.phrase)" :key="`wrd_${bestPhrase.sequence}_${$index}`">
                  <span :class="[wordItem.status, { 'blind': blindMode }]">{{ wordItem.word }}</span>
                </li>
              </ul>
            </div>
            <div class="content mt-5">
              <ul class="example">
                <li v-for="(wordItem, $index) in exmapleWord" :key="`wrd_exp_${bestPhrase.sequence}_${$index}`">
                  <span :class="[wordItem.status, { 'blind': blindMode }]">{{ wordItem.word }}</span>
                </li>
              </ul>
            </div>
          </div>
          <template v-for="phrase in reportPhrases">
            <div v-show="seeAllPhrases" :key="`ei_phr_${phrase.sequence}`" class="blue-box ei">
              <div v-if="phrase.best_phrase" class="best-flag">
                <img src="./../../assets/images/star-flag-2.svg">
              </div>
              <p>{{ `Phrase ${phrase.sequence + 1}` }}</p>
              <es-audio-player
                v-if="phrase.download_url"
                :audio-link="phrase.download_url" 
                :only-player="true"/>
              <div class="content">
              <div v-if="phrase.too_difficult" class="too-difficult">
                <p>🚧 You skipped this one</p>
              </div>
              <div v-if="phrase.issues" class="issue">
                <p>❌ There was a problem:</p>
                <p>{{ maskIssuesText(phrase.issues) }}</p>
              </div>
              <div v-if="!phrase.too_difficult && !phrase.issues" class="phrase-progress">
                  <es-progress-view
                    :key="`ei_phr_ppr_${phrase.sequence}`"
                    :progress="Number(Math.round(phrase.pronunciation * 100))"
                    :title="`PRONUNCIATION ${Number(Math.round(phrase.pronunciation * 100))}%`" />
                  <es-progress-view
                  :key="`ei_phr_upr_${phrase.sequence}`"
                    :progress="Number(Math.round(phrase.understandability * 100))"
                    :title="`UNDERSTANDABILITY ${Number(Math.round(phrase.understandability * 100))}%`" />
                </div>
              </div>
              <div class="content mt-5">
                <ul>
                  <li v-for="(wordItem, $index) in sortBySeq(phrase.phrase)" :key="`wrd_${phrase.sequence}_${$index}`">
                    <span :class="[wordItem.status, { 'blind': blindMode }]">{{ wordItem.word }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <div class="best-phrase-toggle">
            <p v-es-on:click="() => seeAllPhrases = !seeAllPhrases">{{ seeAllPhrases ? 'Hide' : 'See all phrases' }}</p>
          </div>
        </div>
        <div v-if="reportType === 'ivd'">
          <div class="blue-box ivd">
            <p>What did people hear?</p>
            <es-audio-player
                v-if="audioUrl"
                :audio-link="audioUrl" 
                :only-player="true"/>
            <div class="content">
              <div v-if="hasSkipped" class="too-difficult">
                <p>🚧 You skipped this one</p>
              </div>
              <div v-if="issues" class="issue">
                <p>❌ There was a problem:</p>
                <p>{{ maskIssuesText(issues) }}</p>
              </div>
              <ul v-if="!hasSkipped && !issues">
                <li v-for="wordHeard in sortBySeq(reportWordsHeard)" :key="`wh_${wordHeard.sequence}`">{{ wordHeard.word }}</li>
                <li v-if="reportWordsHeard.length === 0">We could not hear you.</li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="reportType === 'itc'">
          <div class="blue-box itc">
            <p>What did people hear?</p>
            <es-audio-player
                v-if="audioUrl"
                :audio-link="audioUrl" 
                :only-player="true"/>
            <div class="content">
              <div v-if="hasSkipped" class="too-difficult">
                <p>🚧 You skipped this one</p>
              </div>
              <div v-if="issues" class="issue">
                <p>❌ There was a problem:</p>
                <p>{{ maskIssuesText(issues) }}</p>
              </div>
              <ul v-if="!hasSkipped && !issues">
                <li v-for="task in sortBySeq(reportTasks)" :key="`task_${task.sequence}`" :class="{'strike': task.status === 'incorrect' }" >{{ task.task }}</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div v-if="reportType === 'gt'">
          <div class="blue-box gt">
            <p>What say about you?</p>
            <es-audio-player
                v-if="audioUrl"
                :audio-link="audioUrl" 
                :only-player="true"/>
            <div class="content">
              <div v-if="hasSkipped" class="too-difficult">
                <p>🚧 You skipped this one</p>
              </div>
              <div v-if="issues" class="issue">
                <p>❌ There was a problem:</p>
                <p>{{ maskIssuesText(issues) }}</p>
              </div>
              <div>
                <star-rating
                  :show-rating="false"
                  v-model="rating"
                  :border-width="2"
                  border-color="#e1e0e0"
                  inactive-color="#f4f4f4"
                  :max-rating="5" />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-report-item',
  props: {
    reportTitle: {
      type: String,
      required: true,
      default: 'Report'
    },
    scores: {
      type: Array,
      required: false,
      default: null
    },
    reportType: {
      type: String,
      required: true,
      default: null
    },
    reportPhrases: {
      type: Array,
      required: false,
      default: () => []
    },
    reportWordsHeard: {
      type: Array,
      required: false,
      default: () => []
    },
    reportTasks: {
      type: Array,
      required: false,
      default: () => []
    },
    blindMode: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasSkipped: {
      type: Boolean,
      required: false,
      default: () => false
    },
    issues: {
      type: String,
      required: false,
      default: undefined
    },
    audioUrl: {
      type: String,
      required: false,
      default: undefined
    },
    rating: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    seeAllPhrases: false,
    exmapleWord: [{
      sequence: 0,
      status: 'missing',
      word: 'MISSING'
    },{
      sequence: 1,
      status: 'incorrect',
      word: 'INCORRECT'
    },{
      sequence: 2,
      status: 'mispronounced',
      word: 'ALMOST'
    },{
      sequence: 3,
      status: 'correct',
      word: 'GREAT'
    }]
  }),
  computed: {
    bestPhrase() {
      return this.reportPhrases.find(phrase => phrase.best_phrase === true)
    }
  },
  methods: {
    toggleBlindMode() {
      this.$emit('blind-toggle')
    },
    sortBySeq(inputArray) {
      return [...inputArray].sort((item1, item2) => {
        return Number(item1.sequence) - Number(item2.sequence)
      })
    },
    maskIssuesText(issues) {
      switch(issues) {
        case 'It’s too quiet':
        case 'It’s too quiet 🤫':
          return 'It was too quiet'
        case 'It’s too noisy':
        case 'It’s too noisy 🎤':
          return 'There was too much noise'
        case 'They said something else':
        case 'They said something else 😵':
          return 'You said something different'
        case 'Bad language/swearing':
        case 'Bad language/swearing 🤬':
          return 'There was bad language'
        case 'Other':
        case 'Other 🧐':
          return 'This couldn’t be marked'
        default:
          return issues
      }
    }
  }
}
</script>