import { TemplateType } from './../usecases/spoken/SpokenUsecase'
import { Assets } from './AssessmentResponse';

  export interface AtomQuestionOption {
    value: string;
    option_id: string;
}

export interface AtomQuestion {
    atom_question_id: string;
    atom_question: string;
    atom_question_options: AtomQuestionOption[];
}

export interface Atom {
    template: string;
    cefr: string;
    skill: string;
    title: string;
    speaking_text: string;
    atom_url: string;
    atom_questions: AtomQuestion[];
    assets?: Assets;
}

export interface AtomResponse {
    marker_id: string;
    candidate_id: string;
    sitting_id: string;
    response_id: string;
    item_id: string;
    spoken_question_id: string;
    atom_id: string;
    atom_version: string;
    download_url: string;
    atom: Atom;
    speaker_country: string;
}

// export enum MARKING_COMPONENTS {
//     TAP_SELECT = "TAP_SELECT",
//     QUESTION_LIST = "QUESTION_LIST",
//     STAR_RATING = "STAR_RATING"
// }

export enum MarkingComponents {
    TAP_SELECT = "TAP_SELECT",
    QUESTION_LIST = "QUESTION_LIST",
    STAR_RATING = "STAR_RATING",
    QUESTION_TEXT_OR_IMAGE = "QUESTION_TEXT_OR_IMAGE"
}

export interface AtomMarking {
    id: string;
    component: MarkingComponents;
    title: string;
}

export interface TapSelectWord {
    id: string;
    value: string;
    status?: 'correct' | 'incorrect';
}
export interface TapSelectMarkingItem extends AtomMarking {
    words: Omit<TapSelectWord, 'status'>[];
}

export interface QuestionOption {
    id: string;
    value: string;
}
export interface QuestionListQuestions {
    id: string;
    question: string;
    options: QuestionOption[];
}
export interface QuestionListMarkingItem extends AtomMarking {
    questions: QuestionListQuestions[];
    text: string;
}
export interface StarRatingMarkingItem extends AtomMarking {
    showEmoji: boolean;
    ratingText: string[];
}

export interface QuestionTextOrImageMarkingItem extends Omit<AtomMarking, 'title'> {
    title?: string;
    imageUrl?: string;
    options: QuestionOption[];
}

export type MarkingItem = TapSelectMarkingItem | QuestionListMarkingItem | StarRatingMarkingItem | QuestionTextOrImageMarkingItem;

export interface AtomData {
    audioUrl: string;
    id: string;
    itemId: string;
    version: string;
    template: string;
    marking: MarkingItem[];
    speakingText: string;
    userId: string;
    countryCode?: string;
    speakerNumber?: number;
}

interface MarkingResponse {
    id: string;
    wordsStatus: TapSelectWord[];
    starRating: number;
    questionList: Record<string, string>[];
    questionTextOrImage: string;
    component: MarkingComponents;
}

export type TapSelectMarkingResponseItem = Omit<MarkingResponse, 'questionList' | 'starRating' | 'questionTextOrImage'>;
export type QuestionListMarkingResponseItem = Omit<MarkingResponse, 'wordsStatus' | 'starRating' | 'questionTextOrImage'>;
export type StarRatingMarkingResponseItem = Omit<MarkingResponse, 'wordsStatus' | 'questionList' | 'questionTextOrImage'>;
export type QuestionTextOrImageMarkingResponseItem = Omit<MarkingResponse, 'wordsStatus' | 'questionList' | 'starRating'>;

export type MarkingResponseItem = TapSelectMarkingResponseItem | QuestionListMarkingResponseItem | StarRatingMarkingResponseItem;

export interface AtomPhraseOption {
    option_id: string;
    sequence: number;
    status: 'correct' | 'incorrect';
    value: string;
}

export interface AtomMarkingResponse {
    id: string;
    itemId: string;
    version: string;
    atom_id?: string;
    atom_version?: string;
    atom_response: {
        easy_to_understand?: number;
        question_id?: string;
        template?: string;
        phrases?: AtomPhraseOption[];
        words_heard?: AtomPhraseOption[];
        additionalQuestions: MarkingResponseItem[];
    };
    template: string;
}