export default {
  namespaced: true,
  state: {
    show: false,
    color: '',
    message: '',
  },
  mutations: {
    setShow: (state, payload) => { state.show = payload },
    setColor: (state, payload) => { state.color = payload },
    setMessage: (state, payload) => { state.message = payload },
  },
  actions: {
    snack: ({ commit }, { mode, message }) => {
      if (!message) return

      // @todo Figure out how to reset the close-dialog timer
      // @see https://vuetifyjs.com/en/api/v-snackbar/#props
      commit('setMessage', message)
      commit ('setColor', mode && mode === 'error' ? 'error' : 'success')
      commit('setShow', true)
    },
  }
}