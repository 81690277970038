import { apiClient } from "@/services/api";
import { Action } from "vuex";

type ProctoringReasonData = Array<{
  displayName: string;
  action: string;
  btnCss: string;
  reasons: Array<{
    displayName: string;
    key: string;
  }>;
}>;

type ProctorSittingState = {
  busy: boolean;
  showProctoringDialog: boolean;
  selectedItemId: string;
  hasNextItem: boolean;
  proctoringReasons: ProctoringReasonData;
};

type ProctorSittingAction = Action<ProctorSittingState, any>;

// The selectedItem is the sitting that was clicked by the User.
const loadSelectedItem = async (
  { state, rootState, dispatch },
  { selectedItemId }
) => {
  state.busy = true;
  const { items } = rootState.proctoringList;
  const foundItem = items.find(({ order_id }) => order_id === selectedItemId);

  if (!foundItem) {
    console.log("Not found");
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Could not load item: ${selectedItemId}</strong>`
      },
      { root: true }
    );
    state.busy = false;
    return false;
  }

  state.selectedItemId = selectedItemId;
  state.showProctoringDialog = true;

  // Enrich the selected item with Proctoring data.
  await dispatch("proctoringList/enrichItem", foundItem, { root: true });

  // Preload the next selectedItem for when the user clicks "Save and Next"
  const index = items?.findIndex(({ order_id }) => order_id === selectedItemId);
  const nextSelectedItem = items[index + 1] || false;
  if (!nextSelectedItem) {
    state.hasNextItem = false;
    state.busy = false;
    return;
  }
  state.hasNextItem = true;

  // Preload the next item with Proctoring data.
  dispatch("proctoringList/enrichItem", nextSelectedItem, { root: true });
  state.busy = false;
};

const getAction = (proctoringReasons: ProctoringReasonData, status: string) =>
  proctoringReasons.find(({ reasons }) =>
    reasons.find(item => item.key === status)
  )?.action;

const saveProctoring: ProctorSittingAction = async (
  { state, rootState, dispatch },
  { status, loadNext, reset }
) => {
  state.busy = true;
  const { selectedItemId } = state;
  const { items } = rootState.proctoringList;
  try {
    const foundItem = items.find(({ order_id }) => order_id === selectedItemId);
    if (!foundItem) return;

    const { user_id, sitting_id, order_id } = foundItem;
    const payload = {
      user_id,
      sitting_id,
      order_id,
      status,
      action: getAction(state.proctoringReasons, status)
    };

    await apiClient.patch("/v1.11/proctoring", payload);
    dispatch(
      "snackbar/snack",
      {
        mode: "success",
        message: `✅ Saved the proctoring status for the sitting.`
      },
      { root: true }
    );
    state.busy = false;
  } catch (error) {
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Sorry, something went wrong when saving the proctoring status for the sitting.</strong>`
      },
      { root: true }
    );
    state.busy = false;
    return;
  }

  if (!loadNext) {
    dispatch("proctoringList/fetchItems", {}, { root: true });
    state.showProctoringDialog = false;
  } else {
    const index = items?.findIndex(
      ({ order_id }) => order_id === selectedItemId
    );
    const nextSelectedItem = items[index + 1] || false;
    if (nextSelectedItem)
      dispatch("loadSelectedItem", {
        selectedItemId: nextSelectedItem.order_id
      });
    reset(); // This function is passed from the ProctorSitting.vue component.
  }
};

const state: ProctorSittingState = {
  busy: false,
  showProctoringDialog: false,
  selectedItemId: "",
  hasNextItem: false,
  proctoringReasons: []
};

export default {
  namespaced: true,
  state,
  mutations: {
    setShowProctoringDialog(state, showProctoringDialog) {
      state.showProctoringDialog = showProctoringDialog;
    },
    setProctoringReasons(state, proctoringReasons) {
      state.proctoringReasons = proctoringReasons;
    }
  },
  actions: {
    loadSelectedItem,
    saveProctoring
  },
  getters: {
    proctoringReasons: state => state.proctoringReasons
  }
};
