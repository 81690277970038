<template>
  <div class="es-record-message" :class="{ 'wrap': wrap, 'help': showHelp, 'as-marking': marking , 'dark': darkMode  }">
    <div class="wrapper">
      <button v-if="showHelp" v-es-on:click="() => $emit('show-skip')" class="es-help">?</button>
      <p v-if="title" class="title">{{ title }}</p>
      <simplebar ref="scroller" class="message-wrapper">
        <vue-markdown>
          {{ formattedMessage }}
        </vue-markdown>
      </simplebar>
    </div>
  </div>
</template>
<script>
import simplebar from 'simplebar-vue';
export default {
  name: 'es-record-message',
  components: {
    simplebar
  },
  props: {
    message: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    wrap: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showHelp: {
      type: Boolean,
      required: false,
      default: () => true
    },
    marking: {
      type: Boolean,
      required: false,
      default: () => false
    },
    darkMode: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    formattedMessage() {
      if (this.darkMode) {
        return this.message.replace("record a short voice message", "**record a short voice message**").replace("You must:", "**You must:**").replaceAll(/\\n/g, '')
      }
      return this.message.replaceAll(/\\n/g, '')
    }
  },
  mounted() {
    if (this.darkMode) {
      this.$refs.scroller.SimpleBar.options = {
        ...this.$refs.scroller.SimpleBar.options,
        autoHide: false,
        forceVisible: 'y',
        // scrollbarMinSize: 8,
        // scrollbarMaxSize: 8
      }
      this.$refs.scroller.SimpleBar.recalculate()
    }
  }
}
</script>