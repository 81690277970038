<template>
  <div :style="style">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'es-safe-area-view',
  props: {
    includeHeader: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    style() {
      const headerHeight = window.innerWidth > 320 ? 73 : 69
      const { topSafeAreaHeight, bottomSafeAreaHeight } = this.$deviceConfig;
      return {
        height: this.includeHeader ? `calc( 100vh - (${(topSafeAreaHeight + headerHeight + bottomSafeAreaHeight)}px` : `calc( 100vh - (${(topSafeAreaHeight + bottomSafeAreaHeight)}px`,
        marginTop: this.includeHeader ? `${(topSafeAreaHeight + headerHeight)}px` : `${topSafeAreaHeight}px`,
        flex: 1,
        overflow: 'hidden'
      }
    }
  }
}
</script>