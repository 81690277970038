<template>
    <button class="es-btn" :class="[color, { 'full': full } ]" @click="$emit('click', $event)" :disabled="disabled">
        <slot>Default Button</slot>
    </button>
</template>
<script>
export default {
    name: 'es-btn',
    props: ['color', 'disabled', 'full']
}
</script>