<template>
  <div class="es-questions" :class="{ 'disabled': disabled}">
    <div class="grey-box">
      <h2 v-if="title">{{ title }}</h2>
      <p v-if="text">{{ text }}</p>
      <template v-for="question in questions">
        <div class="content" :key="`qs_${question.id}`">
          <p>{{ question.value }}</p>
          <div>
            <template v-for="option in question.options">
              <es-tap-btn @click="() => toggleSelection(question.id, option.id)" :class="{ 'active': isSelected(question.id, option.id), 'large-font': largeFont }" :key="`opt_${question.id}_${option.id}`">{{ option.value }}</es-tap-btn>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-questions',
  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    questions: {
      type: Array,
      required: true
    },
    largeFont: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    selectedOptions: {}
  }),
  methods: {
    isSelected(questionId, optionId) {
      return this.selectedOptions[questionId] && this.selectedOptions[questionId].includes(optionId)
    },
    toggleSelection(questionId, optionId) {
      if (this.disabled) {
        return;
      }
      if (!this.selectedOptions[questionId]) {
        this.selectedOptions[questionId] = []
        this.selectedOptions = {
          ...this.selectedOptions
        }
      }
      // if (this.selectedOptions[questionId].includes(optionId)) {
      //   const index = this.selectedOptions[questionId].indexOf(optionId)
      //   this.selectedOptions[questionId] = [
      //     ...this.selectedOptions[questionId].slice(0, index),
      //     ...this.selectedOptions[questionId].slice(index+1)
      //   ]
      // } else {
        
      // }
      this.selectedOptions[questionId] = [
        optionId
      ]
      this.emitResponse()
    },
    emitResponse() {
      let count = 0
      for(const questionId of Object.keys(this.selectedOptions)) {
        count += this.selectedOptions[questionId].length
      }
      if (count !== this.questions.length) {
        this.$emit('input', null)
      } else { 
        this.$emit('input', {
          'rating_question': this.selectedOptions
        })
      }
    }
  }
}
</script>