import Hammer from 'hammerjs'

export default (options) => {
  const { platform } = {
    platform: 'web',
    ...options
  }
  return {
    name: 'es-on',
    directive: {
      inserted: (el) => {
        if (platform === 'ios' || platform === 'android') {
          const mc = el.hammer
          // Disabled tap
          mc.set({ enable: el.getAttribute('disabled') !== 'disabled' });
        }
      },
      bind: (el, binding) => {
        if (platform === 'ios' || platform === 'android') {
          if (!el.hammer) {
            el.hammer = new Hammer.Manager(el, {
              cssProps: {
                tapHighlightColor: 'rgba(0, 0, 0, 0.2)'
              },
              recognizers: [[Hammer.Tap]]
            })
          }
          const mc = el.hammer;
          // Disabled tap
          mc.set({ enable: el.getAttribute('disabled') !== 'disabled' });
          if (typeof binding.value !== 'function') {
            mc.handler = null
            console.warn('[@es/ui tap] invalid handler function: ' + binding.arg)
          } else {
            mc.on('tap', (mc.handler = binding.value));
          }
        } else {
          el.addEventListener(binding.arg, binding.value);
        }
      },
      componentUpdated: async (el, binding) => {
        // HACK: disabled state update triggers with little delay, thus adding 15 Millisecond delay
        await new Promise(resolve => setTimeout(resolve, 15));
        
        if (platform === 'ios' || platform === 'android') {
          const mc = el.hammer
          if (!mc) return;
          if (mc.handler) {
            mc.off('tap', mc.handler)
          }
          // Disabled tap
          mc.set({ enable: el.getAttribute('disabled') !== 'disabled' });
          if (typeof binding.value !== 'function') {
            mc.handler = null
            console.warn('[@es/ui tap] invalid handler function: ' + binding.arg)
          } else {
            mc.on('tap', (mc.handler = binding.value));
          }
        }
      },
      unbind: (el, binding) => {
        if (platform === 'ios' || platform === 'android') {
          const mc = el.hammer
          // Disabled tap
          mc.set({ enable: el.getAttribute('disabled') !== 'disabled' });
          mc.off('tap', (mc.handler = binding.value))
          el.hammer.destroy()
          el.hammer = null
        } else {
          el.removeEventListener(binding.arg, binding.value);
        }
      }
    }
  }
}