import { singleton } from "tsyringe";
import NativeAudioService from '@es/domain/lib/services/NativeAudioService'
import { callbackFunction, callbackFunctionNoTimeout } from "..";
import StopRecordingResponse from "@es/domain/lib/models/AudioService";

@singleton()
export default class AndroidAudioService implements NativeAudioService {

  record(onProgress: (err: any, data: any) => void): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'startRecording',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }),
        params: JSON.stringify({
          micLevelCallback: callbackFunctionNoTimeout(onProgress)
        })
      }))
    })
  }

  stop(): Promise<StopRecordingResponse> {
    return new Promise((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'stopRecording',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(JSON.parse(data))
        }),
        params: JSON.stringify({})
      }))
    })
  }

  get(token: string): Promise<string> {
    return new Promise((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'getRecording',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          // console.log('android:data', data)
          resolve(data)
        }),
        params: JSON.stringify({
          token
        })
      }))
    })
  }


  playAudio(token: string, onDone: () => void): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'playAudio',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }),
        params: JSON.stringify({
          token,
          onPlayFinishedCallback: callbackFunctionNoTimeout(onDone)
        })
      }))
    })
  }

  pauseAudio(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'pauseAudio',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }),
        params: JSON.stringify({})
      }))
    })
  }
  
}