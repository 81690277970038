<template>
  <div class="es-progress">
    <div class="progress-container">
      <div class="progress-state" ref="progressState"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-progress',
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },
  watch: {
    progress(newValue) {
      this.$refs.progressState.style.width = `${newValue}%`
    }
  },
  mounted() {
    this.$refs.progressState.style.width = `${this.progress}%`
  }
}
</script>