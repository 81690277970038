import axios from "axios";
import { token } from "./authentication";

const beforeRequest = async config => {
  config.headers = {
    Authorization: `Bearer ${await token()}`
  };
  return config;
};

const getBaseUrl = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.has("base_url")) {
    return params.get("base_url") || "";
  }
  return process.env.VUE_APP_API_BASE_URL || "";
};

const apiClient = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "Application/json"
  }
});
apiClient.interceptors.request.use(beforeRequest);

const apiCancelToken = axios.CancelToken;

const isCancel = axios.isCancel;

export { apiClient, apiCancelToken, isCancel };
