import { apiClient } from './../services/api'

export default {
  namespaced: true,
  state: {
    mode: 'production',
    certBusy: false
  },
  mutations: {
    changeMode(state, mode) {
      state.mode = mode
      // Sync with local/QA storage
      // window.localStorage.setItem('apiMode', mode)
      // if (window.location.search.includes('local') && process.env.VUE_APP_API_ENDPOINT_LOCAL) {
      //   setBaseURL(process.env.VUE_APP_API_ENDPOINT_LOCAL)
      // } else if (window.location.search.includes('qa') && process.env.VUE_APP_API_ENDPOINT_QA) {
      //   setBaseURL(process.env.VUE_APP_API_ENDPOINT_QA)
      // } else if (window.location.search.includes('spoken') && process.env.VUE_APP_API_ENDPOINT_SPOKEN) {
      //   setBaseURL(process.env.VUE_APP_API_ENDPOINT_SPOKEN)
      // } else {
      //   setBaseURL((state.mode === 'production') ? process.env.VUE_APP_API_ENDPOINT_PRODUCTION : process.env.VUE_APP_API_ENDPOINT_STAGING)
      // }
    },
    setCertBusy(state, certBusy) {
      state.certBusy = certBusy
    }
  },
  actions: {
    loadMode: ({ commit }) => {
      // Fetch from local storage
      commit('changeMode', window.localStorage.getItem('apiMode') || 'production')
    },
    toggleMode: ({ commit, getters }) => {
      commit('changeMode', !getters.isProduction ? 'production' : 'staging' )
    },
    requestCertGen: async ({ commit }, { uid, sid, oid }) => {
      try {
        commit('setCertBusy', true)
        const { data } = await apiClient.patch('/v1.11/certificate',{
          'user_id': uid,
          'sitting_id': sid,
          'order_id': oid
        })
        console.log(data)
      } catch(err) {
        if (err.response) {
          switch(err.response.status) {
            case 400:
              throw new Error(err.response.data.message)
            case 404:
              throw new Error(err.response.data.message)
            case 500:
            default:
              throw new Error('Something went wrong, Please try again.')
          }
        } else {
          throw new Error('Something went wrong, Please try again.')
        }
      } finally {
        commit('setCertBusy', false)
      }
    },
    resetBaseUrl: () => {
      const params = new URLSearchParams(window.location.search)
      params.delete('base_url')
      window.location.href = `${window.location.origin}?${params.toString()}`
    }
  },
  getters: {
    isProduction: state => state.mode === 'production',
    isCertBusy: state => state.certBusy,
    baseUrl: () => {
      return apiClient.defaults.baseURL
    },
    hasBaseUrlChanged: (_, getters) => {
      return getters.baseUrl !== process.env.VUE_APP_API_BASE_URL
    }
  }
}