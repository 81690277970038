<template>
  <h2 :class="[align]">
    <slot></slot>
  </h2>
</template>
<script>
export default {
  name: 'es-h2',
  props: {
    align: {
      type: String,
      default: 'left',
      required: false
    }
  }
}
</script>
<style lang="scss" scoped>
h2 {
  color: #220a5a;
  &.left {
    text-align: left;
  }
  &.right {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
}
</style>