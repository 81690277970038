
import { apiClient } from '@/services/api'

// Generate a human-friendly label e.g. from "DND_READING" to "Dnd Reading"
const humanize = (string: string) => string && string
  .trim()
  .toLowerCase()
  .split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ')

const headers = [
  { text: 'Name', value: 'name' },
  { text: 'Email', value: 'email' },
  { text: 'Roles', value: 'roleNames' },
  { text: '', value: 'actions', align: 'right', sortable: false },
]

const fetchRoles = async ({ state }) => {
  const { data: roles } = await apiClient.get('/v1.11/roles')
  state.roles = roles.map(role => ({ ...role, label: humanize(role.role_type) })).sort((a, b) => a.role_type < b.role_type ? -1 : 1)
}

const getFullname = ({ first_name, last_name }) => {
  if (!first_name && !last_name) return '—'
  // sometimes last name is already in the first name.
  if (first_name?.endsWith(last_name)) return first_name
  return [first_name, last_name].filter(Boolean).join(' ')
}

const fetchUsers = async ({ dispatch, rootState, state }) => {
  state.busy = true
  try {
    const { data: { users } } = await apiClient.get('/v1.11/user/internal', {
      params: {
        user_id: rootState.account.user.user_id,
        page_size: 10000,
      }
    })
    const items = users.map(({ first_name, last_name, roles, user_profile_id, email }) => {
      const userRoles = roles?.reduce((accumulator, role) => {
        if (!role.active) return accumulator

        const match = state.roles.find(({ role_id }) => role_id === role.role_id)
        if (!match) return accumulator

        const { role_type } = match
        accumulator.push({ ...role, role_type })
        return accumulator
      }, [])

      const roleNames =  [...userRoles].map(({ role_type }) => role_type).sort().map(humanize).join(', ')
      return {
        user_id: user_profile_id,
        name: getFullname({ first_name, last_name }),
        email,
        userRoles,
        roleNames,
      }
    })
    state.items = items
  }
  catch (error) {
    console.error(error)
    state.items = []
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Could not load users.</strong>`,
    }, { root: true })
  }
  state.busy = false
}

const loadUserRolesForm = async ({ dispatch, state }, user_id) => {
  state.user = state.items.find(({ user_id: id }) => id === user_id)
  if (state.user) {
    state.showRolesModal = true
  }
  else {
    state.showRolesModal = false
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Could not load user.</strong>`,
    }, { root: true })
  }
}

const updateUserRoles = async ({ dispatch, state }, { selectedRoles }) => {
  state.busy = true
  const { user: { user_id: user_profile_id, userRoles }}  = state
  const userRoleTypes = userRoles.map(({ role_type }) => role_type)
  const rolesToAdd = selectedRoles?.filter(role => !userRoleTypes.includes(role))
  const rolesToRemove = userRoleTypes.filter(role => !selectedRoles.includes(role))
  if (!rolesToAdd.length && !rolesToRemove.length) {
    state.busy = false
    state.showRolesModal = false
    return
  }

  try {
    const promisesToAddRoles = rolesToAdd.map(role => {
      const { role_id } = state.roles.find(({ role_type }) => role_type === role)
      return apiClient.post('/v1.11/userroles', { user_profile_id, role_id })
    })
    const promisesToRemoveRoles = rolesToRemove.map(role => {
      const { user_role_id } = userRoles.find(({ role_type }) => role_type === role)
      return apiClient.patch('/v1.11/userroles', { user_role_id, active: false })
    })
    await Promise.all([
      ...promisesToAddRoles,
      ...promisesToRemoveRoles,
    ])
    state.showRolesModal = false
    dispatch('fetchUsers')
    dispatch('snackbar/snack', { mode: 'success', message: `✅ Roles updated.` }, { root: true })
  }
  catch (error) {
    state.busy = false
    dispatch('snackbar/snack', {
    mode: 'error',
    message: `⚠️ Error: <strong class="px-4">Sorry, something went wrong when updating roles.</strong>`,
    }, { root: true })
  }
}

export default {
  namespaced: true,
  state: {
    busy: true,
    showRolesModal: false,
    headers,
    items: [],
    roles: [],
    user: {},
  },
  actions: {
    fetchUsers,
    fetchRoles,
    loadUserRolesForm,
    updateUserRoles,
  },
  mutations: {
    setShowRolesModal(state, showRolesModal) {
      state.showRolesModal = showRolesModal
    },
    setUser(state, user) {
      state.user = user
    },
  },
}
