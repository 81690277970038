<template>
  <div class="es-rating" :class="{ 'disabled': disabled }">
    <div class="rating-wrapper">
      <h3>{{ title }}</h3>
      <div class="rating">
        <div class="stars">
          <star-rating
          :read-only="disabled"
          @rating-selected="emitResponse"
          :show-rating="false"
          v-model="ratingResponse.easy_to_understand"
          :border-width="2"
          border-color="#e1e0e0"
          :active-border-color="starColor"
          :active-color="starColor"
          inactive-color="#f4f4f4"
          :max-rating="5" />
        </div>
        <div class="star-response">
          <p>{{ starMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-rating',
  props: {
    title: {
      type: String,
      required: false,
      default: 'How easy are they to understand?'
    },
    isLR: {
      type: Boolean,
      required: false,
      default: false
    },
    ratingText: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    ratingResponse: {
      easy_to_understand: 0
    }
  }),
  computed: {
    starColor() {
      // switch(this.ratingResponse.easy_to_understand) {
      //   case 5:
      //     return '#5deb4c'
      //   case 4:
      //     return '#b2eb4c'
      //   case 3:
      //     return '#ffe300'
      //   case 2:
      //     return '#ff4635'
      //   case 1:
      //     return '#ab0033'
      // }
      return '#ffe300'
    },
    starMessage() {
      switch(this.ratingResponse.easy_to_understand) {
        case 5:
          return this.isLR ? 'I understood them very well.' : 'They described a lot of details and spoke very well.'
        case 4:
          return this.isLR ? 'They made a few mistakes but I understood what they said.' : 'They described a few more details and made no mistakes.'
        case 3:
          return this.isLR ? 'They made a few mistakes and were a bit difficult to understand.' : 'They didn’t say much, but didn’t make many mistakes'
        case 2:
          return this.isLR ? 'They made many mistakes and were very difficult to understand.' : 'They didn’t say very much and made lots of mistakes.'
        case 1:
          return this.isLR ? 'They made lots of mistakes and I couldn’t understand them.' : 'I couldn’t understand them'
      }
      return ''
    }
  },
  methods: {
    emitResponse() {
      this.$emit('input', this.ratingResponse)
    }
  },
  beforeMount() {
    if (this.value !== null) {
      this.ratingResponse = {
        ...this.value
      }
    }
  }
}
</script>