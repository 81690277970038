<template>
  <div id="app">
    <header>
      <div class="wrapper">
        <div class="branding" @click="navigateHome">
          <img src="@/assets/bc-es-logo.svg" alt="EnglishScore - CMS">
          <h1>CMS</h1>
        </div>
        <div v-if="hasBaseUrlChanged" class="header-right-content base-url-info">
          <p>Backend Mapped to</p>
          <p>
            <strong>{{ baseUrl }}</strong>
            <a @click="resetBaseUrl">Reset to default</a>
          </p>
        </div>
        <div v-if="user" class="header-right-content">
          <p class="user-info">
            {{ `Welcome, ${user.displayName}` }}
            <a @click="logout"><fa icon="sign-out-alt" /> Logout</a>
          </p>
        </div>
      </div>
    </header>
    <main>
      <router-view />
    </main>
    <Snackbar />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Snackbar from '@/components/commons/Snackbar.vue'

export default {
    name: 'App',
    components: {
        Snackbar,
    },
    computed: {
        ...mapGetters({
            user: 'account/user',
            baseUrl: 'ui/baseUrl',
            hasBaseUrlChanged: 'ui/hasBaseUrlChanged'
        }),
    },
    methods: {
        ...mapActions({
            accountLogout: 'account/logout',
            resetBaseUrl: 'ui/resetBaseUrl'
        }),
        navigateHome() {
          if (this.$router.currentRoute.path !== '/') {
            this.$router.push('/')
          }
        },
        async logout() {
          try {
            await this.accountLogout()
            this.$router.replace({ name: 'login', query: this.$route.query, params: this.$route.params })
          } catch(err) {
            console.log(err)
          }
        }
    },
}
</script>
<style lang="scss" scoped>
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $dark-indigo;
    display: flex;
    align-items: center;
    height: 126px;
    padding-top: 1em;
    z-index: 99;

    @include media-breakpoint-up(md) {
      height: 78px;
      padding-top: 0;
    }

    & .wrapper {
      flex: 1;
      padding: 0 1em;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      & .branding {
        cursor: pointer;
        display: flex;
        & img {
          width: 100px;
          border-right: 1px solid white;
          padding-right: 30px;
          margin-right: 30px;
        }
        & h1 {
          margin: 0;
          color: white;
          line-height: inherit;
        }
      }

      & .header-right-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        & .api-mode {
          min-width: 150px;
          color: white;
          margin-right: 1em;
          padding-right: 1em;
          border-right: 1px solid white;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          & span {
            display: inline-block;
            margin-left: .5em;
          }
        }
      }
      & .base-url-info {
        color: white;
        display: flex;
        flex-direction: column;
        & p {
          margin: 0;
          & a {
            margin-left: .5em;
            color: white;
            text-decoration: none;
            cursor: pointer;
            transition: .2s;
            &:active {
              opacity: .8;
            }
          }
        }
      }

      & .user-info {
        color: white;

        & a {
          margin-left: 1.5em;
          cursor: pointer;
          font-family: 'BritishCouncilSansW15-Headline';
        }
      }
    }
}
main {
  padding: 0 1em;
  padding-top: 126px;
  @include media-breakpoint-up(md) {
    padding-top: 78px;
  }
}
</style>