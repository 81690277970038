import { MutationTree, ActionTree, GetterTree } from "vuex";
import { apiClient } from "@/services/api";
import axios from "axios";

interface Item {
  response_id: string;
  item_id: string;
  grader_id: string;
  data_point_type: string;
  data_point_value: string;
  data_points: Record<string, string>[];
  sequence: number;
  template_type: string;
  grader_type: string;
  is_correct: string;
  response: Array<string>;
}

interface SittingDetail {
  sitting_id: string;
  user_id: string;
  assessment_instance_id: string;
  assessment_type_id: string;
  assessment_type_name: string;
  status: string;
  grading_status: string;
  assessment_skills: string | string[];
  item_responses: Item[];
  score: {
    englishscore: number | string | null;
  };
}

interface SittingDetailsResponse {
  results: SittingDetail[];
}

export interface SittingsState {
  sittings: unknown[];
  sittingDetails?: SittingDetail;
  busy: boolean;
  pagination: {
    perPage: number;
    offset: number;
  };
  filters: {
    sitting_user_profile_id?: string;
    assessment_instance_id?: string;
    assessment_type?: string;
  };
  error?: Error;
}

export enum SittingsMutations {
  SET_BUSY = "SET_BUSY",
  SET_PAGINATION = "SET_PAGINATION",
  SET_FILTERS = "SET_FILTERS",
  SET_SITTINGS = "SET_SITTINGS",
  SET_SITTING_DETAILS = "SET_SITTING_DETAILS",
  SET_ERROR = "SET_ERROR"
}

// Generate a human-friendly label e.g. from "DND_READING" to "Dnd Reading"
const humanize = (string: string) =>
  string &&
  string
    .trim()
    .toLowerCase()
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const getSkill = assessment_typeName => {
  if (assessment_typeName.startsWith("crella")) return "Core Skills";
  if (assessment_typeName.startsWith("spoken")) return "Speaking";
  if (assessment_typeName.startsWith("writing")) return "Writing";
  return "Unknown";
};

const headers = [
  { text: "Started", value: "started_time" },
  { text: "Sitting Id", value: "sitting_id" },
  { text: "Assessment Instance Id", value: "assessment_instance_id" },
  { text: "Assessment Type", value: "assessment_type_name" },
  { text: "Score", value: "score" },
  { text: "Status", value: "status" },
  { text: "", value: "user_id", align: " d-none" },
  { text: "", value: "actions", align: "right", sortable: false }
];

const itemHeaders = [
  { text: "Template Type", value: "template_type" },
  { text: "Question", value: "question" },
  { text: "Response", value: "response" },
  { text: "Answer correct?", value: "is_correct" },
  { text: "Grader Type", value: "grader_type" },
  { text: "Data Points", value: "data_points" },
  { text: "Issues", value: "issues" },
  { text: "Candidate CEFR", value: "candidate_cefr" },
  { text: "Candidate Country", value: "candidate_country" },
  { text: "", value: "question_id", align: " d-none" },
  { text: "", value: "user_id", align: " d-none" },
  { text: "", value: "actions", align: "right", sortable: false }
];

const initialState: SittingsState = {
  busy: true,
  pagination: {
    perPage: 100,
    offset: 0
  },
  sittings: [],
  sittingDetails: undefined,
  filters: {
    sitting_user_profile_id: "",
    assessment_instance_id: "",
    assessment_type: ""
  }
};

const state: SittingsState = {
  ...initialState
};

const mutations: MutationTree<SittingsState> = {
  [SittingsMutations.SET_BUSY](state, busy: boolean) {
    state.busy = busy;
  },
  [SittingsMutations.SET_PAGINATION](
    state,
    pagination: { perPage: number; offset: number }
  ) {
    state.pagination = pagination;
  },
  [SittingsMutations.SET_FILTERS](
    state,
    filters: {
      sitting_user_profile_id?: string;
      assessment_instance_id?: string;
      assessment_type?: string;
    }
  ) {
    state.filters = filters;
  },
  [SittingsMutations.SET_SITTINGS](state, sittings: unknown[]) {
    state.sittings = sittings;
  },
  [SittingsMutations.SET_SITTING_DETAILS](
    state,
    sittingDetails: SittingDetail
  ) {
    state.sittingDetails = sittingDetails;
  },
  [SittingsMutations.SET_ERROR](state, error: Error | undefined) {
    state.error = error;
  }
};

const actions: ActionTree<SittingsState, any> = {
  fetchSittings: async ({ state, commit, rootState }) => {
    try {
      commit(SittingsMutations.SET_BUSY, true);
      commit(SittingsMutations.SET_ERROR, undefined);
      const params = {
        user_id: rootState.account.user.user_id,
        page_size: state.pagination.perPage,
        offset: state.pagination.offset,
        application: "CMS"
      };
      if (
        state.filters.sitting_user_profile_id &&
        state.filters.sitting_user_profile_id.length > 3
      ) {
        params["sitting_user_profile_id"] =
          state.filters.sitting_user_profile_id;
      }
      if (
        state.filters.assessment_instance_id &&
        state.filters.assessment_instance_id.length > 3
      ) {
        params["assessment_instance_id"] = state.filters.assessment_instance_id;
      }
      if (
        state.filters.assessment_type &&
        state.filters.assessment_type.length > 3
      ) {
        params["assessment_type"] = `${state.filters.assessment_type}%`;
      }

      const {
        data: { results, page_size, offset }
      } = await apiClient.get("/v1.13/exam/sittings", { params });
      const sittings = results.map(sitting => {
        return {
          ...sitting,
          score: sitting.score?.englishscore || ""
        };
      });
      commit(SittingsMutations.SET_SITTINGS, sittings);
      commit(SittingsMutations.SET_PAGINATION, { perPage: page_size, offset });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.response?.data.message &&
          commit(
            SittingsMutations.SET_ERROR,
            new Error(err.response.data.message)
          );
      } else {
        commit(SittingsMutations.SET_ERROR, err);
      }
    } finally {
      commit(SittingsMutations.SET_BUSY, false);
    }
  },
  fetchSittingDetailsByID: async ({ commit, rootState }, { sittingId }) => {
    try {
      commit(SittingsMutations.SET_BUSY, true);
      commit(SittingsMutations.SET_ERROR, undefined);
      const params = {
        user_id: rootState.account.user.user_id,
        application: "CMS",
        sitting_id: sittingId,
        include_item_responses: true
      };
      const {
        data: {
          results: [sittingDetails]
        }
      } = await apiClient.get<SittingDetailsResponse>("/v1.13/exam/sittings", {
        params
      });
      sittingDetails.assessment_type_name = sittingDetails.assessment_type_name
        ? humanize(sittingDetails.assessment_type_name)
        : "—";
      sittingDetails.status = sittingDetails.status
        ? humanize(sittingDetails.status)
        : "—";
      sittingDetails.grading_status = sittingDetails.grading_status
        ? humanize(sittingDetails.grading_status)
        : "—";
      sittingDetails.score.englishscore = sittingDetails.score?.englishscore
        ? sittingDetails.score.englishscore
        : "—";
      sittingDetails.assessment_skills = sittingDetails.assessment_type_name
        ? getSkill(sittingDetails.assessment_type_name)
        : "—";

      /**
       * @Note
       * Some sittings have a response ID; they have text for their "response" field.
       * Others have an array of response IDs in their "response" field.
       * In the latter case, use the first row in the response array as the "response_id" field.
       */
      const item_responses = sittingDetails.item_responses.reduce<any[]>(
        (accumulator, response) => {
          // Ignoore expert grades for now
          if (response.grader_type === "expert") return accumulator;

          const response_id = response.response_id || response.response[0];

          const item = accumulator.find(
            item => item.response_id === response_id
          );
          if (item) {
            if (response.data_point_type) {
              if (!item.grader_type?.includes(humanize(response.grader_type))) {
                item.grader_type.push(humanize(response.grader_type));
              }
              item.data_points.push({
                type: humanize(response.data_point_type),
                value: response.data_point_value,
                grader_id: response.grader_id
              });
            }
          } else {
            accumulator.push({
              response_id,
              item_id: response.item_id,
              sequence: response.sequence,
              template_type: response.template_type
                ? humanize(response.template_type)
                : "—",
              grader_type: [
                response.grader_type ? humanize(response.grader_type) : "—"
              ],
              is_correct: response.is_correct ? "Correct" : "—",
              response: response.response,
              data_points: response.data_point_type
                ? [
                    {
                      type: humanize(response.data_point_type),
                      value: response.data_point_value,
                      grader_id: response.grader_id
                    }
                  ]
                : []
            });
          }

          // const response_id = response.response_id || response.response[0];

          // const acc_response = accumulator[response_id] ?? {
          //   ...response
          // };

          // acc_response.template_type = response.template_type
          //   ? humanize(response.template_type)
          //   : "—";
          // acc_response.grader_type = response.grader_type
          //   ? humanize(response.grader_type)
          //   : "—";
          // acc_response.is_correct = response.is_correct ? "Correct" : "—";

          // if (acc_response.data_point_type) {
          //   const dataPoint = {
          //     type: humanize(response.data_point_type),
          //     value: response.data_point_value,
          //     grader_id: response.grader_id
          //   };
          //   if (!("data_points" in acc_response)) {
          //     acc_response.data_points = [];
          //   }
          //   acc_response.data_points.push(dataPoint);

          //   // response.data_points = [
          //   //   ...(accumulator[response_id]?.data_points ?? []),
          //   //   {
          //   //     ...dataPoint
          //   //   }
          //   // ];
          // } else {
          //   acc_response.data_points = [];
          // }
          // accumulator[response_id] = acc_response;
          return accumulator;
        },
        []
      );

      commit(SittingsMutations.SET_SITTING_DETAILS, {
        ...sittingDetails,
        items: item_responses //Object.values(item_responses)
      });
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        err.response?.data.message &&
          commit(
            SittingsMutations.SET_ERROR,
            new Error(err.response.data.message)
          );
      } else {
        commit(SittingsMutations.SET_ERROR, err);
      }
    } finally {
      commit(SittingsMutations.SET_BUSY, false);
    }
  },
  resetSittingDetails: ({ commit }) => {
    commit(SittingsMutations.SET_SITTING_DETAILS, undefined);
  }
};

const getters: GetterTree<SittingsState, unknown> = {
  sittings: state => state.sittings,
  isBusy: state => state.busy,
  error: state => state.error,
  pagination: state => state.pagination,
  filters: state => state.filters,
  sittingDetails: state => state.sittingDetails,
  isSpeakingSitting: state =>
    state.sittingDetails?.assessment_skills.includes("spoken"),
  headers: () => headers,
  itemHeaders: () => itemHeaders
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
