/**
 * Authorisation: Role-based access control, based on the user's roles and the route's allowed roles.
 *
 * Grant access if any of the user's authorised roles matches any
 * of the roles that are allowed to access this route.
 */
import routes from "../router/routes";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { children: browseRoutes }: any = routes.find(({ path }) => path === "/");
const userHasAccess = ({ to, authorisedRoles }) => {
  const userHasAuthorisedRoles =
    Array.isArray(authorisedRoles) && authorisedRoles.length > 0;
  const { meta } = browseRoutes.find(({ path }) => path.startsWith(to)) || {};
  const { allowedRoles } = meta || {};
  if (
    userHasAuthorisedRoles &&
    Array.isArray(allowedRoles) &&
    allowedRoles.some(role => authorisedRoles.includes(role))
  ) {
    return true;
  }

  // Fall back to denying access unless it has explicitly been granted.
  return false;
};

export default {
  namespaced: true,
  state: {
    links: [
      {
        title: "View Offers",
        to: "/leads",
        icon: "search-dollar"
      },
      {
        title: "Certificate Vouchers",
        to: "/certificate-vouchers",
        icon: "ticket-alt"
      },
      {
        title: "Connect Codes",
        to: "/connect-codes",
        icon: "ticket-alt"
      },
      {
        title: "Cert Regen",
        to: "/cert-regen",
        icon: "certificate"
      },
      {
        title: "Certificate Preview",
        to: "/certificate-preview",
        icon: "portrait"
      },
      {
        title: "User Events",
        to: "/user-events",
        icon: "users"
      },
      {
        title: "User Deletion",
        to: "/user-deletion",
        icon: "user-minus"
      },
      {
        title: "Proctoring",
        to: "/proctoring",
        icon: "images"
      },
      {
        title: "Items",
        to: "/items",
        icon: "list-alt"
      },
      {
        title: "Certify a Sitting",
        to: "/certify",
        icon: "certificate"
      },
      {
        title: "Certificate Credits",
        to: "/certificate-credits",
        icon: "ticket-alt"
      },
      {
        title: "Users",
        to: "/users",
        icon: "user-alt"
      },
      {
        title: "Sittings",
        to: "/sittings",
        icon: "school"
      },
      {
        title: "Assessment Instances",
        to: "/assessment-instances",
        icon: "code-branch"
      },
      {
        title: "Organisations",
        to: "/organisations",
        icon: "dice-d6"
      },
      {
        title: "Access Management",
        to: "/access-management",
        icon: "users"
      },
      {
        title: "Grading",
        to: "/grading",
        icon: "highlighter"
      },
      {
        title: "Revoke Certificate",
        to: "/revoke-certificate",
        icon: "certificate"
      },
      {
        title: "Fluentify <> Mailchimp",
        to: "/fluentify-mailchimp-sync-status",
        icon: "mail-bulk"
      },
      {
        title: "Reinstate Certificate",
        to: "/reinstate-certificate",
        icon: "certificate"
      },
      {
        title: "Retire App Version",
        to: "/retire-app-version",
        icon: "mobile"
      },
      {
        title: "User Configurations",
        to: "/user-configurations",
        icon: "user-cog"
      }
    ]
  },
  getters: {
    links: (state, _getters, _rootState, rootGetters) => {
      return state.links.map(link => {
        const hasAccess = userHasAccess({
          to: link.to.slice(1),
          authorisedRoles: rootGetters["account/user"].roles
        });
        return {
          ...link,
          hasAccess
        };
      });
    }
  }
};
