<template>
  <div>
    <div class="es-mark-words" :class="{ 'disabled': disabled }">
      <div class="mark-wrapper">
        <h3>{{ title }}</h3>
        <div class="words">
          <template v-for="(word) in words">
            <button
              v-es-on:click="openModal(word)"
              :key="`es-mark-word-${word.option_id}`"
              class="word"
              :class="{'missed': isMissed(word), 'mis-pronounced': isMisPronounced(word), 'wrong-pronounced': isWrongPronounced(word), 'selected': (selectedWord && word.option_id === selectedWord.option_id)}" >
              {{ word.value }}
            </button>
          </template>
        </div>
        <p>or</p>
        <div class="buttons">
          <button
            v-es-on:click="allWordsCorrect"
            class="all-words-correct"
            :class="{ 'selected': markResponse.all_words_heard }">All correct &#x1f44d;</button>
          <button
            v-es-on:click="allWordsMissing"
            class="all-words-missing"
            :class="{ 'selected': markResponse.all_words_missing }">All missing &#x1f44e;</button>
          <!-- <button
            v-es-on:click="allWordsIncorrect"
            class="all-words-incorrect"
            :class="{ 'selected': markResponse.all_words_not_heard }">All words sounds very incorrect</button> -->
        </div>
      </div>
    </div>
    <div v-if="selectedWord" class="es-modal">
      <div v-es-on:click="closeModal" class="modal-backdrop"></div>
      <div class="modal-wrapper">
        <div class="mark-responses">
          <h2>{{ selectedWord.value }}</h2>
          <div class="responses">
            <button v-es-on:click="toMisPronounced()" class="response" :class="{ 'selected': isMisPronounced(selectedWord) }" >It sounded a little incorrect 😕</button>
            <button v-es-on:click="toWrongPronounced()" class="response" :class="{ 'selected': isWrongPronounced(selectedWord) }">It sounded very incorrect 😭</button>
            <button v-es-on:click="toWordMissed()" class="response" :class="{ 'selected': isMissed(selectedWord) }">I did not hear this word ❌</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-mark-words',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Tap any words that are missing, or pronounced incorrectly'
    },
    words: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data: () => ({
    selectedWord: null,
    markResponse: {
      all_words_heard: false,
      all_words_not_heard: false,
      all_words_missing: false,
      phrases: []
    }
  }),
  methods: {
    allWordsCorrect() {
      if (this.disabled) return
      if (!this.markResponse.all_words_heard) {
        this.markResponse = {
          all_words_heard: false,
          all_words_not_heard: false,
          all_words_missing: false,
          phrases: []
        }
        this.words.forEach(word => {
          word.status = 'correct'
        })
        this.markResponse.all_words_heard = true
      } else {
        this.words.forEach(word => {
          word.status = undefined
        })
        this.markResponse.all_words_heard = false
      }
      this.emitResponse()
    },
    allWordsIncorrect() {
      if (this.disabled) return
      if (!this.markResponse.all_words_not_heard) {
        this.markResponse = {
          all_words_heard: false,
          all_words_not_heard: false,
          all_words_missing: false,
          phrases: []
        }
        this.words.forEach(word => {
          word.status = 'incorrect'
        })
        this.markResponse.all_words_not_heard = true
      } else {
        this.words.forEach(word => {
          word.status = undefined
        })
        this.markResponse.all_words_not_heard = false
      }
      this.emitResponse()
    },
    allWordsMissing() {
      if (this.disabled) return
      if (!this.markResponse.all_words_missing) {
        this.markResponse = {
          all_words_heard: false,
          all_words_not_heard: false,
          all_words_missing: false,
          phrases: []
        }
        this.words.forEach(word => {
          word.status = 'missing'
        })
        this.markResponse.all_words_missing = true
      } else {
        this.words.forEach(word => {
          word.status = undefined
        })
        this.markResponse.all_words_missing = false
      }
      this.emitResponse()
    },
    openModal(word) {
      if (this.disabled) return
      this.selectedWord = word
    },
    closeModal() {
      this.selectedWord = null
    },
    isMissed(word) {
      return word.status && word.status === 'missing'
    },
    isMisPronounced(word) {
      return word.status && word.status === 'mispronounced'
    },
    isWrongPronounced(word) {
      return word.status && word.status === 'incorrect'
    },
    toWordMissed() {
      if (this.disabled) return
      if (this.selectedWord.status === 'missing') {
        this.words.find(word => word.option_id === this.selectedWord.option_id).status = 'correct'
      } else {
        this.words.find(word => word.option_id === this.selectedWord.option_id).status = 'missing'
      }
      this.updateButtonState()
      this.selectedWord = null
      this.emitResponse()
    },
    toMisPronounced() {
      if (this.selectedWord.status === 'mispronounced') {
        this.words.find(word => word.option_id === this.selectedWord.option_id).status = 'correct'
      } else {
        this.words.find(word => word.option_id === this.selectedWord.option_id).status = 'mispronounced'
      }
      this.updateButtonState()
      this.selectedWord = null
      this.emitResponse()
    },
    toWrongPronounced() {
      if (this.selectedWord.status === 'incorrect') {
        this.words.find(word => word.option_id === this.selectedWord.option_id).status = 'correct'
      } else {
        this.words.find(word => word.option_id === this.selectedWord.option_id).status = 'incorrect'
      }
      this.updateButtonState()
      this.selectedWord = null
      this.emitResponse()
    },
    updateButtonState() {
      this.markResponse.all_words_not_heard = this.words.length === this.words.filter(item => item.status === 'incorrect').length
      this.markResponse.all_words_heard = this.words.length === this.words.filter(item => (!item.status || item.status === 'correct')).length
      this.markResponse.all_words_missing = this.words.length === this.words.filter(item => item.status === 'missing').length
    },
    emitResponse() {
      this.words.forEach(word => {
        if (!word.status || (word.status !== 'missing' && word.status !== 'incorrect' && word.status !== 'mispronounced')) {
          word.status = 'correct'
        }
      })
      this.$emit('input', {
        ...this.markResponse,
        phrases: this.words
      })
    }
  }
}
</script>