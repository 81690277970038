import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import { registerWebUserInfo } from '@es/data'
import UserInfo from '@es/domain/lib/models/UserInfo'
import { esUser } from './../services/authentication'

registerWebUserInfo({
    getUserDetails: async () => {
        const _esUser = await esUser()
        return {
            authToken: await firebase.auth().currentUser?.getIdToken(),
            userId: _esUser?.user_id,
            latestGVRLScore: 301
        } as UserInfo
    }
})

export default ({
    install: (vue, { store, onReady }) => {
        if (!process.env.VUE_APP_FIREBASE_CONFIG) throw new Error('VUE_APP_FIREBASE_CONFIG is not set')

        firebase.initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG))

        const analytics = firebase.analytics()

        vue.prototype.$logEvent = (name: string, params?: any) => {
            analytics.logEvent(name, params)
        }

        firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                await store.dispatch('account/setUser', false)
                return onReady()
            }

            const _esUser = await esUser()
            await store.dispatch('account/setUser', {
                ...user,
                ..._esUser
            })
            onReady()
        })

        
    }
})
