import { AssessmentResponse, BaseTemplate, Configuration, Section, SubmitResponse } from '@es/domain/lib/models/AssessmentResponse';
import StopRecordingResponse from '@es/domain/lib/models/AudioService';
import NativeAudioService from '@es/domain/lib/services/NativeAudioService';
import { inject, singleton } from 'tsyringe';
import AssessmentApi from '../../apis/AssessmentApi';
import { AtomReportResponse } from '../../models/AtomReportResponse';
import { AssessmentType } from '../../models/Assessment';
import CloudStorage from "../../services/CloudStorage";
import NativeUtils from '../../services/NativeUtils';
import ProctoringRepository from '../../repositories/ProctoringRepository';
import UserInfoProvider from '../../providers/UserInfoProvider';

// TODO: Move Data logic to repository
export enum TestStates {
  Setup = 1,
  SectionStart = 100,
  ElicitedImitationTemplate = 101,
  IndependentVideoDescriptionTemplate = 102,
  IndependentTaskCompletionTemplate = 103,
  ReadLoudTemplate = 104,
  ListenAndRepeatTemplate = 105,
  GuidedTaskTemplate = 106,
  SectionEnd = 1000,
  TestEnd = 1001
}

export enum TemplateType {
  ELICITED_IMITATION = 'ELICITED_IMITATION',
  INDEPENDENT_VIDEO_DESCRIPTION = 'INDEPENDENT_VIDEO_DESCRIPTION',
  INDEPENDENT_TASK_COMPLETION = 'INDEPENDENT_TASK_COMPLETION',
  LISTEN_AND_REPEAT = 'LISTEN_AND_REPEAT',
  READ_ALOUD = 'READ_ALOUD',
  GUIDED_TASK_TEXTUAL = 'GUIDED_TASK_TEXTUAL',
  GUIDED_TASK_VISUAL = 'GUIDED_TASK_VISUAL'
}

export class TestState {
  constructor(public index: number) { }
}

export class Setup extends TestState {
  constructor() {
    super(TestStates.Setup)
  }
}

export class SectionStart extends TestState {
  constructor(public sectionId: string, public title: string, public subTitle: string, public body: string) {
    super(TestStates.SectionStart)
  }
}

export class SolvingTemplate extends TestState {
  constructor(public itemId: string, index: number) {
    super(index)
  }
}

export class ElicitedImitationTemplate extends SolvingTemplate {
  constructor(public itemId: string) {
    super(itemId, TestStates.ElicitedImitationTemplate)
  }
}

export class IndependentVideoDescriptionTemplate extends SolvingTemplate {
  constructor(public itemId: string) {
    super(itemId, TestStates.IndependentVideoDescriptionTemplate)
  }
}

export class IndependentTaskCompletionTemplate extends SolvingTemplate {
  constructor(public itemId: string) {
    super(itemId, TestStates.IndependentTaskCompletionTemplate)
  }
}

export class ReadAloudTemplate extends SolvingTemplate {
  constructor(public itemId: string) {
    super(itemId, TestStates.ReadLoudTemplate)
  }
}

export class ListenAndRepeatTemplate extends SolvingTemplate {
  constructor(public itemId: string) {
    super(itemId, TestStates.ListenAndRepeatTemplate)
  }
}
export class GuidedTaskTemplate extends SolvingTemplate {
  constructor(public itemId: string) {
    super(itemId, TestStates.GuidedTaskTemplate)
  }
}

export class SectionEnd extends TestState {
  constructor(public sectionId: string) {
    super(TestStates.SectionEnd)
  }
}

export class TestEnd extends TestState {
  constructor() {
    super(TestStates.TestEnd)
  }
}


@singleton()
export default class SpokenUsecase {
  constructor (
    @inject('NativeAudioService') private nativeAudioService: NativeAudioService,
    @inject('NativeUtils') private nativeUtils: NativeUtils,
    @inject('CloudStorage') private cloudStorage: CloudStorage,
    @inject('AssessmentApi') private assessmentApi: AssessmentApi,
    @inject('ProctoringRepository') private proctoringRepository: ProctoringRepository,
    @inject('UserInfoProvider') private userInfoProvider: UserInfoProvider,
  ) {}
  /**
   * Start recording
   */
  async startRecording(): Promise<boolean> {
    return (await this.nativeAudioService).record((err, data) => {
      if (!err) {
        window.dispatchEvent(new CustomEvent('AUDIO_WAVE_DATA', {
          detail: {
            data
          }
        }))
      }
    })
  }

  /**
   * Stop recording
   */
  async stopRecording(): Promise<StopRecordingResponse> {
    return (await this.nativeAudioService).stop()
  }

  /**
   * Get recording
   * @param token to fetch recodrinf as base64 string
   */
  async getRecording(token: string): Promise<string> {
    return (await this.nativeAudioService).get(token)
  }

  /**
   * Play audio
   * @param token for audio
   */
  async playAudio(token: string, onDone: () => void): Promise<boolean> {
    return (await this.nativeAudioService).playAudio(token, onDone);
  }

  /**
   * Pause audio
   */
  async pauseAudio(): Promise<boolean> {
    return (await this.nativeAudioService).pauseAudio();
  }
    
  /**
   * 
   * @returns GVRL Score
   */
  async getGVRLScore(): Promise<number> {
    const userInfoProvider = await this.userInfoProvider
    const { latestGVRLScore } = await userInfoProvider.getUserDetails()

    return latestGVRLScore
  }

  /**
   * GVRL Score = Spoken Assessment Level
   * 200-324 = Easy
   * 325-424 = Medium
   * 425-599 = Hard
   * @returns AssessmentType
   */
  async getAssessmentType(): Promise<AssessmentType> {
    const score = await this.getGVRLScore();
    if (score >= 425) {
      return AssessmentType.Spoken12V4Hard
    } else if (score >= 325 && score < 425) {
      return AssessmentType.Spoken12V4Medium
    }
    return AssessmentType.Spoken12V4Easy
  }

  private _testStates: TestState[] = []
  private _currentStateIndex = 0
  private _assessmentData?: AssessmentResponse
  private _submitResponse?: SubmitResponse

  private setupAssessment() {
    this._currentIndex = 0
    this._testStates = []
    this._currentStateIndex = 0
    this._testStates.push(new Setup())

    // let sectionStartEI = false
    let sectionStartLR = false
    // let sectionStartIVD = false
    // let sectionStartITC = false
    let sectionStartRA = false
    let sectionStartGT = false

    let partNumber = 1;
    
    this._assessmentData?.branching.forEach(branch => {
      const section = this._assessmentData?.sections.find(item => item.section_id === branch.section_id)
      // this._testStates.push(new SectionStart(branch.section_id, ''))
      section?.items.map(item => {
        if (!(item.template in this._assessmentData?.all_items!)) return undefined
        switch(item.template) {
          case TemplateType.READ_ALOUD:
            if (!sectionStartRA) {
              this._testStates.push(new SectionStart(branch.section_id, `Part ${partNumber}`, 'Read aloud', `You will see a phrase on-screen\nRecord yourself reading the phrase aloud\nThere are four phrases, you can try recording each one twice`))
              sectionStartRA = true
              partNumber++
            }
            this._testStates.push(new ReadAloudTemplate(item.item_id))
            break
          // case TemplateType.ELICITED_IMITATION:
          //   if (!sectionStartEI) {
          //     this._testStates.push(new SectionStart(branch.section_id, `Part ${partNumber}`, 'Listen and repeat', `You will watch a video of someone saying a phrase\nRecord yourself repeating the phrase that your hear\nThere are four phrases, you can try recording each one twice`))
          //     sectionStartEI = true
          //     partNumber++
          //   }
          //   this._testStates.push(new ElicitedImitationTemplate(item.item_id))
          //   break
          case TemplateType.LISTEN_AND_REPEAT:
            if (!sectionStartLR) {
              this._testStates.push(new SectionStart(branch.section_id, `Part ${partNumber}`, 'Listen and repeat', `You will watch a video of someone saying a phrase\nRecord yourself repeating the phrase that your hear\nThere are four phrases, you can try recording each one twice`))
              sectionStartLR = true
              partNumber++
            }
            this._testStates.push(new ListenAndRepeatTemplate(item.item_id))
            break
            case TemplateType.GUIDED_TASK_TEXTUAL:
            case TemplateType.GUIDED_TASK_VISUAL:
            if (!sectionStartGT) {
              this._testStates.push(new SectionStart(branch.section_id, `Part ${partNumber}`, 'Answer the question', `You will watch a video of someone asking you a question\nUse the image on the screen and record yourself answering their question\nThere are four questions, you can try answering each one twice`))
              sectionStartGT = true
              partNumber++
            }
            this._testStates.push(new GuidedTaskTemplate(item.item_id))
            break
          // case TemplateType.INDEPENDENT_VIDEO_DESCRIPTION:
          //   if (!sectionStartIVD) {
          //     this._testStates.push(new SectionStart(branch.section_id, `Part ${partNumber}`, 'Watch the video, then describe what is happening', `You will see a phrase on-screen\nRecord yourself reading the phrase aloud\nThere are four phrases, you can try recording each one twice`))
          //     sectionStartIVD = true
          //     partNumber++
          //   }
          //   this._testStates.push(new IndependentVideoDescriptionTemplate(item.item_id))
          //   break
          // case TemplateType.INDEPENDENT_TASK_COMPLETION:
          //   if (!sectionStartITC) {
          //     this._testStates.push(new SectionStart(branch.section_id, `Part ${partNumber}`, 'Record a voice message', `You will see a phrase on-screen\nRecord yourself reading the phrase aloud\nThere are four phrases, you can try recording each one twice`))
          //     sectionStartITC = true
          //     partNumber++
          //   }
          //   this._testStates.push(new IndependentTaskCompletionTemplate(item.item_id))
          //   break
        }
      })
      this._testStates.push(new SectionEnd(branch.section_id))
    })
    this._testStates.push(new TestEnd())
  }

  headerConfiguration() {
    return {
      numberOfSections: this._assessmentData?.sections.length,
      secondsInTest: this._assessmentData?.configuration.seconds_in_test,
      defaultTitle: 'Spoken: Part 1'
    }
  }

  getSittingId() {
    return this._assessmentData?.sitting_id
  }

  async startAssessment(assessmentType: AssessmentType): Promise<boolean> {
    const assessmentData = await this.assessmentApi.startTest(assessmentType)
    this._assessmentData = assessmentData
    this.setupAssessment()

    const totalItems = this._assessmentData.sections.reduce((sum, section) => ( sum + section.items.length), 0);
    const noOfImages = 3;

    this.proctoringRepository.init(totalItems, {
      ...this._assessmentData?.audio_response_details,
      Bucket: 'es-proctoring'
    }, noOfImages)

    this._submitResponse = {
      'sitting_id': assessmentData.sitting_id,
      'question_responses': [],
      'user_id': '',
      'has_proctoring': false
    }
    return true
  }

  async submitResponses(): Promise<boolean> {
    const proctoringDone =  await this.proctoringRepository.canSubmit()
    this._submitResponse!['has_proctoring'] = proctoringDone
    const submitResponseStatus = await this.assessmentApi.submitResponses(this._submitResponse!)
    this.proctoringRepository.clear()
    return submitResponseStatus
  }

  async showCameraPreview(width: number, height: number, cameraCheck: boolean) {
    this.proctoringRepository.showCameraPreview(width, height, cameraCheck)
  }

  async hideCameraPreview() {
    this.proctoringRepository.hideCameraPreview()
}

  async fetchReport(sittingId: string): Promise<AtomReportResponse> {
    return await this.assessmentApi.spokenReport(sittingId)
  }

  async currentTestState(): Promise<TestState> {
    return this._testStates[this._currentStateIndex]
  }

  async consumeTestState(): Promise<TestState> {
    const testState = this._testStates[this._currentStateIndex]
    this._currentStateIndex = this._currentStateIndex < (this._testStates.length - 1) ? this._currentStateIndex + 1 : this._currentStateIndex
    if (testState.index === TestStates.SectionStart) {
      this._currentTitle = `Spoken: ${(testState as SectionStart).title}`
      window.dispatchEvent(new CustomEvent('header-progress', {
        detail: {
          headerProgress: this._headerProgress,
          title: this._currentTitle
        }
      }))
    }
    return testState
  }

  private allItems () {
    return [
      ...(this._assessmentData?.all_items.ELICITED_IMITATION || []),
      ...(this._assessmentData?.all_items.INDEPENDENT_TASK_COMPLETION || []),
      ...(this._assessmentData?.all_items.INDEPENDENT_VIDEO_DESCRIPTION || []),
      ...(this._assessmentData?.all_items.READ_ALOUD || []),
      ...(this._assessmentData?.all_items.LISTEN_AND_REPEAT || []),
      ...(this._assessmentData?.all_items.GUIDED_TASK_TEXTUAL || []),
      ...(this._assessmentData?.all_items.GUIDED_TASK_VISUAL || []),
    ] 
  }

  private _headerProgress = [0]
  private _currentIndex = 0
  private _currentTitle;

  async loadTemplateData(templateId: string): Promise<BaseTemplate> {
    return this.allItems().find(item => item.item_id === templateId)!
  }

  async loadSectionData(sectionId: string): Promise<Section> {
    return this._assessmentData?.sections.find(item => item.section_id === sectionId)!
  }

  async getConfiguration(): Promise<Configuration> {
    return this._assessmentData?.configuration!
  }

  async exitTest(success: boolean): Promise<void> {
    (await this.nativeUtils).exitWebApp(success)
  }

  async requestPermission(): Promise<void> {
    (await this.nativeUtils).openMicSetting()
  }

  async checkPermission(onPermissionGranted: () => void): Promise<boolean> {
    return (await this.nativeUtils).checkMicPermission(onPermissionGranted)
  }

  async updateResponse(questionId: string, uploadUrl: string, skip: boolean): Promise<void> {
    this._submitResponse?.question_responses.push({
      'question_id': questionId,
      'response':  uploadUrl.length === 0 ? [] : [uploadUrl],
      'difficult_flag': skip
    })
  }

  async updateHeaderState() {
    this.proctoringRepository.proctor(this._currentIndex);
    this._currentIndex++;
    this._headerProgress = [
      Math.round(((this._currentIndex) / this.allItems().length) * 100)
    ]
    window.dispatchEvent(new CustomEvent('header-progress', {
      detail: {
        headerProgress: this._headerProgress,
        title: this._currentTitle
      }
    }))
  }

  /**
   * Upload recording
   * @param token to fetch recodrinf as base64 string
   */
  async upload(token: string, fileName: string): Promise<string> {
    const recording = await this.getRecording(token)
    const [ typeInfo , base64Data] = recording.split('base64,')
    const { AccessKeyId, SecretAccessKey, SessionToken, Bucket, FilePath } = this._assessmentData?.audio_response_details!
    const fullBucketPath = `${Bucket}/${FilePath}`.slice(0, -1)

    const [ fileNameNoExtension, ...rest] = fileName.split('.')
    // console.log('typeInfo', typeInfo)
    const extension =  typeInfo.includes('audio/webm') ? 'webm' : 'm4a'
    const contentType = typeInfo.includes('audio/webm') ? 'audio/webm' : 'audio/m4a'

    return this.cloudStorage.upload(`${fileNameNoExtension}.${extension}`, base64Data, fullBucketPath, {
      accessKeyId: AccessKeyId,
      secretAccessKey: SecretAccessKey,
      sessionToken: SessionToken
    }, contentType)
  }
}