import KeyValueStorage from '@es/data/lib/localstorage/KeyValueStorage'
import { injectable, inject } from 'tsyringe'

@injectable()
export default class PlatformProvider {

  constructor(
    @inject("KeyValueStorage") private keyValueStorage: KeyValueStorage
  ) {}

  getPlatform = async () => {
    if (window.location.href.startsWith('https://spoken')) {
      const [ platform ] = window.location.pathname.split('/').slice(1);
      if (!['web', 'android', 'ios'].includes(platform.toLowerCase())) {
        window.location.replace('/web');
        return '';
      }
      await this.setPlatform(platform);
      return platform;
    } else {
      await this.setPlatform('web');
      return 'web';
    } 
  }

  setPlatform = (platform) => this.keyValueStorage.put<string>('platform', platform)
}