import HelloWorld from './components/HelloWorld'
import RegisterButtons from './components/buttons'
import RegisterSpoken from './components/spoken'
import RegisterTypography from './components/typography'
import RegisterIcons from './components/icons'
import RegisterTransitions from './components/transitions'
import RegisterTooltip from './components/tooltip'
import RegisterProgress from './components/progress'
import RegisterInputs from './components/inputs'
import RegisterModal from './components/modal'
import RegisterViews from './components/views'

import { RegisterDirectives } from './directives'

import './styles/index.scss'

export default {
  install(Vue, options) {
    Vue.$platform = options.platform
    Vue.prototype.$platform = options.platform
    
    Vue.$deviceConfig = options.deviceConfig
    Vue.prototype.$deviceConfig = options.deviceConfig
    
    RegisterButtons(Vue)
    RegisterSpoken(Vue)
    RegisterTypography(Vue)
    RegisterIcons(Vue)
    RegisterTransitions(Vue)
    RegisterTooltip(Vue)
    RegisterProgress(Vue)
    RegisterInputs(Vue)
    RegisterModal(Vue)
    RegisterViews(Vue)

    RegisterDirectives(Vue, options)
    
    Vue.mixin({
      components: {
        HelloWorld
      }
    })
  }
}