import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { singleton } from 'tsyringe'

//TODO SMT: Dynamic Configuration
@singleton()
export default class NetworkProvider {

  private _apiClient: AxiosInstance
  private _authToken?: string

  constructor() {
    this._apiClient = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Content-type": "Application/json"
      }
    })
    this._apiClient.interceptors.request.use(config => this.beforeRequest(config))
  }

  async beforeRequest (config: AxiosRequestConfig) {
    config.headers = {
      'Authorization': `Bearer ${this._authToken}`,
    }
    return config
  }

  getClient(authToken: string) {
    this._authToken = authToken
    return this._apiClient!
  }
}