<template>
  <div ref="audioWave" class="audio-wave">
  </div>
</template>
<script>
export default {
  name: 'audio-wave',
  props: {
    noOfWaves: {
      type: Number,
      default: 18
    }
  },
  data: () => ({
    waveData: []
  }),
  computed: {
    waveCount() {
      return this.noOfWaves % 2 === 0 ? this.noOfWaves + 1 : this.noOfWaves
    }
  },
  mounted() {
    this.waveData = new Array(this.waveCount).fill(0);
    for (let i=0; i< this.waveCount; i++) {
      const waveSpan = document.createElement('span');
      this.$refs.audioWave.appendChild(waveSpan)
    }
  },
  beforeMount() {
    window.addEventListener('AUDIO_WAVE_DATA', (event) => {
      // console.log('AUDIO_WAVE_DATA:data', data.detail.data)
      if (!this.$refs.audioWave) return;
      const maxHeight = this.$refs.audioWave.offsetHeight;
      // console.log('AUDIO_WAVE_DATA:maxHeight', maxHeight)
      const halfWave = Math.floor(this.waveCount / 2)
      const halfData = this.waveData.slice(1, halfWave + 1)
      const { data, noTrim } = event.detail
      this.waveData = [
        ...halfData,
        data,
        ...(halfData.reverse())
      ]
      const step = 0.75 / halfWave;
      this.waveData.forEach((waveDataItem, index) => {
        const heightControlStep = noTrim ? 1 : 1 - (step * (Math.abs(halfWave - index)))
        const waveSpan = this.$refs.audioWave.childNodes[index];
        if (waveSpan && waveSpan.style) {
          const newHeight = Number(( maxHeight * (heightControlStep * waveDataItem)));
          waveSpan.style.height = isNaN(newHeight) || newHeight  < 6 ? '6px' : `${newHeight}px`;
        }
      })
    })
  },
  beforeDestroy() {
    window.removeEventListener('AUDIO_WAVE_DATA', () => {
      console.log('AUDIO_WAVE_DATA_OFF');
    })
  }
}
</script>