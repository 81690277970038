import { inject, singleton } from "tsyringe";
import MarkingApi from "../../apis/MarkingApi";
import { AtomData, AtomResponse, MarkingComponents, AtomMarkingResponse, StarRatingMarkingResponseItem, AtomPhraseOption, TapSelectMarkingResponseItem } from "../../models/AtomResponse";
import { MarkingResponse } from "../../models/MarkingResponse";
import CloudStorage from "../../services/CloudStorage";
import NativeUtils from "../../services/NativeUtils";
import { v4 as uuidV4 } from 'uuid'
import { TemplateType } from "./SpokenUsecase";
import GuidedTaskAtomData from '@es/data/lib/data/guided-task-atom-data'

@singleton()
export default class MarkingUsecase {
  
  constructor (
    @inject('NativeUtils') private nativeUtils: NativeUtils,
    @inject('CloudStorage') private cloudStorage: CloudStorage,
    @inject('MarkingApi') private markingApi: MarkingApi
  ) {}

  private _markingResponse?: MarkingResponse
  private _atoms: AtomResponse[] = []
  private _version = 'v3'
  private _sittingId?: string;
  private _headerProgress = [0];
  private _currentIndex = 0;

  private _supportV1AtomResponse = true;

  async loadAtoms(atoms: object[], index: number, version: string, sittingId?: string) {
    this._atoms = atoms.map(_atom => _atom as AtomResponse)
    this._currentIndex = index
    this._version = version
    this._sittingId = sittingId;

    // (await this.nativeUtils).updateMarkingState({
    //   index: Number(this._currentIndex),
    //   version: this._version,
    //   size: Number(this._atoms.length)
    // })

    this._headerProgress = [
      Math.round(((this._currentIndex) / this._atoms.length) * 100)
    ]
    window.dispatchEvent(new CustomEvent('header-progress', {
      detail: {
        headerProgress: this._headerProgress
      }
    }))
  }

  async startMarking(): Promise<boolean> {
    this._markingResponse = await this.markingApi.startMarking()
    return true
  }

  hasAtoms(): boolean {
    return this._currentIndex < this._atoms.length
  }

  getIndexAsNumber(): number {
    return this._currentIndex + 1
  }

  getAnalyicsParams(): object {
    return {
      sittingID: this._sittingId,
      collectionID: this._version,
      sequenceID: this._currentIndex
    }
  }

  async getAtom(): Promise<AtomResponse> {
    return this._atoms[this._currentIndex]
  }

  async submitMarkingResponses(marginResponse: AtomMarkingResponse): Promise<boolean> {
    try {
      if (this._supportV1AtomResponse) {
        marginResponse['atom_id'] = marginResponse.id;
        marginResponse['atom_version'] = marginResponse.id;

        const tapSelectItem = marginResponse.atom_response.additionalQuestions.find(value => value.component === MarkingComponents.TAP_SELECT) as TapSelectMarkingResponseItem;
        const ratingItem = marginResponse.atom_response.additionalQuestions.find(value => value.component === MarkingComponents.STAR_RATING) as StarRatingMarkingResponseItem;
        marginResponse['atom_response'] = {
          ...marginResponse.atom_response,
          'easy_to_understand': ratingItem?.starRating ?? 0,
          'question_id': tapSelectItem?.id ?? marginResponse.itemId,
          'words_heard': tapSelectItem?.wordsStatus.map((item, index) => ({
            'option_id': item.id,
            sequence: index,
            status: item.status,
            value: item.value
          } as AtomPhraseOption)) ?? [],
          phrases: tapSelectItem?.wordsStatus.map((item, index) => ({
            'option_id': item.id,
            sequence: index,
            status: item.status,
            value: item.value
          } as AtomPhraseOption)) ?? [],
          template: marginResponse.template
        }
      }
    } catch (err) {
      console.log('errr', err);
    }
    return await this.markingApi.submitMarkingResponses(marginResponse)
  }

  private _buildAtomDataByTemplate = (atomResponse: AtomResponse): AtomData => {
    const { template, speaking_text: speakingText } = atomResponse.atom;
    // const userId = atomResponse.download_url.split('/')[4];
    const atomData: AtomData = {
      audioUrl: atomResponse.download_url,
      id: atomResponse.atom_id,
      version: atomResponse.atom_version,
      marking: [],
      itemId: atomResponse.item_id,
      template,
      speakingText,
      userId: atomResponse.candidate_id
    };

    if (template === TemplateType.READ_ALOUD || template === TemplateType.LISTEN_AND_REPEAT || template === TemplateType.ELICITED_IMITATION || template === TemplateType.INDEPENDENT_VIDEO_DESCRIPTION || template === TemplateType.INDEPENDENT_TASK_COMPLETION) {
      const [ question ] = atomResponse.atom.atom_questions;

      atomData.marking = [{
        component: MarkingComponents.TAP_SELECT,
        title: 'Tap all the words that you hear',
        id: question.atom_question_id,
        words: question.atom_question_options.map(option => ({
          id: option.option_id,
          value: option.value
        }))
      }, {
        id: '60f71ff1-aefb-4107-8c9b-55f49e78ff9c',
        component: MarkingComponents.QUESTION_LIST,
        title: 'This is what they should have said:',
        text: `“${speakingText}”`,
        questions: [{
          id: '979888cb-5b6b-4264-973d-7d20b6abada5',
          question: 'Did they say any extra words?',
          options: [{
            id: '76bc888b-2404-47e2-857c-9e63bfce4297',
            value: 'Yes'
          }, {
            id: 'af71d6fb-6082-4620-9d9b-485ed2e57d34',
            value: 'No'
          }]
        }, {
          id: '0b0b62c6-4e04-4389-ae1e-f955ecb5da68',
          question: 'Did they make any big pauses?',
          options: [{
            id: '2b8b9448-c2a1-4249-8c11-52355f4b906e',
            value: 'Yes'
          }, {
            id: '7e20c4f7-a798-489d-8506-16532977cf8a',
            value: 'No'
          }]
        }, {
          id: 'e65e98f2-f5f2-4163-89f5-9d3dc6d027a7',
          question: 'Did they repeat any words?',
          options: [{
            id: 'bfde5d48-5bab-476e-a761-2fca5f16bbe7',
            value: 'Yes'
          }, {
            id: '3b302f83-b3f2-4260-b1d5-ec292fa420b1',
            value: 'No'
          }]
        }]
      }, {
        id: 'd652f27a-955d-42d1-8ff1-1c8f36cf430d',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'How clear were they?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }];
    } else if (template === TemplateType.GUIDED_TASK_TEXTUAL) {
      const guidedTaskItem = GuidedTaskAtomData.find(item => item.item_id === atomResponse.item_id);
      const [ imageAssets ] = atomResponse.atom.assets?.images ?? [];
      const imageUrl = imageAssets.file_location.replace('_1.svg', '_2.svg');
      atomData.marking = [{
        component: MarkingComponents.QUESTION_TEXT_OR_IMAGE,
        title: guidedTaskItem.marking_question_2,
        id: '5f5d91b8-02f1-4752-bc52-4ccfff8e3104',
        options: [{
          id: 'e989d7e5-f509-4ccd-a460-dbd746ab1318',
          value: 'Yes'
        }, {
          id: 'ad11b830-4151-4ee5-90fa-a304999deb3f',
          value: 'No'
        }]
      }, {
        component: MarkingComponents.QUESTION_TEXT_OR_IMAGE,
        imageUrl,
        id: '0b25b775-2780-400f-a08a-8ece8007b36c',
        options: [{
          id: 'c779d306-5254-45ec-94ab-94172f6feeed',
          value: 'Yes, both'
        }, {
          id: '33d420af-6dec-47b1-a04c-586cb1e99bd2',
          value: 'Yes, but only one'
        }, {
          id: '97cdb7dd-1815-4aba-b2b6-8087c0a9db2a',
          value: 'No'
        }]
      },{
        id: '507cf6c8-6794-4ff1-92ee-9e3cdd34c5cf',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'How clear were they?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }, {
        id: '829ea2f0-f644-4734-898f-1ca0338d48c6',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'Finally. How well did they do?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }];
    } else if (template === TemplateType.GUIDED_TASK_VISUAL) {
      const [ imageAssets ] = atomResponse.atom.assets?.images ?? [];
      const imageUrl = imageAssets.file_location.replace('_1.svg', '_2.svg');
      atomData.marking = [{
        component: MarkingComponents.QUESTION_TEXT_OR_IMAGE,
        imageUrl,
        id: 'bec42b15-e141-4898-a79b-3b6f70065c47',
        options: [{
          id: 'c7b702b9-a606-45ae-b906-cc1a04932848',
          value: 'Yes'
        },{
          id: 'd13d4f25-ed39-4da5-bd9a-dc7d2011fed7',
          value: 'No'
        }]
      }, {
        id: '507cf6c8-6794-4ff1-92ee-9e3cdd34c5cf',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'How clear were they?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }, {
        id: 'addc4c41-1dbb-4d96-980d-cd454aa37d5f',
        component: MarkingComponents.STAR_RATING,
        showEmoji: true,
        title: 'Finally. How well did they do?',
        ratingText: [
          '🙁',
          '😕',
          '🙂',
          '😀',
          '😃'
        ]
      }];
    }
    return atomData;
  }

  async fetchMarkingAtom(): Promise<AtomData> {
    const atom = await this.markingApi.atom();
    return this._buildAtomDataByTemplate(atom);
  }

  async updateHeaderState() {
    if (this._currentIndex + 1 < this._atoms.length) {
      (await this.nativeUtils).updateMarkingState({
        index: Number(this._currentIndex),
        version: this._version,
        size: Number(this._atoms.length)
      })
    }
    this._currentIndex++
    this._headerProgress = [
      Math.round(((this._currentIndex) / this._atoms.length) * 100)
    ]
    window.dispatchEvent(new CustomEvent('header-progress', {
      detail: {
        headerProgress: this._headerProgress
      }
    }))
  }
  
  async exit(flag: boolean) {
    (await this.nativeUtils).exitWebApp(flag)
  }

  async markinDone() {
    if (this._sittingId) {
      await this.markingApi.updateMarkingStatus(this._sittingId, 'static_complete')
    }
    setTimeout(() => {
      this.exit(true)
    }, 500)
  }

  /**
   * Upload recording
   * @param token to fetch recording as base64 string
   */
  async upload(data: any): Promise<string> {
    const { AccessKeyId, SecretAccessKey, SessionToken, Bucket, FilePath } = this._markingResponse!!
    let fullBucketPath = `${Bucket}/${FilePath}`.slice(0, -1)

    if (window.location.href.indexOf('-qa') !== -1) {
      fullBucketPath = fullBucketPath.replace('staticmarking', 'staticmarking-qa')
    } else if (window.location.href.indexOf('-stage') !== -1) {
      fullBucketPath = fullBucketPath.replace('staticmarking', 'staticmarking-stage')
    } else if (window.location.href.indexOf('-dev') !== -1) {
      fullBucketPath = fullBucketPath.replace('staticmarking', 'staticmarking-dev')
    }
    
    const base64Data = btoa(unescape(encodeURIComponent(JSON.stringify(data))))

    const fileNameNoExtension = `${this._version}_${data.atom_id}`
    const extension =  'dat'
    const contentType = 'application/octet-stream'

    return this.cloudStorage.upload(`${fileNameNoExtension}.${extension}`, base64Data,  this._sittingId ? `${fullBucketPath}/${this._sittingId}` : fullBucketPath, {
      accessKeyId: AccessKeyId,
      secretAccessKey: SecretAccessKey,
      sessionToken: SessionToken
    }, contentType)
  }
}