<template>
  <div>
  <div class="es-select-words" :class="{ 'disabled': disabled }">
      <div class="select-wrapper">
        <h3>{{ title }}</h3>
        <div class="words">
          <template v-for="word in markedWords">
            <button
              :disabled="disabled"
              v-es-on:click="() => toggleSelection(word.option_id, !isSelected(word))"
              :key="`es-mark-word-${word.option_id}`"
              class="word"
              :class="{'selected': isSelected(word)}" >
              {{ word.value }}
            </button>
          </template>
        </div>
        <div class="or-content">
          <p>or</p>
          <button :class="{ 'active': allIncorrect }" v-es-on:click="selectNone">None of these words</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'es-select-words',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Tap everything that you hear the speaker say'
    },
    words: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    markResponse: {
      words_heard: [],
    },
    allIncorrect: false
  }),
  computed: {
    markedWords: {
      get() {
        return this.markResponse.words_heard
      },
      set(value) {
        this.markResponse.words_heard = value
      }
    }
  },
  methods: {
    isSelected(word) {
      return word.status && word.status === 'correct'
    },
    toggleSelection(optionId, shouldAdd) {
      this.allIncorrect = false
      const index = this.markedWords.findIndex(item => item.option_id === optionId)
      const word = this.markedWords[index]
      if (shouldAdd) {
        word.status = 'correct'
      } else {
        word.status = 'incorrect'
      }
      this.markedWords = [
        ...this.markedWords.slice(0, index),
        word,
        ...this.markedWords.slice(index + 1),
      ] 
      this.emitResponse()
    },
    emitResponse(sendNull = false) {
      if (!sendNull) {
        const words = this.markedWords.map(word => {
          if (!word.status || word.status !== 'correct') {
            word.status = 'incorrect'
          }
          return word
        })
        this.$emit('input', {
          ...this.markResponse,
          words_heard: words
        })
      } else {
        this.$emit('input', null)
      }
    },
    selectNone() {
      if (this.disabled) {
        return 
      }
      if (!this.allIncorrect) {
        this.markedWords = [...this.markedWords.map(word => {
          word.status = 'incorrect'
          return word
        })]
        this.allIncorrect = true
        this.emitResponse()
      } else {
        this.allIncorrect = false
        this.emitResponse(true)
      }
    }
  },
  mounted() {
    this.markResponse.words_heard = this.words
  },
  watch: {
    'markedWords' (newWords) {
       if (newWords.filter(word => word.status === 'correct').length === 0 && !this.allIncorrect) {
         this.emitResponse(true)
       }
    }
  }
}
</script>