import { apiClient, apiCancelToken, isCancel } from "@/services/api";
import { ActionTree, GetterTree, MutationTree } from "vuex";

let _fetchItemApiCancelToken;
let _updateItemApiCancelToken;

interface OrgHubSpotItem {
  org_id: string;
  hubspot_id: string;
  org_name: string;
  hubspot_name: string;
  cancelToken: any;
}

interface OrgHubSpotState {
  items: Array<OrgHubSpotItem & { isBusy?: boolean }>;
  busy: boolean;
  error: Error | null;
  dialogActive: boolean;
  busyUpdate: boolean;
}

enum OrgHubSpotMutations {
  SET_ITEMS = "SET_ITEMS",
  SET_BUSY = "SET_BUSY",
  SET_ERROR = "SET_ERROR",
  DIALOG_ACTIVE = "DIALOG_ACTIVE",
  SET_BUSY_UPDATE = "SET_BUSY_UPDATE"
}

const state: OrgHubSpotState = {
  items: [],
  busy: false,
  error: null,
  dialogActive: false,
  busyUpdate: false
};

const getters: GetterTree<OrgHubSpotState, unknown> = {
  items: state => state.items,
  busy: state => state.busy,
  busyUpdate: state => state.busyUpdate,
  error: state => state.error,
  dialogActive: state => state.dialogActive,
  headers: () => [
    // {
    //   text: "Org ID",
    //   value: "org_id"
    // },
    {
      text: "HubSpot ID",
      value: "hubspot_id"
    },
    {
      text: "HubSpot Name",
      value: "hubspot_name"
    },
    {
      text: "Org Name",
      value: "org_name"
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false
    }
  ]
};

const mutations: MutationTree<OrgHubSpotState> = {
  [OrgHubSpotMutations.SET_ITEMS](state, items) {
    state.items = items;
  },
  [OrgHubSpotMutations.SET_BUSY](state, busy) {
    state.busy = busy;
  },
  [OrgHubSpotMutations.SET_ERROR](state, error) {
    state.error = error;
  },
  [OrgHubSpotMutations.DIALOG_ACTIVE](state, dialogActive) {
    state.dialogActive = dialogActive;
  },
  [OrgHubSpotMutations.SET_BUSY_UPDATE](state, busyUpdate) {
    state.busyUpdate = busyUpdate;
  }
};

const actions: ActionTree<OrgHubSpotState, unknown> = {
  async fetchItems({ commit, dispatch }) {
    commit(OrgHubSpotMutations.SET_BUSY, true);
    try {
      _fetchItemApiCancelToken = apiCancelToken.source();
      const { data: items } = await apiClient.get<OrgHubSpotItem[]>(
        "/v1.11/organisations/hubspot/diffs",
        {
          cancelToken: _fetchItemApiCancelToken.token
        }
      );
      commit(OrgHubSpotMutations.SET_ITEMS, items);
    } catch (error) {
      // check if the error is a cancelation
      if (isCancel(error)) {
        return;
      }

      commit(OrgHubSpotMutations.SET_ERROR, error);

      dispatch(
        "snackbar/snack",
        {
          mode: "error",
          message: "Failed to fetch items"
        },
        { root: true }
      );

      setTimeout(() => {
        commit(OrgHubSpotMutations.SET_ERROR, null);
      }, 5000);
    } finally {
      _fetchItemApiCancelToken = undefined;
      commit(OrgHubSpotMutations.SET_BUSY, false);
    }
  },
  async updateItem({ commit, dispatch, state }, item: OrgHubSpotItem) {
    try {
      let index = state.items.findIndex(i => i.org_id === item.org_id);
      commit(OrgHubSpotMutations.SET_ITEMS, [
        ...state.items.slice(0, index),
        { ...item, isBusy: true },
        ...state.items.slice(index + 1)
      ]);
      _updateItemApiCancelToken = apiCancelToken.source();
      await apiClient.patch(
        "/v1.11/organisations/hubspot/diffs",
        {
          new_organisation_name: item.hubspot_name,
          organisation_id: item.org_id,
          hubspot_id: item.hubspot_id
        },
        {
          cancelToken: _updateItemApiCancelToken.token
        }
      );

      index = state.items.findIndex(i => i.org_id === item.org_id);
      commit(OrgHubSpotMutations.SET_ITEMS, [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1)
      ]);
      dispatch(
        "snackbar/snack",
        {
          mode: "success",
          message: `Updated ${item.org_name} to ${item.hubspot_name}`
        },
        { root: true }
      );
    } catch (error) {
      const index = state.items.findIndex(i => i.org_id === item.org_id);
      commit(OrgHubSpotMutations.SET_ERROR, error);
      dispatch(
        "snackbar/snack",
        {
          mode: "error",
          message: `Failed to update ${item.org_name} to ${item.hubspot_name}`
        },
        { root: true }
      );
      commit(OrgHubSpotMutations.SET_ITEMS, [
        ...state.items.slice(0, index),
        { ...item, isBusy: false },
        ...state.items.slice(index + 1)
      ]);
      setTimeout(() => {
        commit(OrgHubSpotMutations.SET_ERROR, null);
      }, 5000);
    } finally {
      _updateItemApiCancelToken = undefined;
    }
  },
  toggleDialog({ commit, state, dispatch }) {
    if (_fetchItemApiCancelToken) {
      _fetchItemApiCancelToken.cancel();
    }
    if (!state.dialogActive) {
      dispatch("fetchItems");
    }
    commit(OrgHubSpotMutations.DIALOG_ACTIVE, !state.dialogActive);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
