<template>
  <div class="audio-progress" :class="[{ 'recording': recording }, progressColor]">
    <div ref="minProgress" class="min-progress"></div>
    <div ref="currentProgress" class="progress"></div>
    <span>{{ time }}</span>
  </div>
</template>
<script>
export default {
  name: 'audio-progress',
  props: {
    minDuration: {
      type: Number,
      default: 5 * 1000,
    },
    maxDuration: {
      type: Number,
      default: 15 * 1000
    },
    recording: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentTime: 0,
    interval: null,
    time: '--:--'
  }),
  computed:{
    duration() {
      return this.maxDuration
    },
    progressColor() {
      return this.currentTime <= this.minDuration ? 'minimum' : ''
    }
  },
  methods: {
    updateTimeAndProgress() {
      if (this.$refs.minProgress) {
        const progress = `${(this.minDuration/this.duration) * 100}%`
        this.$refs.minProgress.style.width = progress
      }
      if (this.$refs.currentProgress) {
        // update progress
        const progress = `${(this.currentTime/this.duration) * 100}%`
        this.$refs.currentProgress.style.width = progress
      }
      // const timeLeft = (this.duration - this.currentTime) / 1000
      const timelapse = this.currentTime / 1000

      const minutes = Number(Math.floor(timelapse / 60)).toString().padStart(2, '0')
      const seconds = Number(Math.floor(timelapse % 60)).toString().padStart(2, '0')
      this.time = `${minutes}:${seconds}`
      if (this.currentTime >= this.maxDuration) {
        this.stopTimer()
        this.$emit('timeout')
      }
    },
    startTimer() {
      this.currentTime = 0;
      this.updateTimeAndProgress();

      setTimeout(() => {
        this.interval = setInterval(() => {
          this.currentTime += 10
          this.updateTimeAndProgress()
        }, 10)
      }, 100)
    },
    stopTimer() {
      clearInterval(this.interval)
    }
  },
  beforeDestroy() {
    this.stopTimer()
  },
  watch: {
    recording(recording) {
      if (recording) {
        this.startTimer()
      } else {
        this.stopTimer()
      }
    }
  }
}
</script>