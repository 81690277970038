import { inject, injectable } from 'tsyringe'
import NetworkProvider from '../network/NetworkProvider'
import AssessmentApi from '@es/domain/lib/apis/AssessmentApi'
import { AssessmentType } from '@es/domain/lib/models/Assessment';
import { AssessmentResponse, SubmitResponse } from '@es/domain/lib/models/AssessmentResponse'
import UserInfoProvider from '@es/domain/lib/providers/UserInfoProvider'
import { AtomResponse } from '@es/domain/lib/models/AtomResponse'
import { AtomReportResponse } from '@es/domain/lib/models/AtomReportResponse'

@injectable()
export default class AssessmentApiImpl implements AssessmentApi {
  
  constructor (
    @inject('UserInfoProvider') private userInfoProvider: UserInfoProvider,
    @inject('NetworkProvider') private networkProvider: NetworkProvider
  ) { }

  async startTest(assessmentType: AssessmentType): Promise<AssessmentResponse> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken, userId } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).post<AssessmentResponse>('/v1.11/exam/starttest', {
        'assessment_type': assessmentType.toString(),
        'user_id': userId,
        'device': 'mobile'
      })
      return data
    } catch(err) {
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }

  async submitResponses(submitResponse: SubmitResponse): Promise<boolean> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken, userId } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).post('/v1.12/exam/responses', {
        ...submitResponse,
        'user_id': userId
      })
      await this.networkProvider.getClient(authToken).post('/v1.12/exam/endtest', {
        'user_id': userId,
        'sitting_id': submitResponse.sitting_id
      })
      return data
    } catch(err) {
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }

  async spokenReport(sittingId: string): Promise<AtomReportResponse> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken, userId } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).get(`/v1.11/spoken/report?sitting_id=${sittingId}&user_id=${userId}`)
      return data
    } catch(err) {
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }

}