import { injectable } from "tsyringe";
import { GradingData } from "../models/GradingResponse";
import GradingUsecase from "../usecases/spoken/GradingUsecase";

@injectable()
export default class GradingInteractor {
  constructor(
    private gradingUsecase: GradingUsecase
  ) {}

  fetchGrading = () => this.gradingUsecase.fetchGrading()
  submitGradingResponses = (gradingResponse: GradingData) => this.gradingUsecase.submitGradingResponses(gradingResponse)
  updateHeaderState = () => this.gradingUsecase.updateHeaderState();
  exit = (flag: boolean) => this.gradingUsecase.exit(flag);
}