import { loginWithGoogle,loginWithMicrosoft, logout, isValid } from './../services/authentication'
import { configureUser, clearContext } from './../services/crashlytics'

export default {
  namespaced: true,
  state: () => ({
    user: null
  }),
  actions: {
    setUser: async ({ commit }, user) => {
      if (user !== null) {
        // Check domain validation
        if (isValid(user.email)) {
          commit('UPDATE_USER', user)
          configureUser({
            email: user.email,
            displayName: user.displayName,
          })
        } else {
          // User is logged in but domain is not valid thus forced logout
          await logout()
        }
      } else {
        clearContext()
      }
    },
    login: loginWithGoogle,
    loginMS: loginWithMicrosoft,
    logout: async ({ commit }) => {
      await logout()
      commit('UPDATE_USER', null)
    }
  },
  mutations: {
    UPDATE_USER(state, user) {
      state.user = user
    }
  },
  getters: {
    user: state => state.user,
    isBusy: state => state.busy,
  }
}