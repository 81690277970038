import { apiClient } from './../services/api'
import queryString from 'query-string'

const getAction = (status) => [
  {
    action: 'verify',
    reasons: [
      'pass_great',
      'pass_acceptable',
    ],
  },
  {
    action: 'invalidate',
    reasons: [
      'fail_not_visible',
      'fail_sitting_issue',
      'fail_not_alone',
    ],
  },
  {
    action: 'regenerate',
    reasons: [
      'fail_main_photo_issue',
      'fail_not_matching',
    ],
  },
  {
    action: 'flag',
    reasons: [
      'flag_tech_issue',
      'flag_possible_underage',
      'flag_other',
    ],
  },
].find(({ reasons }) => reasons.includes(status))?.action


export default {
  namespaced: true,
  state: {
    proctoringList: [],
    lazyProctoringList: [],
    error: '',
    isBusy: false,
    statusBusy: false,
    statusList: [{
      id: 1,
      key: 'unreviewed',
      text: 'Unreviewed'
    }, {
      id: 2,
      key: 'great',
      text: 'Great'
    }, {
      id: 3,
      key: 'acceptable',
      text: 'Acceptable'
    }, {
      id: 4,
      key: 'unacceptable_not_visible',
      text: 'Unacceptable - Not visible'
    }, {
      id: 5,
      key: 'unacceptable_not_alone',
      text: 'Unacceptable - Not alone'
    }, {
      id: 6,
      key: 'unacceptable_not_matching',
      text: 'Unacceptable - Not matching'
    }, {
      id: 7,
      key: 'flag_for_followup',
      text: 'Flag for followup'
    }],
    statusMap: [{
      key:'unreviewed',
      name:'Unreviewed',
      reasons: [],
      colour: '#aed3f9',
      borderColour:'#d6d6d6',
      applyOnStatusSelected: false
    }, {
      key:'pass',
      name:'Pass',
      reasons: [{
        title:'Great',
        key:'pass_great',
        action:'verify'
      }, {
        title:'Acceptable',
        key:'pass_acceptable',
        action:'verify'
      }],
      colour: '#03edc3',
      borderColour: '#03edc3'
    }, {
      key:'fail',
      name:'Fail',
      reasons: [{
        title:'Invalidate - Not visible',
        key:'fail_not_visible',
        action:'invalidate',
        confirmBeforeApply: true
      }, {
        title:'Invalidate - Sitting issue',
        key:'fail_sitting_issue',
        action:'invalidate',
        confirmBeforeApply: true
      }, {
        title:'Invalidate - Not alone',
        key:'fail_not_alone',
        action:'invalidate',
        confirmBeforeApply: true
      }, {
        title:'Regen - Main photo issue',
        key:'fail_main_photo_issue',
        action:'regenerate',
        confirmBeforeApply: true
      }, {
        title:"Regen - Photos don't match",
        key:'fail_not_matching',
        action:'regenerate',
        confirmBeforeApply: true
      }],
      colour: '#ff8200',
      borderColour: '#ff8200'
    }, {
      key:'flag',
      name:'Flag for follow-up',
      reasons: [{
        title:'Tech issue',
        key:'flag_tech_issue'
      }, {
        title:'Possible under-age',
        key:'flag_possible_underage'
      }, {
        title:'Other',
        key:'flag_other'
      }],
      colour: '#ffe300',
      borderColour: '#ffe300'
    }],
    legacyStatusMappings: {
      'great': 'pass_great',
      'acceptable': 'pass_acceptable',
      'unacceptable_not_visible': 'fail_not_visible',
      'unacceptable_not_alone': 'fail_not_alone',
      'unacceptable_not_matching': 'fail_not_matching',
      'unacceptable_for_followup': 'flag_other'
    }
  },
  mutations: {
    updateList(state, list) {
      state.proctoringList = list
    },
    updateLazyList(state, list) {
      state.lazyProctoringList = list
    },
    updateLazyItem(state, { index, item}) {
      // See: https://vuejs.org/2016/02/06/common-gotchas/#Why-isn%E2%80%99t-the-DOM-updating
      state.lazyProctoringList.splice(index, 1, item)
   },
    updateBusy(state, busy) {
      state.isBusy = busy
    },
    updateError: (state, error) => {
      state.error = error
    },
    updateStatusBusy(state, statusBusy) {
      state.statusBusy = statusBusy
    }
  },
  actions: {
    async fetchLazyList({ commit }, payload) {
      try {
        const query = payload ? `?${queryString.stringify(payload)}` : ''
        commit('updateBusy', true)
        const { data } = await apiClient.get(`/v1.11/proctoring${query}`)
        commit('updateLazyList', data.results)

        // Update each proctoring result with meta data
        data.results.forEach(async (item, index) => {
          const { order_id, user_id } = item
          if (!order_id) return

          const { data } = await apiClient.get(`/v1.11/order?order_id=${order_id}&user_id=${user_id}`)
          const { product_type } = data?.results[0]
          const [tier, type] = product_type.split('_')
          item.tier = tier ? tier : '--',
          item.type = type === 'spoken' ? 'Speaking' : 'Core Skills'
          commit('updateLazyItem', { index, item })
        })
      } catch(err) {
        commit('updateError', err)
        commit('updateLazyList', [])
      } finally {
        commit('updateBusy', false)
      }
    },
    async changeStatus({ commit, state }, statusPayload) {
      try {
        commit('updateStatusBusy', true)
        if (!statusPayload.action) statusPayload.action = getAction(statusPayload.status)
        const { data } = await apiClient.patch('/v1.11/proctoring', statusPayload)
        const itemIndex = state.lazyProctoringList.findIndex(item => item.sitting_id === data.sitting_id)
        const proctoringItem = state.lazyProctoringList[itemIndex]
        proctoringItem.proctoring_status = data.status
        delete proctoringItem['status'];
        delete proctoringItem['reason'];
        commit('updateLazyList', [
          ...state.lazyProctoringList.slice(0, itemIndex),
          {
            ...proctoringItem
          },
          ...state.lazyProctoringList.slice(itemIndex + 1),
        ])
      } catch(err) {
        commit('updateError', err)
      } finally {
        commit('updateStatusBusy', false)
      }
    },
    setStatus({ commit, getters, dispatch }, { itemIndex, statusIndex, reasonIndex, action }) {
      const proctoringItem = getters.lazyProctoringItems[itemIndex];
      const status = getters.statusMap[statusIndex];
      const payload = {
        'user_id': proctoringItem.user_id,
        'sitting_id': proctoringItem.sitting_id,
        'order_id': proctoringItem.order_id
      }
      if (status.applyOnStatusSelected) {
        dispatch('changeStatus', {
          ...payload,
          status: status.key
        });
      } else if (reasonIndex !== undefined) {
        const reason = status.reasons[reasonIndex];
        dispatch('changeStatus', {
          ...payload,
          status: reason.key,
          action: action
        });
      } else {
        commit('updateLazyList', [
          ...getters.lazyProctoringItems.slice(0, itemIndex),
          {
            ...proctoringItem,
            status: statusIndex,
            reason: reasonIndex
          },
          ...getters.lazyProctoringItems.slice(itemIndex + 1),
        ]);
      }
    },
  },
  getters: {
    proctoringItems: state => state.proctoringList,
    lazyProctoringItems: state => {
      const items = state.lazyProctoringList.map(item => {
        if (!('status' in item)) {
          let { proctoring_status } = item;
          if ( proctoring_status in state.legacyStatusMappings) {
            proctoring_status = state.legacyStatusMappings[proctoring_status];
          }
          if(proctoring_status == null){
            item.status = 0
            item.reason = 0
          }
          else{
            const [ statusKey ] = proctoring_status.split('_');
            const status = state.statusMap.find(statusItem => statusItem.key === statusKey);
            const statusIndex = state.statusMap.findIndex(statusItem => statusItem.key === statusKey);
            item.status = statusIndex >= 0 ? statusIndex : 0;
            const reasonIndex = status?.reasons?.findIndex(reasonItem => reasonItem.key === proctoring_status) ?? -1;
            item.reason = reasonIndex;
            if(status?.reasons.length > 0)
            {
              item.action = status?.reasons[reasonIndex].action ?? '';
            }

          }
        }
        return item;
      });
      return items
    },
    error: state => state.error,
    isBusy: state => state.isBusy,
    isStatusBusy: state => state.statusBusy,
    statusList: state => state.statusList,
    statusMap: state => state.statusMap,
    legacyStatusMappings: state => state.legacyStatusMappings
  }
}