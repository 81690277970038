import MarkingApi from "@es/domain/lib/apis/MarkingApi";
import { MarkingResponse } from "@es/domain/lib/models/MarkingResponse";
import UserInfoProvider from "@es/domain/lib/providers/UserInfoProvider";
import { inject, injectable } from "tsyringe";
import { AtomResponse } from "@es/domain/lib/models/AtomResponse";
import NetworkProvider from "../network/NetworkProvider";

@injectable()
export default class MarkingApiImpl implements MarkingApi {
  constructor (
    @inject('UserInfoProvider') private userInfoProvider: UserInfoProvider,
    @inject('NetworkProvider') private networkProvider: NetworkProvider
  ) { }
  
  async startMarking(): Promise<MarkingResponse> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken, userId } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).get(`/v1.11/spoken/staticmarking?user_id=${userId}`)
      return data
    } catch(err) {
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }

  async updateMarkingStatus(sittingId: string, status = 'static_complete'): Promise<any> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).patch(`/v1.11/exam/sitting/status`, {
        'sitting_id': sittingId,
        'status': status
      })
      console.log('updateMarkingStatus:data', data)
      return data
    } catch(err) {
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }

  async submitMarkingResponses(markingResponse: any): Promise<boolean> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken, userId } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).patch('/v1.11/spoken/atom', {
        ...markingResponse,
        'marker_id': userId
      })
      return data
    } catch(err) {
      console.log('err', err);
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }

  async atom(): Promise<AtomResponse> {
    try {
      const userInfoProvider = await this.userInfoProvider
      const { authToken, userId } = await userInfoProvider.getUserDetails()
      const { data } = await this.networkProvider.getClient(authToken).post('/v1.11/spoken/atom', {
        'marker_id': userId
      })
      return data
    } catch(err) {
      const { message } = err.response ? err.response.data : err
      throw new Error(message || 'Something went wrong')
    }
  }
}