import { singleton } from "tsyringe";
import { callbackFunction, callbackFunctionNoTimeout } from "..";
import NativeUtils from "@es/domain/lib/services/NativeUtils";
import { DeviceConfiguration } from "@es/domain/lib/models/DeviceConfiguration";

@singleton()
export default class IOSNativeUtils implements NativeUtils {

  checkMicPermission(onPermissionGranted: () => void): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window.webkit.messageHandlers.ESiOSNative.postMessage({
        method: 'checkMicPermission',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }, 30000), // timeout increased for permission check 
        params: JSON.stringify({
          onPermissionGranted: callbackFunctionNoTimeout(onPermissionGranted)
        })
      })
    })
  }

  exitWebApp(success: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      window.webkit.messageHandlers.ESiOSNative.postMessage({
        method: 'exitWebApp',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }, 30000), 
        params: JSON.stringify({
          success
        })
      })
    })
  }

  openMicSetting(): Promise<void> {
    return new Promise((resolve, reject) => {
      window.webkit.messageHandlers.ESiOSNative.postMessage({
        method: 'openMicSetting',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }, 30000), 
        params: JSON.stringify({})
      })
    })
  }

  updateMarkingState(markingState: object): Promise<void> {
    return new Promise((resolve, reject) => {
      window.webkit.messageHandlers.ESiOSNative.postMessage({
        method: 'markingState',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(data)
        }, 30000), 
        params: JSON.stringify({
          ...markingState
        })
      })
    })
  }

  getDeviceConfiguration(): Promise<DeviceConfiguration> {
    return new Promise((resolve, reject) => {
      window.webkit.messageHandlers.ESiOSNative.postMessage({
        method: 'deviceConfig',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          resolve(JSON.parse(data) as DeviceConfiguration)
        }), 
        params: JSON.stringify({})
      })
    })
  }

}