import { apiClient } from '@/services/api'

// Hard-coded IDs are the same in all environments.
const productIds = {
  core_v1: '6b158727-6df0-4973-8086-948338932d34',
  spoken: 'ffb37d8d-6438-4444-aa02-91fe3b39dd42',
  writing: 'c2ffa3e3-5bb5-4b07-8b78-3997b344a1d0',
}

// EnglishScore's organisation ID
const organisation_id = '205ee559-c106-4fd5-8f47-2dd42b5f9482'

const state = {
  busy: false,
  sitting_id: '',
  sittingDetails: false,
  certificateOrderPlaced: false,
}

const loadSittingDetails = async ({ state, rootState, dispatch }) => {
  state.busy = true
  state.sittingDetails = false

  const { sitting_id } = state
  try {
    const params = {
      user_id: rootState.account.user.user_id,
      application: 'CMS',
      sitting_id,
    }
    const { data: { results: [sittingDetails] } } = await apiClient.get('/v1.13/exam/sittings', { params })

    // The product_id is required when placing a certificate order.
    const skill = sittingDetails.assessment_skills[0]
    sittingDetails.product_id = productIds[skill]

    state.sittingDetails = sittingDetails
  }
  catch (error) {
    console.error(error)
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Could not load sitting.</strong>`,
    }, { root: true })
  }
  state.busy = false
}

const placeCertificateOrder = async ({ dispatch, state, rootState }) => {
  state.busy = true
  try {
    const { sittingDetails } = state
    if (!sittingDetails) return false

    const { product_id, user_id, sitting_id } = sittingDetails
    await apiClient.post('v1.11/order', {
      product_id,
      user_id,
      sitting_id,
      payment_details: {
        payment_service: 'voucher',
        certificate_credit: {
          placed_by: rootState.account.user.user_id,
          organisation_id,
        },
      },
    })
    state.certificateOrderPlaced = true
    dispatch('snackbar/snack', {
      mode: 'success',
      message: `✅ Certificate order placed successfully.`
    }, { root: true })
  }
  catch (error) {
    console.error(error)
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">Could not certify sitting.</strong>`,
    }, { root: true })
  }
  state.busy = false
}

export default {
  namespaced: true,
  state,
  actions: {
    loadSittingDetails,
    placeCertificateOrder,
  },
  mutations: {
    setSittingId(state, sitting_id) { state.sitting_id = sitting_id },
    setSittingDetails(state, sittingDetails) { state.sittingDetails = sittingDetails },
    setCertificateOrderPlaced(state, certificateOrderPlaced) { state.certificateOrderPlaced = certificateOrderPlaced },
  }
}
