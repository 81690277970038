import { apiClient } from '../services/api'

const state = {
  payload:{
    userId: '',
    sittingId: '',
    orderId: '',
  },
  success: '',
  error: '',
}

const actions = {
  load: async ({ state }, { queryparams }) => {
    const { sittingId, userId, orderId} = queryparams
    state.payload.sittingId= sittingId
    state.payload.userId = userId
    state.payload.orderId = orderId
  },

  placeCertificateOrder: async ({ state }) => {
    state.success = ''
    state.error = ''
    try {
      const { sittingId, userId, orderId} = state.payload
      const payload = {
        user_id: userId,
        sitting_id: sittingId,
        reinstate: {
          order_id: orderId,
        },
      }
      await apiClient.patch('v1.11/certificate', payload )

      state.success = `
        <strong>Certificate reinstated successfully</strong><br />
        <small>
          &middot; User ID: ${userId} <br />
          &middot; Sitting ID: ${sittingId} <br />
          &middot; Order ID: ${orderId}
        </small>`
      state.payload = {}
    } 
    catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const message = error?.response?.data?.message
      state.error = `<p>Sorry. An error occurred.</p> <small>${message}</small>`
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
}
