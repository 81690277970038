import Vue from 'vue'
import VueRouter from 'vue-router'

import * as firebase from 'firebase/app'
import 'firebase/auth'

import routes from './routes'

Vue.use(VueRouter)

const getRouter = (authorisedRoles) => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  // See: https://v3.router.vuejs.org/guide/advanced/navigation-guards.html
  router.beforeEach((to, _from, next) => {
    const user = firebase.auth().currentUser
    if (!user) {
      if (!to.name) {
        return next({ name: 'login' })
      }

      if (to.name === 'login') {
        // User is not logged in, but is going to login page.
        return next()
      }
      else {
        // User is going to another page, but is not logged in, so redirect to the login page.
        const query = { ...to.query }
        query.requestedRoute = to.name
        if (Object.keys(to.params).length) { query.params = JSON.stringify(to.params) }
        return next({
          name: 'login',
          query,
        })
      }
    }

    /**
     * From this point on, trust that the user is logged in.
     */

    // User is logged in, but is going to login page, so redirect as appropriate.
    if (to.name === 'login') {
      if (!to.query.requestedRoute) {
        return next({ name: 'browse' })
      }
      else {
        const name = to.query.requestedRoute as string
        const params = to.query.params && JSON.parse(to.query.params as string) || {}
        const query = { ...to.query }
        delete query.requestedRoute
        delete query.params
        return next({
          name,
          params,
          query,
        })
      }
    }

    /**
     * Role-based access control
     *
     * authorisedRoles = User's authorised roles: The roles that the user is authorised to use for access.
     * allowedRoles    = Route's allowed roles: Roles that allow access to the route.
     */

    // Use the *last* matched route that has allowed roles.
    // (This means that child-routes' allowed roles override their parents' allowed roles.)
    const matches = to.matched.filter(({ meta }) => meta.allowedRoles?.length > 0)
    const lastMatch = matches.pop()
    const { meta } = lastMatch || {}
    const { allowedRoles } = meta || {}

    // We don't know if a CMS user is new, but if they have no roles, we guess they are.
    if (to.name !== 'noPermissions') {
      const userHasNoCmsRolesAssigned = !authorisedRoles || !authorisedRoles.length || ![...authorisedRoles].filter(role => role !== 'b2b_dashboard_owner').length
      if (userHasNoCmsRolesAssigned) {
        console.log('authorisedRoles', authorisedRoles)
        return next({ name: 'noPermissions' })
      }
    }
    // Deny access if *none* of the user's authorised roles matches any of the roles that are allowed to access this route.
    if (Array.isArray(allowedRoles)) {
      const userHasAuthorisedRoles = Array.isArray(authorisedRoles) && (authorisedRoles.length > 0)
      if (!userHasAuthorisedRoles) {
        return next({ name: 'noAccess' })
      }
      const userHasMatchingAllowedRole = authorisedRoles.some(role => allowedRoles.includes(role))
      if (!userHasMatchingAllowedRole) {
        console.log('authorisedRoles', authorisedRoles)
        console.log('allowedRoles', allowedRoles)
        return next({ name: 'noAccess' })
      }
      
    }

    next()
  })
  
  return router
}

export default getRouter