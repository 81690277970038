import { AxiosResponse } from 'axios'
import { apiClient } from '@/services/api'

// Generate a human-friendly label e.g. from "MCQ_GAPFILL_CHAT" to "Gapfill Chat"
const humanize = (string: string) => string
  .trim()
  .toLowerCase()
  .split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ')

/**
 * Form components are dynamically imported. Different item templates have different vue components.
 * The name of the file is expected to match specific conventions.
 * Translate the template name to StartCase e.g. from "DND_READING" to "DndReading"
 */
const setForm = ({ state }): void => {
  const { value } = state.template
  const filename = humanize(value).split(" ").join("")
  state.form = () => import(`@/components/item/templates/${filename}`)
}

/**
 * New items
 * Hydrate state with data from the to-do item array
 */
const loadPresetValues = ({ state, rootGetters, dispatch }): void => {
  state.error = ''
  state.success = ''
  state.isUpdate = false

  const getPresets = rootGetters['todo/getPresets']
  const presets = getPresets(state.presetIndex)

  state.bucket = presets.bucket
  state.level = presets.level
  state.skill = presets.skill
  state.domain = presets.domain

  // Defaulting to not-applicable if languageFunction has no preset
  state.languageFunction = presets.languageFunction || { value: "not-applicable", text: "Not-applicable" }

  // lengthMin and lengthMax advise the item author how many words to include in the item
  state.lengthMin = presets.length_min
  state.lengthMax = presets.length_max

  // Writing items have limits on word count for the content written by the test-taker.
  state.wordLimits = {
    mandatory: {
      min: presets.assessment_min_word_limit,
      max: presets.assessment_max_word_limit,
    },
  }
  if (presets.advised_min_word_limit) {
    state.wordLimits.advised = {
      min: presets.advised_min_word_limit,
      max: presets.advised_max_word_limit,
    }
  }
  else {
    state.wordLimits.advised = [
      {
        position: 0,
        min: presets.advised_min_word_limit_turn_1,
        max: presets.advised_max_word_limit_turn_1,
      },
      {
        position: 1,
        min: presets.advised_min_word_limit_turn_2,
        max: presets.advised_max_word_limit_turn_2,
      },
    ]
  }

  // Sometimes permitted_formats is an array, sometimes it's a string. Ensure it's an array.
  const templates = [].concat(presets.permitted_formats).map(value => ({
    value,
    text: humanize(value),
  })).reverse() // reverse so that gapfill paragraph comes first (to conserve user experience from original version)
  state.templates = templates

  // The default template is the first in the array. Note: this can be changed in the UI.
  state.template = templates[0]
  dispatch('setForm')
}

/**
 * Existing items
 * Hydrate state with data from the item list, or fetch item data from the api
 */
const loadApiValues = async ({ state, rootState, dispatch }): Promise<void> => {
  state.error = ''
  state.isUpdate = true
  state.payload = {}

  // Find the item in the raw list of items
  let item = rootState.list.rawItems.find(({ item_id }) => item_id === state.uuid)
  if (!item) {
    // If the item is not in the list, fetch it from the api
    const { data } = await apiClient.get(`/v1.11/item?id=${state.uuid}`)
    item = Object.values(data.results[0]).flat()[0]
  }

  /**
   * Item "payload" contains all the properties from the API that are not part of the standard item model.
   * The payload is different depending on the item type, and too brittle to be mapped accurately.
   * So: To create the payload, clone the item data and delete the base properties.
   */
  const payload = { ...item }
  const baseItemProperties = [
    'author_id',
    'author_name',
    'batch',
    'bucket',
    'cefr_level',
    'created',
    'domain',
    'item_id',
    'language_function',
    'skill',
    'status',
    'status_metadata',
    'template_type',
    'updated',
  ]
  baseItemProperties.forEach(key => delete payload[key])
  state.payload = payload

  state.authorName = item.author_name
  state.authorId = item.author_id
  state.bucket = item.bucket
  state.level = item.cefr_level
  state.created = item.created
  state.updated = item.updated
  state.status = humanize(item.status)
  state.skill = humanize(item.skill.toLowerCase() === 'spoken' ? 'speaking' : item.skill)

  if (item.status_metadata) {
    state.statusMetadata = item.status_metadata
  }

  /**
   * Get wordcount from the matching item in the todo item list.
   * This is not a perfect solution because there's no unique value in the fetched item
   * that matches any specific item in the todo item list.
   * @todo find a better solution
   */
  try {
    const moniker = [state.skill, state.level, state.bucket].sort().join('-')
    const found = rootState.todo.items.find(item => item.moniker === moniker)
    state.lengthMin = found?.length_min ?? 0
    state.lengthMax = found?.length_max ?? 0
  }
  catch (error) {
    console.error(error)
  }

  if (item.domain) {
    const domains = rootState.todo.domains
    state.domain = domains.find(({ text }) => text === humanize(item.domain))
  }

  if (item.language_function) {
    const languageFunctions = rootState.todo.languageFunctions
    state.languageFunction = languageFunctions.find(({ text }) => text === humanize(item.language_function))
  }

  const template = rootState.todo.templates.find(({ value }) => value === item.template_type.toLowerCase())
  state.template = template

  dispatch('setForm')
}

const uploadFile = async({ state, dispatch }, itemPayload) => {
  state.isBusy = true
  state.error = ''
  state.success = ''

  const configuration = { headers: {
    'Content-Type': 'multipart/form-data'
  }}

  try {
    const response = await apiClient.post('/v1.11/asset', itemPayload, configuration)
    state.isBusy = false
    return response
  } catch (error) {
    console.error(error)
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const { message } = error?.response?.data || {}
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `❌ Error: Could not upload file. ${message}`,
    }, { root: true })
  }
  state.isBusy = false
}

// Questions are saved individually when an item is updated (but not when it's created)
const saveQuestions = async ({ state, itemRequestData }): Promise<void> => {
  if (!state.payload.questions) return

  const questionPromises = state.payload.questions.reduce((promises, rawQuestion) => {
    const question = JSON.parse(JSON.stringify(rawQuestion))
    const questionRequestData = {
      ...itemRequestData,
      question_id: question.question_id,
    }

    // First update each option
    question.question_options?.forEach(option => {
      promises.push(apiClient.patch('/v1.11/item/question/option', {
        ...questionRequestData,
        option_id: option.option_id,
        data: {
          value: option.value
        }
      }))
    })
    delete question.question_options

    // Then update the question, if the question has text and/or assets content
    const hasQuestionText = question.question?.trim().length > 0
    const hasContent = hasQuestionText || !!question.assets
    if (!hasContent) return promises

    // Question "position" is only set on create, so if it's an update, remove it.
    if (state.isUpdate) delete question.position

    promises.push(apiClient.patch('/v1.11/item/question', {
      ...questionRequestData,
      data: question,
    }))
    return promises
  }, [] as Promise<AxiosResponse>[])

  Promise.all(questionPromises).catch(error => {
    throw error
  })
}

// Save requests are either CREATE or UPDATE
const save = async ({ state, rootState, dispatch }) => {
  state.isBusy = true
  state.error = ''
  state.success = ''

  // Base item data required for both CREATE and UPDATE
  const itemData = {
    batch: state.batch,
    cefr_level: state.level,
    bucket: String(state.bucket),
    domain: state.domain.value,
    language_function: state.languageFunction.value,
    skill: state.skill.toLowerCase() === 'speaking' ? 'spoken' : state.skill.toLowerCase(),
    status_metadata: state.statusMetadata,
  }

  const itemRequestData = {
    author_id: rootState.account.user.user_id,
    item_id: state.uuid,
  }
  const data = {}
  const templatePropertyName = state.template.value.toUpperCase()
  if (state.isUpdate) {
    // Deliberately omit questions data when updating an item, because questions are updated at a different API endpoint.
    const expurgatedPayload = { ...state.payload }
    delete expurgatedPayload.questions
    data[templatePropertyName] = { // @note: the API for updating items expects this NOT to be an array
      ...itemData,
      ...expurgatedPayload,
    }

    // Save questions separately
    saveQuestions({ state, itemRequestData }).catch(error => {
      console.error(error)
      state.error = `An error occurred while attempting to save a question.`
    })
  }
  else {
    data[templatePropertyName] = [{ // @note: the API for new items expects this to be an array
      ...itemData,
      ...state.payload
    }]
  }

  try {
    const method = state.isUpdate ? 'patch' : 'post'
    const response = await apiClient[method]('/v1.11/item', { ...itemRequestData, data })
    const responseData = state.isUpdate ? response.data.data[0] : response.data.data[templatePropertyName][0]
    const { status, item_id } = responseData

    /**
     * @note After saving we want to reroute to /items/update/${uuid}
     * However routing is not designed be done in vuex stores.
     * So at this point, a watcher in the Controls component (where the save button is)
     * notices the change in uuid and does the rerouting for us.
     */
    state.uuid = item_id
    state.isUpdate = true
    state.updated = new Date()
    state.status = humanize(status)
    state.success = 'Item saved successfully.'
    dispatch('snackbar/snack', {
      mode: 'success',
      message: `✅ Item saved successfully.`
    }, { root: true })
  }
  catch(error) {
    console.error(error)
    state.error = `An error occurred while attempting to save the item.`
    dispatch('snackbar/snack', {
      mode: 'error',
      message: `⚠️ Error: <strong class="px-4">An error occurred while attempting to save the item.</strong>`,
    }, { root: true })
  }
  state.isBusy = false
}

const patchItem = async ({ state, rootState }, data) => {
  const itemRequestData = {
    author_id: rootState.account.user.user_id,
    item_id: state.uuid,
  }
  try {
    const response = await apiClient.patch('/v1.11/item', { ...itemRequestData, data })
    const responseData = response.data.data[0]
    const { status } = responseData
    state.updated = new Date()
    state.status = humanize(status)
  }
  catch(error) {
    console.error(error)
    throw error
  }
}

const toggleStatus = async ({ state, rootState }) => {
  state.isBusy = true
  state.error = state.success = ''

  const newStatus = state.status?.toLowerCase() === 'live' ? 'deactivated' : 'live'

  try {
    /**
     * Add an entry to the status timeline for this item
     *
     * An event of 1 means 'Activated' (live)
     * An event of 2 means 'Deactivated' (deactivated)
     */
    if (!state.statusMetadata.timeline) state.statusMetadata.timeline = []
    state.statusMetadata.timeline.push({
      person: rootState.account.user.user_id,
      date: new Date(),
      event: newStatus === "live" ? 1 : 2
    })
    const templatePropertyName = state.template.value.toUpperCase()
    const data = {[templatePropertyName]: {
      status: newStatus,
      status_metadata: state.statusMetadata,
    }}

    await patchItem({ state, rootState }, data)
    state.success = 'Item saved successfully.'
  }
  catch(error) {
    console.error(error)
    state.error = 'An error occurred while attempting to save the item.'
  }
  state.isBusy = false
}

const discard = async ({ state, rootState }) => {
  state.isBusy = true
  state.error = state.success = ''

  const templatePropertyName = state.template.value.toUpperCase()
  const data = {[templatePropertyName]: {
    status: 'discarded',
  }}

  try {
    await patchItem({ state, rootState }, data)
    state.success = 'Item discarded.'
  }
  catch(error) {
    state.error = 'An error occurred while attempting to discard the item.'
  }
  state.isBusy = false
}

const item = {
  payload: {},
  batch: 'item-cms',
  authorName: '',
  authorId: '',
  bucket: '',
  domain: '',
  languageFunction: '',
  level: '',
  skill: '',
  template: '',
  created: '',
  updated: '',
  status: '',
  statusMetadata: { timeline: [] },
}

const wordCount = {
  lengthMin: 0,
  lengthMax: 0,
  currentWordCount: 0,
}

const controls = {
  isBusy: false,
  isUpdate: false,
  error: '',
  success: '',
  toggleStatusLabel: '',
}

export default {
  namespaced: true,
  state: {
    ...item,
    ...wordCount,
    ...controls,
    presetIndex: 0,
    uuid: '',
    form: false,
    templates: [],
    audioIsInvalid: false,
    isMissingRequiredInput: false,
  },
  getters: {}, // @see https://codeburst.io/vuex-getters-are-great-but-dont-overuse-them-9c946689b414
  mutations: {
    setPresetIndex(state, index) { state.presetIndex = index },
    setDomain(state, domain) { state.domain = domain },
    setLanguageFunction(state, languageFunction) { state.languageFunction = languageFunction },
    setTemplate(state, template) { state.template = template },
    setUpdated(state, updated) { state.updated = updated },
    setStatus(state, status) { state.status = status },
    setSuccess(state, success) { state.success = success },
    setError(state, error) { state.error = error },
    setUuid(state, uuid) { state.uuid = uuid },
    setWordCount(state, text) { state.currentWordCount = text.split(/\S+/).length - 1},
    setPayload(state, payload) {
      if (payload.gapfill_paragraph) {
        payload.gapfill_paragraph = payload.gapfill_paragraph.trim()
      }
      state.payload = payload
    },
    setAudioIsInvalid(state, value) { state.audioIsInvalid = value },
    setIsMissingRequiredInput(state, value) { state.isMissingRequiredInput = value },
  },
  actions: {
    loadPresetValues,
    loadApiValues,
    setForm,
    uploadFile,
    save,
    toggleStatus,
    discard,
  },
}
