import UserInfo from "@es/domain/lib/models/UserInfo";
import UserInfoProvider from "@es/domain/lib/providers/UserInfoProvider";
import { callbackFunction } from "../../../services/platform";

export default class AndroidUserInfoProvider implements UserInfoProvider {
  getUserDetails(): Promise<UserInfo> {
    return new Promise<UserInfo>((resolve, reject) => {
      window.ESAndroidNative.callNative(JSON.stringify({
        method: 'getUserDetails',
        callback: callbackFunction((err, data) => {
          if (err) {
            return reject(err)
          }
          const { token, userid, latestGVRLScore } = JSON.parse(data)
          resolve({
            authToken: token,
            userId: userid,
            latestGVRLScore: Number(latestGVRLScore) || 0
          })
        }),
        params: JSON.stringify({})
      }))
    })
  }
}