import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faCheck, faMicrophone, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default (Vue) => {
  library.add(faCheckCircle)
  library.add(faCheck)
  library.add(faMicrophone)
  library.add(faTimes)
  Vue.component('es-icon', FontAwesomeIcon)
}