import { injectable } from "tsyringe";
import MarkingUsecase from "../usecases/spoken/MarkingUsecase";

@injectable()
export default class MarkingInteractor {
  constructor(
    private markingUsecase: MarkingUsecase
  ) {}

  loadAtoms = (atoms: object[], index: number, version: string, sittingId?: string) => this.markingUsecase.loadAtoms(atoms, index, version, sittingId)
  startMarking = () => this.markingUsecase.startMarking()
  upload = (markingResponse: object) => this.markingUsecase.upload(markingResponse)
  getAtom = () => this.markingUsecase.getAtom()
  updateHeaderState = () => this.markingUsecase.updateHeaderState()
  hasAtoms = () => this.markingUsecase.hasAtoms()
  exit = (flag: boolean) => this.markingUsecase.exit(flag)
  markinDone = () => this.markingUsecase.markinDone()
  getIndexAsNumber = () => this.markingUsecase.getIndexAsNumber()
  getAnalyicsParams = () => this.markingUsecase.getAnalyicsParams()
  fetchMarkingAtom = () => this.markingUsecase.fetchMarkingAtom()
  submitMarkingResponses = (markingResponse: any) => this.markingUsecase.submitMarkingResponses(markingResponse)
}